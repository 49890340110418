import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Paper, Typography, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { gethome, getHost } from "../../service/api";
import Cookies from "js-cookie";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px', // Adjust the height based on your design
  },
}));

const SystemInformation = () => {
  const classes = useStyles();
  const [systemInfo, setSystemInfo] = useState(null);
  const [assigned, setassigned] = useState([])
  const [error, setError] = useState(null);
  const [internetConnected, setInternetConnected] = useState(true); // State to track internet connection
  const [users, setUsers] = useState([]);

  useEffect(() => {

    getAllUsers();
    fetchSystemInfo();
  }, []);

  const getAllUsers = async () => {
    try {
      console.log("called gethomefunc");
      // Check internet connection before making the API call
      const isOnline = navigator.onLine;
      setInternetConnected(isOnline);
      if (!isOnline) return; // If not online, stop further execution

      const id = Cookies.get("access_token");
      const response = await gethome(id);
      console.log("response:", response.data);
      console.log("Company_name:", response.data[0].Company_name);
      const Company_name = response.data[0].Company_name;

      if (response.data && response.data.length > 0) {
        console.log("Parent component users:", response.data);
        console.log("====================================");
        console.log("====================================");

        const datae = {
          jsonrpc: "2.0",
          method: "host.get",
          params: {
            output: "extend",
            selectHostGroups: "extend",
          },
          id: 1,
        };
        const res = await getHost(datae);
        console.log("=============GHost=======================");
        console.log(res.data);
        console.log("====================================");

        // Map the data based on edgedevice_name
        const mappedData = response.data.map((user) => {
          const edgedeviceName = user.edgedevice_name;
          const matchingHost = res.data.result.find(
            (host) => host.host === edgedeviceName
          );
          return { ...user, matchingHost };
        });

        // Filter out the items where matchingHost is undefined
        const filteredData = mappedData.filter((user) => user.matchingHost);
        // Assuming res.data.result contains the JSON data
        const filteredData1 = res.data.result.filter((item) => {
          // Check if any of the hostgroups have the name "wermink"
          return item.hostgroups.some((group) => group.name === Company_name);
        });

        console.log("Filtered Data1:",filteredData1.length);

        setassigned(filteredData1);
        console.log("Filtered Data:", filteredData);
        setUsers(filteredData);
      } else {
        console.log("No user data found");
      }
    } catch (error) {
      console.log("Error fetching users:", error);
    }
  };
    const fetchSystemInfo = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL_node;
        const response = await axios.post(`${apiUrl}/getsysteminfo`);
        const htmlContent = response.data.body;

        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlContent;
        const tableElement = tempElement.querySelector('.list-table');

        if (tableElement) {
          const systemInfoData = {};
          const rows = tableElement.querySelectorAll('tbody tr');

          rows.forEach(row => {
            const cells = row.querySelectorAll('th, td');
            if (cells.length === 3) {
              let parameter = cells[0].innerText;
              const value = cells[1].innerText;
              const details = cells[2].innerText;

              // Modify display for "Number of hosts"
              if (parameter === 'Number of hosts (enabled/disabled)') {
                parameter = 'Number of Hosts';
                console.log('assigned.length',assigned.length);
              systemInfoData[parameter] = {
                Value: users.length,
                Details: `${users.length}/${assigned.length}`
              };
              }else if (parameter === 'Number of users (online)') {
            // Modify display for "Number of users (online)"
            systemInfoData[parameter] = {
                Value: users.length,
                Details: `${users.length}/${assigned.length}`
            };
          } else {
            // For other parameters, use the default value and details
            systemInfoData[parameter] = {
              Value: value,
              Details: details
            };
          }

            }
          });

          setSystemInfo(systemInfoData);
        } else {
          setError('Table element with class "list-table" not found in the HTML content.');
        }
      } catch (error) {
        setError(`Error fetching system info: ${error.message}`);
      }
    };
console.log('users', users);


  return (
    <Paper className={classes.container} elevation={3}>
      <Typography variant="h5" gutterBottom align='center'>
        System Information
      </Typography>
      {systemInfo ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Parameter</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(systemInfo).map(([parameter, { Value, Details }]) => (
                <TableRow key={parameter}>
                  <TableCell>{parameter}</TableCell>
                  <TableCell>{Value}</TableCell>
                  <TableCell>{Details}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
    </Paper>
  );
};

export default SystemInformation;
