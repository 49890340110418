import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import {
  getinterface,
  changeipv4,
  changeMTU,
  changestate,
} from "../../service/api";
import { useParams } from "react-router-dom";
import Sidebar from "./Sidebar";
import Nav from "../Home/Nav";

const Interfaces = () => {
  const { id } = useParams();
  const [newIPV4, setNewIPV4] = useState("");
  const [isValidSourceIp, setIsValidSourceIp] = useState(true);
  const [newMTU, setNewMTU] = useState("");
  const [newState, setNewState] = useState("");
  const [interfc, setInterfc] = useState([]);
  const [openIpDialog, setOpenIpDialog] = useState(false);
  const [openMtuDialog, setOpenMtuDialog] = useState(false);
  const [openStateDialog, setOpenStateDialog] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedIp, setSelectedIp] = useState("");
  const [selectedMtu, setSelectedMtu] = useState("");

  useEffect(() => {
    getInterfc();
  }, []);

  const getInterfc = async () => {
    try {
      const response = await getinterface(id);
      setInterfc(response.data);
    } catch (error) {
      console.log("Error while getting interface data:", error);
    }
  };

  const handleSourceIpChange = (event) => {
    const ipAddress = event.target.value;
    setIsValidSourceIp(validateIp2(ipAddress));
    setNewIPV4(ipAddress);
  };

  const validateIp2 = (ipAddress) => {
    const parts = ipAddress.split("/");
    if (parts.length !== 2) {
      return false;
    }

    const ipParts = parts[0].split(".");
    const maskPart = parts[1];

    if (ipParts.length !== 4) {
      return false;
    }

    for (let i = 0; i < ipParts.length; i++) {
      const part = ipParts[i];
      if (!/^\d+$/.test(part)) {
        return false;
      }
      const value = parseInt(part);
      if (value < 0 || value > 255) {
        return false;
      }
    }

    if (!/^\d+$/.test(maskPart)) {
      return false;
    }
    const maskValue = parseInt(maskPart);
    if (maskValue < 0 || maskValue > 32) {
      return false;
    }

    return true;
  };

  const handleIpDialogOpen = (index, ip) => {
    setOpenIpDialog(true);
    setSelectedIndex(index);
    setSelectedIp(ip);
  };

  const handleIpDialogClose = () => {
    setOpenIpDialog(false);
    setSelectedIndex(null);
    setSelectedIp("");
  };

  const handleMtuDialogOpen = (index, mtu) => {
    setOpenMtuDialog(true);
    setSelectedIndex(index);
    setSelectedMtu(mtu);
  };

  const handleMtuDialogClose = () => {
    setOpenMtuDialog(false);
    setSelectedIndex(null);
    setSelectedMtu("");
  };

  const handleStateDialogOpen = (index) => {
    setOpenStateDialog(true);
    setSelectedIndex(index);
  };

  const handleStateDialogClose = () => {
    setOpenStateDialog(false);
    setSelectedIndex(null);
  };

  const handleChangeIPV4 = async () => {
    try {
      const value = {
        sw_if_index: selectedIndex,
        current_ipaddress: selectedIp,
        new_ipaddress: newIPV4,
      };

      const response = await changeipv4(value, id);
      // handle response and set progress
      getInterfc();
    } catch (error) {
      console.error("Error while changing IPV4:", error);
    } finally {
      handleIpDialogClose();
    }
  };

  const handleChangeMTU = async () => {
    try {
      const value = {
        sw_if_index: selectedIndex,
        int_link_mtu: selectedMtu,
        mtu: newMTU,
      };

      const response = await changeMTU(value, id);
      // handle response and set progress
      getInterfc();
    } catch (error) {
      console.error("Error while changing MTU:", error);
    } finally {
      handleMtuDialogClose();
    }
  };

  const handleChangeState = async () => {
    try {
      const value = { sw_if_index: selectedIndex, state: newState };

      const response = await changestate(value, id);
      // handle response and set progress
      getInterfc();
    } catch (error) {
      console.error("Error while changing state:", error);
    } finally {
      handleStateDialogClose();
    }
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Nav name="LAN & WAN Interfaces" />
      </Grid>

      <Grid item xs={2}>
        <Paper style={{ height: "100vh", overflowY: "auto" }}>
          <Sidebar />
        </Paper>
      </Grid>

      <Grid item xs={10} style={{ paddingTop: "64px" }}>
        <Typography variant="h6" align="center" gutterBottom></Typography>
        <Paper style={{ overflowY: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Interface IPV4 Address</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Interface Link MTU</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>Interface Name</TableCell>
                <TableCell>Interface Speed</TableCell>
                <TableCell>Interface Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {interfc.map((user, index) => (
                <TableRow key={user.int_index}>
                  <TableCell>{user.int_ipv4_address || "null"}</TableCell>
                  {user.type === "lan" || user.type === "wan" ? (
                    <>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            handleIpDialogOpen(index, user.int_ipv4_address)
                          }
                          disabled={!isValidSourceIp}
                          style={{ fontSize: "10px", padding: 0 }}
                        >
                          Change IP
                        </Button>
                      </TableCell>
                      <TableCell></TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </>
                  )}
                  <TableCell>{user.type}</TableCell>
                  <TableCell>{user.int_link_mtu || "null"}</TableCell>
                  {user.type === "lan" || user.type === "wan" ? (
                    <>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            handleMtuDialogOpen(index, user.int_link_mtu)
                          }
                          style={{ fontSize: "10px", padding: 0 }}
                        >
                          Change MTU
                        </Button>
                      </TableCell>
                      <TableCell></TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </>
                  )}
                  <TableCell>{user.int_name || "null"}</TableCell>
                  <TableCell>{user.int_speed || "null"}</TableCell>
                  <TableCell>{user.int_status || "null"}</TableCell>
                  {user.type === "lan" || user.type === "wan" ? (
                    <>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleStateDialogOpen(index)}
                          style={{ fontSize: "9px", padding: 0 }}
                        >
                          Change State
                        </Button>
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell></TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {/* IP Dialog */}
          <Dialog open={openIpDialog} onClose={handleIpDialogClose}>
            <DialogTitle>Change IPV4 Address</DialogTitle>
            <DialogContent>
              <FormControl fullWidth>
                <TextField
                  label="New IPV4 Address"
                  variant="outlined"
                  onChange={handleSourceIpChange}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleIpDialogClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleIpDialogClose();
                  handleChangeIPV4();
                }}
                color="primary"
                disabled={!isValidSourceIp}
              >
                Save Changes
              </Button>
            </DialogActions>
          </Dialog>

          {/* MTU Dialog */}
          <Dialog open={openMtuDialog} onClose={handleMtuDialogClose}>
            <DialogTitle>Change MTU Value</DialogTitle>
            <DialogContent>
              <FormControl fullWidth>
                <TextField
                  label="New MTU Value"
                  variant="outlined"
                  onChange={(event) => setNewMTU(event.target.value)}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleMtuDialogClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleMtuDialogClose();
                  handleChangeMTU();
                }}
                color="primary"
              >
                Save Changes
              </Button>
            </DialogActions>
          </Dialog>

          {/* State Dialog */}
          <Dialog open={openStateDialog} onClose={handleStateDialogClose}>
            <DialogTitle>Change Interface State</DialogTitle>
            <DialogContent>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">State</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="state"
                  name="state"
                  label="state"
                  onChange={(event) => setNewState(event.target.value)}
                >
                  <MenuItem value="up">up</MenuItem>
                  <MenuItem value="down">down</MenuItem>
                  <MenuItem value="inactive">inactive</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleStateDialogClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleStateDialogClose();
                  handleChangeState();
                }}
                color="primary"
              >
                Save Changes
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Interfaces;
