// HostAvailabilityInfo.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import './HostAvailabilityInfo.css'; // You can create a CSS file for styling

const HostAvailabilityInfo = () => {
  const [hostAvailabilityData, setHostAvailabilityData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL_node;
        const response = await axios.post(`${apiUrl}/gethostavailable`);
        setHostAvailabilityData(response.data);
      } catch (error) {
        console.error('Error:', error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {hostAvailabilityData && hostAvailabilityData.body ? (
        <div className="host-availability-container">
          {/* Render the HTML body received from the server */}
          <div dangerouslySetInnerHTML={{ __html: hostAvailabilityData.body }}></div>
        </div>
      ) : (
        <p>Error fetching host availability data</p>
      )}
    </div>
  );
};

export default HostAvailabilityInfo;
