import {React, useState, ChangeEvent} from 'react'
import {FormGroup, FormControl, Input, InputLabel,NativeSelect, Typography, styled, Button,FormControlLabel, Switch, FormLabel, FormHelperText} from "@mui/material"
import { addPathLabel } from '../../service/api'
import { useNavigate } from 'react-router-dom'




const Container = styled(FormGroup)`
    width: 500%;
    margin: 5% auto 0 100%;
    & > div{
        margin-top: 20px;
    }


`
function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = d.getHours(),
        minutes = d.getMinutes();


    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

        const dd = [day,month, year].join('-')
        const tt = [hours,minutes].join(':');

    return [dd, tt].join(' ');
}
const timeStamp = formatDate(Date());

const defaultValues = {
    pathLabelName: "",
    organizationName: "",
    description: "",
    pathLabelType:"DIA",
    color:"",
    timeStamp:timeStamp
}

function AddPathLabel() {

    

    const navigate = useNavigate()
    
    const [user, setUser] = useState(defaultValues);

    const onValueChange = (e) =>{
        setUser({...user, [e.target.name]: e.target.value})
    }

    
    const AddPathLabel= async () =>{
        await addPathLabel(user)
         navigate('/inventory/pathLabel')
 
     }

  return (
    <Container fullWidth>
        <Typography variant='h4'>Add Path Label</Typography>
    <FormControl>
        <InputLabel>Path Label Name </InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="pathLabelName"/>
    </FormControl>
        <FormControl fullWidth>
             <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Organization Name
             </InputLabel>
             <NativeSelect
                  defaultValue={1}
                     inputProps={{
                      name: 'organizationName',
                      id: 'uncontrolled-native',
                     }}
                     onChange={(e) => onValueChange(e)}  >
               <option value={1}>Demo</option>
               <option value={2}>Demo 1</option>
               <option value={3}>Demo 2</option>
               <option value={4}>Demo 3</option>
             </NativeSelect>
        </FormControl>
        
    <FormControl>
        <InputLabel>Description</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="description"/>
    </FormControl>
        
        
    <FormControl fullWidth>
             <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Path Label Type
             </InputLabel>
             <NativeSelect
                  defaultValue={1}
                     inputProps={{
                      name: 'pathLabelType',
                      id: 'uncontrolled-native',
                     }}
                     onChange={(e) => onValueChange(e)}  >
               <option value={'DIA'}>DIA</option>
               <option value={'Tunnel'}>Tunnel</option>
             </NativeSelect>
        </FormControl>
    <FormControl>
        <InputLabel>Color </InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="color"/>
    </FormControl>


        <FormControl>
        <Button variant="contained" onClick={() => AddPathLabel()}>Add Path Label</Button>
        </FormControl>
    </Container>
  )
}

export default AddPathLabel