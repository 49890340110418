import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Grid, Paper, Typography } from "@material-ui/core";
import { gethome, getHost } from "../../service/api";
import Sidebar from "./Sidebar";
import SystemChecker from "./SystemChecker";

const DeviceDetails = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    try {
      console.log("called gethomefunc");
      const id = Cookies.get("access_token");
      const response = await gethome(id);
      console.log("response:", response.data);

      if (response.data && response.data.length > 0) {
        console.log("Parent component users:", response.data);
        console.log("====================================");
        console.log("====================================");

        const datae = {
          jsonrpc: "2.0",
          method: "host.get",
          params: {
            output: "extend",
            selectHostGroups: "extend",
          },
          id: 1,
        };
        const res = await getHost(datae);
        console.log("=============GHost=======================");
        console.log(res.data);
        console.log("====================================");

        // Map the data based on edgedevice_name
        const mappedData = response.data.map((user) => {
          const edgedeviceName = user.edgedevice_name;
          const matchingHost = res.data.result.find((host) => host.host === edgedeviceName);
          return { ...user, matchingHost };
        });

        // Filter out the items where matchingHost is undefined
        const filteredData = mappedData.filter((user) => user.matchingHost);

        console.log("Filtered Data:", filteredData);
        setUsers(filteredData);
      } else {
        console.log("No user data found");
      }
    } catch (error) {
      console.log("Error fetching users:", error);
    }
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={2}>
        
        <Paper style={{ maxHeight: "100vh", overflowY: "auto" }}>
          <Sidebar  />
        </Paper>
      </Grid>

      <Grid item xs={10}>
              <Typography variant="h4" align="center" gutterBottom>
                System Hardware requirement Analysis
              </Typography>
        <Paper style={{ maxHeight: "100vh", overflowY: "auto" }}>
          <SystemChecker />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default DeviceDetails;
