import React, { useEffect, useState } from "react";
import { Table, TableHead, TableRow, TableCell, TableBody, Button, Paper,Grid, Typography, LinearProgress } from "@material-ui/core";
import {identity_mapping_status, static_mapping_delete
} from '../../service/api';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Sidebar from "./Sidebar";
import Nav from "../Home/Nav";

const DMZ = () => {
  useEffect(() => {
    getidentity_mapping_status();
  }, []);

  const { id } = useParams();
  const navigate = useNavigate();

  const [gidentity_mapping_status, setidentity_mapping_status] = useState([]);
  const [progress, setProgress] = useState(0);



  const getidentity_mapping_status = async () => {
    try {
      const response = await identity_mapping_status(id);
      setidentity_mapping_status(response.data);
    } catch (error) {
      console.log('Error while getting routing table data:', error);
    }
  };



  const deletestatic_mapping = async (data) => {
    try {
      console.log(data)
      
      setProgress(0);
      const response =await static_mapping_delete(data, id);
      const progressPercentage = response.data.progress;
      console.log('progresspercent',progressPercentage);
      setProgress(progressPercentage);
      displayAlert('Selected Rule Deleted', 'alert-danger');
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  }
const displayAlert = (message, className, hidden = false) => {
  const alertElement = document.getElementById('alertMessage');
  alertElement.textContent = message;
  alertElement.classList.add(className);
  alertElement.style.display = hidden ? 'none' : 'block';
};

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Nav name="DMZ Status" />
      </Grid>

      <Grid item xs={12} md={2}>
        <Paper style={{ overflowY: 'auto' }}>
          <Sidebar />
        </Paper>
      </Grid>

      <Grid item xs={12} md={8}>
        <Paper style={{ padding: "8%" }}>
            
            <div id="alertMessage" className="alert" hidden = "true"></div>
            <LinearProgress variant="determinate" value={progress} />
    <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Local Ip Address</TableCell>
                <TableCell>External Ip Address</TableCell> 
                <TableCell>Nat Type</TableCell> 
              </TableRow>
            </TableHead>
            <TableBody>
              {gidentity_mapping_status?.map((user) => (
                <TableRow key={user.local_ip_address}>
                  <TableCell>{user.local_ip_address}</TableCell>
                  <TableCell>{user.external_ip_address}</TableCell>
                  <TableCell>{user.nat_type}</TableCell>
                  <TableCell>
                <Button variant="contained" color="secondary"  onClick={() => deletestatic_mapping(user)} className="btn btn-primary">Delete</Button></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
    </Paper>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default DMZ;
