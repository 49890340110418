import React, { useState } from 'react';
import Navbar from "../Home/Sidebar";

const AddDevice = () => {
  const [isDownloaded, setDownloaded] = useState(false);

  const handleDownload = () => {
    window.location.href = 'https://assets.cloudetel.com/cloudeteldev/reach_wan/reachwan.sh';
    setDownloaded(true);
  };

  return (
    
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
        
        <div className="row border-bottom " >
            
                <li>
                    <span className="m-r-sm text-muted welcome-message">Welcome to Reach Manage.</span>
                </li>
            <Navbar />
        </div>
      <h2 style={{ color: '#fff', marginBottom: '20px' }}>
        Explore a type of networking technology that uses software-defined networking (SDN) principles to manage and optimize the performance of wide area networks (WANs).
      </h2>

      {!isDownloaded && (
        <button
          onClick={handleDownload}
          style={{
            backgroundColor: '#007BFF',
            color: '#fff',
            padding: '10px 20px',
            cursor: 'pointer',
            border: 'none',
            borderRadius: '5px',
          }}
        >
          Download ReachWAN Setup
        </button>
      )}

      {isDownloaded && (
        <div>
          <h3 style={{ color: '#fff', marginTop: '20px' }}>Installation Steps:</h3>
          <ol style={{ color: '#fff', paddingLeft: '20px' }}>
            <li>Download this file to make your device a ReachEdge device.</li>
            <li>Once the setup is complete, return to Reach Manage and go to Device Manager.</li>
            <li>Click on the details of the newly added device, and you will be redirected to the System Checker tab.</li>
            <li>If the requirements are satisfied, click on the "Send File" button to complete the remaining setup.</li>
            <li>Your device is now ready to use.</li>
          </ol>
        </div>
      )}
    </div>
  );
};

export default AddDevice;
