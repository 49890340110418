import React, { useState } from "react";
import { Button, Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Link } from "react-router-dom";

function DeviceTable({
  users = [],
  handleCheckboxChange,
  handleButtonClick,
  isCreatingTunnel,
}) {
  console.log("users of devicetable:", users);

  // Track the number of checked checkboxes
  const [checkedCount, setCheckedCount] = useState(0);

  // Extract the array of devices from the users array
  const devices = users || [];

  // Handle checkbox change
  const handleCheckboxChangeWithCount = (event, device) => {
    // Update the checked count based on whether the checkbox is checked or not
    if (event.target.checked) {
      setCheckedCount((prevCount) => prevCount + 1);
    } else {
      setCheckedCount((prevCount) => prevCount - 1);
    }

    // Call the original checkbox change handler
    handleCheckboxChange(event, device);
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Public IP</TableCell>
          <TableCell>Tunnel IP</TableCell>
          <TableCell>Branch Location</TableCell>
          <TableCell>Edge Device Name</TableCell>
          <TableCell>Status</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.isArray(devices) &&
          devices.map((device) => {
            return (
              <TableRow key={device.Public_ip}>
                {/* Render checkbox only if device is connected */}
                {device.status === 'connected' ?(
                  <TableCell>
                    <Checkbox onChange={(event) => handleCheckboxChangeWithCount(event, device)} />
                  </TableCell>
                ) : (
                  <TableCell>
                    
                  </TableCell>
                )}
                <TableCell>{device.Public_ip}</TableCell>
                <TableCell>{device.Tunnel_ip}</TableCell>
                <TableCell>{device.branch_location}</TableCell>
                <TableCell>{device.edgedevice_name}</TableCell>
                <TableCell align="center">
                  {/* Render dot with appropriate color based on device status */}
                  <span
                    style={{
                      display: 'inline-block',
                      width: 10,
                      height: 10,
                      borderRadius: '50%',
                      backgroundColor: device.status === 'connected' ? 'green' : 'red',
                    }}
                  />
                </TableCell>
                <TableCell>
                  {/* Conditionally render buttons based on device status */}
                  {device.status === 'connected' ?  (
                    <>
                  <Button
                    variant="contained"
                    style={{ marginRight: 10 }}
                    component={Link}
                    to={`/system_checker/${device.Tunnel_ip}`}
                  >
                    Device Details
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginRight: 10 }}
                    component={Link}
                    to={`/pinghost/${device.Tunnel_ip}`}
                  >
                    Diagnostics
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginRight: 10 }}
                    component={Link}
                    to={`/graph/${device.matchingHost.hostid}`}
                  >
                    Graphs
                  </Button>
                    </>
                  ) : (
                    <>
                  <Button
                    variant="contained"
                    style={{ marginRight: 10 }}
                    component={Link}
                    to={`/graph/${device.matchingHost.hostid}`}
                  >
                    Graphs
                  </Button>
                    </>)}
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
      <TableRow>
      </TableRow>
    </Table>
  );
}

export default DeviceTable;
