import React, { useEffect, useState } from "react";
import { Table, TableHead, TableRow, TableCell, TableBody, Button, Paper,Grid, Typography, LinearProgress } from "@material-ui/core";
import {getqos_policy_status,qos_delete
} from '../../service/api';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Sidebar from "./Sidebar";
import Nav from "../Home/Nav";

const QosPolicy = () => {
  useEffect(() => {
    getqos_policy();
  }, []);

  const { id } = useParams();
  const navigate = useNavigate();

  const [qos_policy_status, setqos_policy_status] = useState([]);
  const [progress, setProgress] = useState(0);


  

  const getqos_policy = async () => {
    try {
      const response = await getqos_policy_status(id);
      setqos_policy_status(response.data);
    } catch (error) {
      console.log('Error while getting routing table data:', error);
    }
  };


  const deleteqos = async (policy_name, dscp,match_dst_ip, match_dst_port, protocol ) => {
    try {
      const value ={policy_name : policy_name,dscp:dscp,match_dst_ip:match_dst_ip,match_dst_port:match_dst_port,protocol:protocol}
      
      setProgress(0);
      const response =await qos_delete(value, id);
      const progressPercentage = response.data.progress;
      console.log('progresspercent',progressPercentage);
      setProgress(progressPercentage);
      displayAlert('Selected QOS Policy Deleted', 'alert-danger');
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  }



const displayAlert = (message, className, hidden = false) => {
  const alertElement = document.getElementById('alertMessage');
  alertElement.textContent = message;
  alertElement.classList.add(className);
  alertElement.style.display = hidden ? 'none' : 'block';
};

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Nav name="Qos Policy Status" />
      </Grid>

      <Grid item xs={12} md={2}>
        <Paper style={{ overflowY: 'auto' }}>
          <Sidebar />
        </Paper>
      </Grid>

      <Grid item xs={12} md={8}>
        <Paper style={{ padding: "8%" }}>
            <div id="alertMessage" className="alert" hidden = "true"></div>
            <LinearProgress variant="determinate" value={progress} />
            
    <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>policy_name</TableCell>
                <TableCell>DHCP</TableCell> 
                <TableCell>Match Destination Ip</TableCell> 
                <TableCell>Match Destination Port</TableCell> 
                <TableCell>Protocol</TableCell> 
                <TableCell>Action</TableCell> 
              </TableRow>
            </TableHead>
            <TableBody>
              {qos_policy_status?.map((user) => (
                <TableRow key={user.policy_name}>
                  <TableCell>{user.policy_name}</TableCell>
                  <TableCell>{user.dscp}</TableCell>
                  <TableCell>{user.match_dst_ip}</TableCell>
                  <TableCell>{user.match_dst_port}</TableCell>
                  <TableCell>{user.protocol}</TableCell>
                  <TableCell>
                <Button variant="contained" color="secondary"  onClick={() => deleteqos(user.policy_name,user.dscp,user.match_dst_ip,user.match_dst_port,user.protocol )} className="btn btn-primary">Delete</Button></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
    </Paper>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default QosPolicy;
