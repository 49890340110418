import axios from "axios";


const apiUrl = process.env.REACT_APP_API_URL_node;
const URL = 'https://172.104.146.239:9002'

export const getallusers = async () =>{
    try{
        return await axios.get(`${apiUrl}/user/getallusers`)
    }catch(error){
        console.log('Error while Calling Get All User API', error);
    }
}

export const gethome = async (id) =>{
    try{
        console.log(`called api at ${apiUrl}/${id}`);
        return await axios.get(`${apiUrl}/${id}`)
    }catch(error){
        console.log('Error while Calling Get All User API', error);
    }
}
export const getuser = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/user/getUser/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}
export const getroutingtable = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/getroutingtable/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}

export const heartbeat = async (id) => {
  try {
    console.log("started heartbeat");
    return await axios.get(`${apiUrl}/user/heartbeat/${id}`, {
      timeout: 10000, // 120 seconds in milliseconds
    });
  } catch (error) {
    console.log("got timeout");
    if (axios.isCancel(error)) {
      console.log('Request canceled due to timeout');
      throw error; // Throw the timeout error
    } else {
      console.log('Error while calling User API', error);
      // Handle other errors as needed or simply rethrow them
      throw error;
    }
  }
};

  
export const getvppinterfacedetails = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/user/getvppinterfacedetails/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}

export const setcsrf = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/setcsrf/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}
export const getcsrf = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/getcsrf/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}
export const getallbackup = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/getallbackup/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}


export const getinterface = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/getinterface/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}


export const getgeneralinfo = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/getgeneralinfo/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}



export const getnetns_interface_details = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/getnetns_interface_details/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}




export const getubuntu_interface_details = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/getubuntu_interface_details/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}




export const getqos_policy_status = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/getqos_policy_status/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}



export const getvxlan_details = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/getvxlan_details/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}

export const getsystemchecker = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/getsystemchecker/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}

export const check_vpp_installed = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/check_vpp_installed/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}

export const check_frr_installed = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/check_frr_installed/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}

export const check_dataplane_install = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/check_dataplane_install/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}

export const check_controlplane_install = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/check_controlplane_install/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}



export const getvxlan_tunnel_details = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/getvxlan_tunnel_details/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}



export const getallvxlantunnel = async () =>{
    try{
        console.log("axios");
        return await axios.get(`${apiUrl}/devices/getallvxlantunnel`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}



export const static_mapping_status = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/static_mapping_status/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}





export const identity_mapping_status = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/identity_mapping_status/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}


export const getacl_status = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/getacl_status/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}


export const getacl_statusout = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/getacl_statusout/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}
export const send_file = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/send_file/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}
export const dataplane_install = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/dataplane_install/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}
export const controlplane_install = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/controlplane_install/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}
export const reach_install = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/reach_install/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}
export const restart_reach = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/restart_reach/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}
export const reach_reset = async (id) =>{
    try{
        return await axios.get(`${apiUrl}/devices/reach_reset/${id}`)

    }
    catch(error){
        console.log('Error While calling User Api',error)
    }
}



export const loginuser = async (user,id) =>{
    try{
        return await axios.post(`${apiUrl}/user/loginuser/${id}`, user)
    }catch(error){
        console.log('Error while Calling Edit User API', error);
    }
}


export const postTunnelData = async (user,id) =>{
    try{
        return await axios.post(`${apiUrl}/devices/postTunnelData/${id}`, user)
    }catch(error){
        console.log('Error while Calling Edit User API', error);
    }
}

export const getHost = async (user) =>{
    try{
        return await axios.post(`${apiUrl}/GHost`, user)
    }catch(error){
        console.log('Error while Calling HOst API', error);
    }
}





export const postoutbound_aclrule = async (user,id) =>{
    try{
        return await axios.post(`${apiUrl}/devices/postoutbound_aclrule/${id}`, user)
    }catch(error){
        console.log('Error while Calling Edit User API', error);
    }
}




export const postinbound_aclrule = async (user,id) =>{
    try{
        return await axios.post(`${apiUrl}/devices/postinbound_aclrule/${id}`, user)
    }catch(error){
        console.log('Error while Calling Edit User API', error);
    }
}

export const port_forward_add = async (user,id) =>{
    try{
        return await axios.post(`${apiUrl}/devices/port_forward_add/${id}`, user)
    }catch(error){
        console.log('Error while Calling Edit User API', error);
    }
}
export const static_mapping_delete = async (user,id) =>{
    try{
        return await axios.post(`${apiUrl}/devices/static_mapping_delete/${id}`, user)
    }catch(error){
        console.log('Error while Calling Edit User API', error);
    }
}
export const deleteportforword = async (user,id) =>{
    try{
        return await axios.post(`${apiUrl}/devices/deleteportforword/${id}`, user)
    }catch(error){
        console.log('Error while Calling Edit User API', error);
    }
}
export const aclrule_delete = async (user,id) =>{
    try{
        return await axios.post(`${apiUrl}/devices/aclrule_delete/${id}`, user)
    }catch(error){
        console.log('Error while Calling Edit User API', error);
    }
}
export const qos_delete = async (user,id) =>{
    try{
        return await axios.post(`${apiUrl}/devices/qos_delete/${id}`, user)
    }catch(error){
        console.log('Error while Calling Edit User API', error);
    }
}
export const upaclrule_add = async (user,id) =>{
    try{
        return await axios.post(`${apiUrl}/devices/upaclrule_add/${id}`, user)
    }catch(error){
        console.log('Error while Calling Edit User API', error);
    }
}
export const downaclrule_add = async (user,id) =>{
    try{
        return await axios.post(`${apiUrl}/devices/downaclrule_add/${id}`, user)
    }catch(error){
        console.log('Error while Calling Edit User API', error);
    }
}
export const dhcp_conf = async (user,id) =>{
    try{
        return await axios.post(`${apiUrl}/devices/dhcp_conf/${id}`, user)
    }catch(error){
        console.log('Error while Calling Edit User API', error);
    }
}
export const testConnection = async (user,id) =>{
    try{
        return await axios.post(`${apiUrl}/devices/testConnection/${id}`, user)
    }catch(error){
        console.log('Error while Calling Edit User API', error);
    }
}
export const dns_conf = async (user,id) =>{
    try{
        return await axios.post(`${apiUrl}/devices/dns_conf/${id}`, user)
    }catch(error){
        console.log('Error while Calling Edit User API', error);
    }
}
export const rate_limit
= async (user,id) =>{
    try{
        return await axios.post(`${apiUrl}/devices/rate_limit/${id}`, user)
    }catch(error){
        console.log('Error while Calling Edit User API', error);
    }
}
export const changeMTU = async (user,id) =>{
    try{
        return await axios.post(`${apiUrl}/devices/changeMTU/${id}`, user)
    }catch(error){
        console.log('Error while Calling Edit User API', error);
    }
}
export const changeipv4 = async (user,id) =>{
    try{
        return await axios.post(`${apiUrl}/devices/changeipv4/${id}`, user)
    }catch(error){
        console.log('Error while Calling Edit User API', error);
    }
}
export const changestate = async (user,id) =>{
    try{
        return await axios.post(`${apiUrl}/devices/changestate/${id}`, user)
    }catch(error){
        console.log('Error while Calling Edit User API', error);
    }
}
export const identity_mapping_add = async (user,id) =>{
    try{
        return await axios.post(`${apiUrl}/devices/identity_mapping_add/${id}`, user)
    }catch(error){
        console.log('Error while Calling Edit User API', error);
    }
}
export const postqos_policy_add = async (user,id) =>{
    try{
        return await axios.post(`${apiUrl}/devices/postqos_policy_add/${id}`, user)
    }catch(error){
        console.log('Error while Calling Edit User API', error);
    }
}
export const poststatic_route_add = async (user,id) =>{
    try{
        return await axios.post(`${apiUrl}/devices/poststatic_route_add/${id}`, user)
    }catch(error){
        console.log('Error while Calling Edit User API', error);
    }
}


export const deleteVxlanData = async (user,id) =>{
    try{
        return await axios.post(`${apiUrl}/devices/deleteVxlanData/${id}`, user)
    }catch(error){
        console.log('Error while Calling Edit User API', error);
    }
}

export const createtunnel = async (device1IP, device1TunnelIP, device2IP, device2TunnelIP) => {
    try {
      const response = await axios.post(`${apiUrl}/devices/createtunnel`, {
        device1IP,
        device1TunnelIP,
        device2IP,
        device2TunnelIP
      });
      return response.data;
    } catch (error) {
      console.log("Error while calling createtunnel API", error);
      throw error;
    }
  };
  

export const editUsr = async (user,id) =>{
    try{
        return await axios.post(`${apiUrl}/user/editUsr/${id}`, user)
    }catch(error){
        console.log('Error while Calling Edit User API', error);
    }
}


export const adduser = async (data) =>{
    try{
       return await axios.post(`${apiUrl}/user/adduser`, data)
    }catch(error){
        console.log('Error while Calling add User API', error);

    } 
}
export const addOrganization = async (data) =>{
    try{
       return await axios.post(`${apiUrl}/organization/addOrganization`, data)
    }catch(error){
        console.log('Error while Calling add User API', error);

    } 
}


export const getallorganizations = async () =>{
    try{
        return await axios.get(`${apiUrl}/organization/getallorganizations`)
    }catch(error){
        console.log('Error while Calling Get All User API', error);
    }
}

export const generateToken = async (data) =>{
    try{
       return await axios.post(`${apiUrl}/token/generateToken`, data)
    }catch(error){
        console.log('Error while Calling add User API', error);

    } 
}


export const getallTokens = async () =>{
    try{
        return await axios.get(`${apiUrl}/token/getallTokens`)
    }catch(error){
        console.log('Error while Calling Get All User API', error);
    }
}
export const getOneToken = async (tokenName) => {
    try {
        const response = await axios.get(`${apiUrl}/token/getallTokens`);
        const tokens = response.data;
        const token = tokens.find(t => t.tokenName === tokenName); // Assuming 'tokenName' is the property name of the token in the response data
        return token;
    } catch (error) {
        console.log('Error while calling getOneToken API', error);
    }
};


export const addPathLabel = async (data) =>{
    try{
       return await axios.post(`${apiUrl}/pathLabel/addPathLabel`, data)
    }catch(error){
        console.log('Error while Calling add User API', error);

    } 
}

export const getPathLabel = async () =>{
    try{
        return await axios.get(`${apiUrl}/pathLabel/getPathLabel`)
    }catch(error){
        console.log('Error while Calling Get All User API', error);
    }
}


export const addResources = async (data) =>{
    try{
       return await axios.post(`${apiUrl}/organization/addOrganization`, data)
    }catch(error){
        console.log('Error while Calling add User API', error);

    } 
}


export const getallresources = async () =>{
    try{
        return await axios.get(`${apiUrl}/organization/getallorganizations`)
    }catch(error){
        console.log('Error while Calling Get All User API', error);
    }
}


export const addPeers = async (data) =>{
    try{
       return await axios.post(`${apiUrl}/organization/addOrganization`, data)
    }catch(error){
        console.log('Error while Calling add User API', error);

    } 
}


export const getallPeers = async () =>{
    try{
        return await axios.get(`${apiUrl}/organization/getallorganizations`)
    }catch(error){
        console.log('Error while Calling Get All User API', error);
    }
}
