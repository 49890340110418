import React, { useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";

import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCogs,
  faPlus,
  faLock,
  faHome,
  faLaptopFile,
  faPeopleArrows,
  faSnowflake,
  faBorderNone,
  faUsersLine,
  faFireBurner,
  faDumpsterFire,
  faQ,
  faTemperatureArrowUp,
  fa1,
  faMapPin,
  faChartLine,
  faGear,
  faEthernet,
  faServer,
  faUserShield,
  faUserLock,
  faPersonMilitaryToPerson,
  faPersonMilitaryRifle,
  faArrowsLeftRight,
  faArrowsLeftRightToLine,
  faArrowUpFromWaterPump,
  faWaveSquare,
  faSheetPlastic,
  faMoneyBillTransfer,
  
} from "@fortawesome/free-solid-svg-icons";

import logo from "./logo.png"; // Import your logo image
const Sidebar = ({ clickedIndex }) => {
  const location = useLocation();
  const { id } = useParams();

  const deleteCookie = (cookieName) => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };
  const handleLogout = () => {
    // Call your deleteCookie function here to delete the user authentication cookie
    deleteCookie("isvalid");
    deleteCookie("user");
    deleteCookie("refresh_token");
    deleteCookie("access_token_expiry");
    deleteCookie("access_token");
    alert("Logged Out Successfully");
    setTimeout(() => {
      window.location.reload(); // Refresh the page after 1 second
    }, 1000);
    // Replace "your_cookie_name" with the actual name of your authentication cookie
    // You may also want to perform other logout-related tasks here (e.g., redirecting to the login page)
  };

  const drawerItems = [
    { text: "Home", icon: faHome, path: "/" },
    { text: "Device Manager", icon: faCogs, path: "/devices" },
    { text: "System Checker", icon: faGear, path: `/system_checker/${id}` },
    {
      text: "Interfaces",
      icon: faPeopleArrows,
      path: `/interfaces/${id}`,
      items: [
        { text: "LAN & WAN Interfaces", icon: faEthernet, path: `/interfaces/${id}` },
        { text: "DHCP", icon: faServer, path: `/dhcp/${id}` },
      ],
    },
    {
      text: "Routing",
      icon: faSnowflake,
      path: `/routing/${id}`,
      items: [
        { text: "Routing Info", icon: faBorderNone, path: `/routing_info/${id}` },
        {
          text: "Add Static Routing",
          icon: faUsersLine,
          path: `/static_routing/${id}`,
        },
      ],
    },
    { text: "Tunnel Status", icon: faMoneyBillTransfer, path: `/tunnel_status/${id}` },
    {
      text: "Port Forwarding",
      icon: faArrowUpFromWaterPump,
      path: `/routing/${id}`,
      items: [
        { text: "Add Port Forwarding Rule", icon: faWaveSquare, path: `/add_PF/${id}` },
        {
          text: "Port Forwarding Status",
          icon: faSheetPlastic,
          path: `/port_forwarding_status/${id}`,
        },
      ],
    },
    {
      text: "Firewall",
      icon: faFireBurner,
      path: `/firewallstatus/${id}`,
      items: [
        {
          text: "Firewall status",
          icon: faDumpsterFire,
          path: `/firewall_status/${id}`,
        },
        { text: "Add Inbound Rule", icon: faUserShield, path: `/Inbound_rule/${id}` },
        {
          text: "Add Outbound Rule",
          icon: faUserLock,
          path: `/Outbound_rule/${id}`,
        },
      ],
    },
    {
      text: "QOS",
      icon: faQ,
      path: `/firewallstatus/${id}`,
      items: [
        {
          text: "Add Qos Policy",
          icon: faPersonMilitaryToPerson,
          path: `/Qos_Policy/${id}`,
        },
        { text: "Rate Limiting", icon: faTemperatureArrowUp, path: `/ratelimiting/${id}` },
        {
          text: "Qos Policy Status",
          icon: faPersonMilitaryRifle,
          path: `/qos_policy_status/${id}`,
        },
      ],
    },
    {
      text: "DMZ",
      icon: faArrowsLeftRightToLine,
      path: `/firewallstatus/${id}`,
      items: [
        {
          text: "Add DMZ(1:1 Mapping)",
          icon: faMapPin,
          path: `/add_dmz/${id}`,
        },
        { text: "DMZ Status", icon: faChartLine, path: `/dmz/${id}` },
        
      ],
    },
    { text: "Add Device", icon: faPlus, path: "/addNewDevice" },
  ];
const renderMenuItem = (item, index) => {
  const isActive = location.pathname === item.path;
  let isSubItemActive = false;

  if (item.items) {
    item.items.forEach((subItem) => {
      if (location.pathname === subItem.path) {
        isSubItemActive = true;
      }
    });
 return (
      <div key={index}>
        <ListItem
          button
          onClick={() => setCollapseIndex(collapseIndex === index ? null : index)}
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={item.icon} />
          </ListItemIcon>
          <ListItemText primary={item.text} />
          {collapseIndex === index ? (
            <KeyboardArrowUp />
          ) : (
            <KeyboardArrowDown />
          )}
        </ListItem>
        <Collapse in={collapseIndex === index || isSubItemActive} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.items.map((subItem, subIndex) => (
              <ListItem
                button
                key={subIndex}
                component={Link}
                to={subItem.path}
                className={location.pathname === subItem.path ? "active" : ""}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={subItem.icon} />
                </ListItemIcon>
                <ListItemText primary={subItem.text} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </div>
    );
  } else {
    return (
      <ListItem
        button
        key={index}
        component={Link}
        to={item.path}
        className={isActive ? "active" : ""}
      >
        <ListItemIcon>
          <FontAwesomeIcon icon={item.icon} />
        </ListItemIcon>
        <ListItemText primary={item.text} />
      </ListItem>
    );
  }
};


  const [collapseIndex, setCollapseIndex] = useState(null);

  return (
    <div
      style={{
        position: "fixed",
            marginTop: '5%',
        left: 0,
        top: 0,
        width: "250px",
        height: "100%",
        background: "#fff",
      }}
    >
      <List>
        {drawerItems.map((item, index) => renderMenuItem(item, index))}
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faLock} />
          </ListItemIcon>
          <ListItemText primary="Log out" />
        </ListItem>
      </List>
    </div>
  );
};

export default Sidebar;
