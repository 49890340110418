import {React, useEffect, useState} from 'react';
import "./token.css"
import {getallTokens} from '../../service/api'
import { useNavigate,  Link, Router  } from "react-router-dom"
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material'
import axios from 'axios'


function Token() {
  

  const [users, setUsers] = useState([]);

useEffect(() => {
  getAllTokens();
},[]);

const getAllTokens = async () =>{
  let response = await getallTokens();
  setUsers(response.data);
}

const navigate = useNavigate();
const newToken = () => {
  navigate('/inventory/newToken')
}


  return (
    <div className="token">
        <div className="button" onClick={newToken}>New Token</div>
        <h1>Tokens</h1>
        <Table>
            <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Token</TableCell>
              <TableCell>Organization</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
              {
                users.map(user =>(
                  <TableRow>
                  <TableCell>{user.tokenName}</TableCell>
                    <TableCell>{user.token}</TableCell>
                    <TableCell>{user.organizationName}</TableCell>
                    <TableCell>{user.timeStamp}</TableCell>
                    <TableCell>
                      <Button variant='contained' style={{marginRight: 10}} component={Link} to={`/editToken/${user._id}`}>Edit</Button>
                      <Button variant='contained' color="error">Delete</Button>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
        </Table>
    </div>

  )
}

export default Token

