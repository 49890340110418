import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "./login.css";

const Login = ({ setLoginUser }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const login = async () => {
    try {
      console.log("in thee fuction");
const apiUrl = process.env.REACT_APP_API_URL_node;
console.log("api url :",apiUrl);
      const response = await axios.post(`${apiUrl}/login`, user);
      console.log("got the response",response);

      if (response.status === 200) {
        console.log('if condition');
        const { access_token, access_token_expiry, refresh_token } = response.data.data;

        Cookies.set("access_token", access_token);
        console.log("fist access" , access_token);
        Cookies.set("access_token_expiry", access_token_expiry);
        Cookies.set("refresh_token", refresh_token);

        const loggedInUser = { _id: access_token };
        Cookies.set("isvalid", "true");

        alert(`Logged In Successfully!`);
        setLoginUser(loggedInUser);
    navigate("/")
      } else {
        console.error("Login failed:", response.data.error);
        // Handle login error here
      }
    } catch (error) {
      console.error("Login failed:", error);
      // Handle login error here
    }
  };


  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      login();
    }
  };

  return (
    <div className="login">
      <h1>Welcome to Reach_Manage <br />Login</h1>
      <input
        type="text"
        name="email"
        value={user.email}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="Enter your Email"
      />
      <input
        type="password"
        name="password"
        value={user.password}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="Enter your Password"
      />
      <button className="button" onClick={login}>
        Login
      </button>
    </div>
  );
};

export default Login;
