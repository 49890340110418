import {React, useState, useEffect} from 'react'
import {FormGroup, FormControl, Input, InputLabel,NativeSelect, Typography, styled, Button} from "@mui/material"
import { editUsr, getuser } from '../../service/api'
import { useNavigate, useParams } from 'react-router-dom'


const Container = styled(FormGroup)`
    width: 500%;
    margin: 5% auto 0 10%;
    & > div{
        margin-top: 20px;
    }


`


function EditUser() {
    
    
const defaultValues = {
    companyname: "",
    businessemail: "",
    firstname: "",
    lastname: "",
    jobtitle: "",
    phonenumber: "",
    password: "",
    permissionTo:"",
    entity:"",
    role:""

}
const navigate = useNavigate()

const { id } = useParams();
    const [user, setUser] = useState(defaultValues);

    useEffect(() =>{
        loadUserDetails();
    },[])
    const loadUserDetails = async () => {
        const response = await getuser(id)
        setUser(response.data);
    }

    const onValueChange = (e) =>{
        setUser({...user, [e.target.name]: e.target.value})
    }

     const editUserDetails = async() =>{
        await editUsr(user);
        
     }
    

  return (
    <Container fullWidth>
        <Typography variant='h4'>Edit User</Typography>
    <FormControl>
        <InputLabel>Company Name </InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="companyname" value={user.companyname}  />
    </FormControl>
        <FormControl>
            <InputLabel>Business Email</InputLabel>
        <Input onChange={(e) => onValueChange(e)} name="businessemail"value={user.businessemail} />
        </FormControl>
        <FormControl>
            <InputLabel>First Name</InputLabel>
        <Input onChange={(e) => onValueChange(e)} name="firstname" value={user.firstname} />
        </FormControl>
        <FormControl>
            <InputLabel>Last Name</InputLabel>
        <Input onChange={(e) => onValueChange(e)} name="lastname" value={user.lastname} />
        </FormControl>
        <FormControl>
            <InputLabel>Job Title</InputLabel>
        <Input onChange={(e) => onValueChange(e)} name="jobtitle" value={user.jobtitle} />
        </FormControl>
        <FormControl>
            <InputLabel>Phone</InputLabel>
        <Input type='number' onChange={(e) => onValueChange(e)} name="phonenumber" value={user.phonenumber} />
        </FormControl>
        <FormControl>
            <InputLabel>Password</InputLabel>
        <Input type='password' onChange={(e) => onValueChange(e)} name="password" value={user.password} />
        </FormControl>
        <FormControl fullWidth>
             <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Permission To
             </InputLabel>
             <NativeSelect
                  defaultValue={user.permissionTo}
                     inputProps={{
                      name: 'permissionTo',
                      id: 'uncontrolled-native',
                     }}
                     onChange={(e) => onValueChange(e)}  >
               <option value={1}>Organization</option>
               <option value={2}>Cloud Etel</option>
               <option value={3}>Organiztion 1</option>
               <option value={4}>Organiztion 2</option>
             </NativeSelect>
        </FormControl>
        <FormControl fullWidth>
             <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Entity
             </InputLabel>
             <NativeSelect
                  defaultValue={user.entity}
                     inputProps={{
                      name: 'entity',
                      id: 'uncontrolled-native',
                     }}
                     onChange={(e) => onValueChange(e)}  >
               <option value={1}>Customer 1</option>
               <option value={2}>Customer 2</option>
               <option value={3}>Customer 3</option>
             </NativeSelect>
        </FormControl>
        <FormControl fullWidth>
             <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Role
             </InputLabel>
             <NativeSelect
                  defaultValue={user.role}
                     inputProps={{
                      name: 'role',
                      id: 'uncontrolled-native',
                     }}
                     onChange={(e) => onValueChange(e)}  >
               <option value={1}>Admin</option>
               <option value={2}>Manager</option>
               <option value={3}>viewer 1</option>
               <option value={4}>viewer 2</option>
             </NativeSelect>
        </FormControl>  
        <FormControl>
        <Button variant="contained" onClick={() => editUserDetails()}>Edit User</Button>
        </FormControl>
    </Container>
  )
}

export default EditUser