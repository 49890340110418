import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  LinearProgress,
} from "@material-ui/core";
import { getinterface, port_forward_add } from "../../service/api";
import { useNavigate, useParams, Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Nav from "../Home/Nav";
import { Box } from "@mui/material";

const AddPF = () => {
  const [port_forwardd, setport_forwardd] = useState({
    int_index: 2,
    protocols: ["tcp", "udp"],
    ports: "none",
    internal_ip: "none",
    internal_port_start: "none",
  });

  useEffect(() => {
    getInterfc();
  }, []);

  const { id } = useParams();
  const navigate = useNavigate();

  const [progress, setProgress] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [interfc, setInterfc] = useState([]);
  const [IpAddress, setInternalIpAddress] = useState("");
  const [isValidIpAddress, setIsValidIpAddress] = useState(true);
  const [selectedProtocols, setSelectedProtocols] = useState([]); // State variable for selected protocols
  const [portsInput, setPortsInput] = useState("");
  const [isValidPortsInput, setIsValidPortsInput] = useState(true);
  const [isValidInternalPortStart, setIsValidInternalPortStart] =
    useState(true);
  const [internalPortStart, setInternalPortStart] = useState("");

  const onValueChange = (e) => {
    const { name, value } = e.target;
    console.log(e.target);

    setport_forwardd({ ...port_forwardd, [name]: value });

    if (name === "int_index") {
      setSelectedIndex(value);
    }
  };
  const handlePortsInputChange = (event) => {
    const input = event.target.value;
    setPortsInput(input);
    setIsValidPortsInput(validatePortsInput(input));
  };

  const handleInternalPortStartChange = (event) => {
    const input = event.target.value;
    setInternalPortStart(input);
    setIsValidInternalPortStart(validateInternalPortStart(input));
  };

  const validateInternalPortStart = (input) => {
    const port = parseInt(input);
    return !isNaN(port) && port >= 0 && port <= 65535;
  };
  const validatePortsInput = (input) => {
    const regex = /^(\d{1,5}|\d{1,5}-\d{1,5})$/;
    if (!regex.test(input)) {
      return false;
    }

    const parts = input.split("-");
    const a = parseInt(parts[0]);
    const b = parseInt(parts[1]);

    if (parts.length === 1) {
      return a >= 0 && a <= 65535;
    } else if (parts.length === 2) {
      return a >= 0 && a <= 65535 && b >= 0 && b <= 65535 && b > a;
    }

    return false;
  };


  const handleIpAddressChange = (event) => {
    const ipAddress = event.target.value;
    setInternalIpAddress(ipAddress);
    setIsValidIpAddress(validateIp(ipAddress));
  };

  const validateIp = (ipAddress) => {
    const parts = ipAddress.split(".");
    if (parts.length !== 4) {
      return false;
    }
    const a = parseInt(parts[0]);
    const b = parseInt(parts[1]);
    const c = parseInt(parts[2]);
    const d = parseInt(parts[3]);

    if (a === 10) {
      if (!(0 <= b && b <= 255 && 0 <= c && c <= 255 && 0 <= d && d <= 255)) {
        return false;
      }
    } else if (a === 172) {
      if (!(16 <= b && b <= 31 && 0 <= c && c <= 255 && 0 <= d && d <= 255)) {
        return false;
      }
    } else if (a === 192) {
      if (!(b === 168 && 0 <= c && c <= 255 && 0 <= d && d <= 255)) {
        return false;
      }
    } else {
      return false;
    }

    return true;
  };

  const getInterfc = async () => {
    try {
      const response = await getinterface(id);
      setInterfc(response.data);
    } catch (error) {
      console.log("Error while getting interface data:", error);
    }
  };

  const port_forward = async () => {
    try {
      setProgress(0);
      const response = await port_forward_add(port_forwardd, id);
      const progressPercentage = response.data.progress;
      console.log("progresspercent", progressPercentage);
      setProgress(progressPercentage);
      displayAlert("Data posted successfully!", "alert-success");
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert("Error posting data. Please try again.", "alert-error");
    }
  };

  const isportFormValid =
    isValidPortsInput &&
    isValidInternalPortStart &&
    selectedIndex &&
    isValidIpAddress;

  const displayAlert = (message, className, hidden = false) => {
    const alertElement = document.getElementById("alertMessage");
    alertElement.textContent = message;
    alertElement.classList.add(className);
    alertElement.style.display = hidden ? "none" : "block";
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Nav name="Add Port Forwarding Rule" />
      </Grid>

      <Grid item xs={12} md={2}>
        <Paper style={{ overflowY: "auto" }}>
          <Sidebar />
        </Paper>
      </Grid>

      <Grid item xs={12} md={8}>
        <Paper style={{ padding: "8%" }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <FormControl style={{ marginRight: '10px', flex: 1 }}>
                <InputLabel id="interface-select-label">Wan Index</InputLabel>
                <Select
                  name="int_index"
                  labelId="interface-select-label"
                  id="interface-select"
                  style={{
                    minWidth: "200px",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    overflow: "auto",
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: 300 },
                    },
                  }}
                  value={selectedIndex}
                  onChange={onValueChange}
                >
                  {interfc?.map((user) => (
                    <MenuItem value={user.int_index} key={user.int_index}>
                      {user.type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ marginRight: '10px', flex: 1 }}>
                <InputLabel>Protocol</InputLabel>
                <Select
                  id="protocols"
                  style={{
                    minWidth: "200px",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    overflow: "auto",
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: 300 },
                    },
                  }}
                  name="protocols"
                  multiple
                  value={
                    selectedProtocols.length > 0
                      ? selectedProtocols
                      : ["tcp", "udp"]
                  }
                  onChange={(event) => {
                    setSelectedProtocols(event.target.value);
                    onValueChange(event); // Call the onValueChange function
                  }}
                  input={<Input />}
                  renderValue={(selected) => selected.join(", ")}
                >
                  <MenuItem value="any">any</MenuItem>
                  <MenuItem value="icmp">icmp</MenuItem>
                  <MenuItem value="tcp">tcp</MenuItem>
                  <MenuItem value="udp">udp</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <FormControl style={{ marginRight: '10px', flex: 1 }}>
                <InputLabel>Internal IP Address</InputLabel>
                <Input
                  name="internal_ip"
                  value={IpAddress}
                  onChange={(event) => {
                    handleIpAddressChange(event);
                    onValueChange(event); // Call the onValueChange function
                  }}
                  error={!isValidIpAddress}
                />
              </FormControl>

              <FormControl style={{ marginRight: '10px', flex: 1 }}>
                <InputLabel>ports</InputLabel>
                <Input
                  name="ports"
                  value={portsInput}
                  placeholder="440-444"
                  onChange={(event) => {
                    handlePortsInputChange(event);
                    onValueChange(event); // Call the onValueChange function
                  }}
                  error={!isValidPortsInput}
                />
              </FormControl>

              <FormControl>
                <InputLabel>Internal Port start</InputLabel>
                <Input
                  name="internal_port_start"
                  value={internalPortStart}
                  placeholder="440"
                  type="number"
                  onChange={(event) => {
                    handleInternalPortStartChange(event);
                    onValueChange(event); // Call the onValueChange function
                  }}
                  error={!isValidInternalPortStart}
                />
              </FormControl>
            </div>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <FormControl>
                <Button
                  variant="contained"
                  onClick={port_forward}
                  disabled={!isportFormValid}
                >
                  Forward
                </Button>
              </FormControl>
            </div>
          </Box>
          <div id="alertMessage" className="alert" hidden="true"></div>
          <LinearProgress variant="determinate" value={progress} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AddPF;
