import {React, useEffect, useState} from 'react';
import "./pathlabel.css"
import {getPathLabel} from '../../service/api'
import { useNavigate,  Link, Router  } from "react-router-dom"
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material'
import axios from 'axios'

function PathLabel() {

  const [users, setUsers] = useState([]);

useEffect(() => {
  getAllPathLabel();
},[]);

const getAllPathLabel = async () =>{
  let response = await getPathLabel();
  setUsers(response.data);
}

const navigate = useNavigate();
const addPathLabel = () => {
  navigate('/inventory/addPathLabel')
}


  return (
    <div className="pathLabel">
        <div className="button" onClick={addPathLabel}> New Path Label</div>
        <h1>Path Labels</h1>
        <Table>
            <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Color</TableCell>
              <TableCell></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
              {
                users.map(user =>(
                  <TableRow>
                  <TableCell>{user.pathLabelName}</TableCell>
                    <TableCell>{user.description}</TableCell>
                    <TableCell>{user.pathLabelType}</TableCell>
                    <TableCell>{user.color}</TableCell>
                    <TableCell>
                      <Button variant='contained' style={{marginRight: 10}} component={Link} to={`/edit/${user._id}`}>Edit</Button>
                      <Button variant='contained' color="error">Delete</Button>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
        </Table>
    </div>

  )
}

export default PathLabel

