import React, { useEffect, useState } from "react";
import {  Button, Paper,Grid, Typography, FormControl, InputLabel, Select, MenuItem, Input, LinearProgress } from "@material-ui/core";
import {getinterface,identity_mapping_add
} from '../../service/api';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Sidebar from "./Sidebar";
import Nav from "../Home/Nav";
import { Box } from "@mui/material";

const AddDMZ = () => {



  const [identity_mappingg, setidentity_mappingg] = useState({
    int_index: 2,
    protocols: ['tcp', 'udp'],
    internal_ip_address: 'none',
  });


  useEffect(() => {
    getInterfc();
  }, []);

  const { id } = useParams();
  const navigate = useNavigate();

  const [interfc, setInterfc] = useState([]);
  const [progress, setProgress] = useState(0);
  const [IpAddress, setInternalIpAddress] = useState('');
  const [selectedIndex, setSelectedIndex] = useState('');
  const [isValidIpAddress, setIsValidIpAddress] = useState(true);
  const [destAddress, setInternaldestAddress] = useState('');
  const [isValiddestAddress, setIsValiddestAddress] = useState(true);


  const onValueChange = (e) => {
    
    const { name, value } = e.target;
    console.log(e.target);
  
    setidentity_mappingg({ ...identity_mappingg, [name]: value });
  
    if (name === 'int_index') {
      setSelectedIndex(value);
    }
  };
  
  

  const getInterfc = async () => {
    try {
      const response = await getinterface(id);
      setInterfc(response.data);
      if (response.data) {
      }
    } catch (error) {
      console.log('Error while getting interface data:', error);
    }
  };


  const handleIpAddressChange = (event) => {
    const ipAddress = event.target.value;
    setInternalIpAddress(ipAddress);
    setIsValidIpAddress(validateIp(ipAddress));
  };

  const validateIp = (ipAddress) => {
    const parts = ipAddress.split('.');
    if (parts.length !== 4) {
      return false;
    }
    const a = parseInt(parts[0]);
    const b = parseInt(parts[1]);
    const c = parseInt(parts[2]);
    const d = parseInt(parts[3]);

    if (a === 10) {
      if (!(0 <= b && b <= 255 && 0 <= c && c <= 255 && 0 <= d && d <= 255)) {
        return false;
      }
    } else if (a === 172) {
      if (!(16 <= b && b <= 31 && 0 <= c && c <= 255 && 0 <= d && d <= 255)) {
        return false;
      }
    } else if (a === 192) {
      if (!(b === 168 && 0 <= c && c <= 255 && 0 <= d && d <= 255)) {
        return false;
      }
    } else {
      return false;
    }

    return true;
  };
  const identity_mapping = async () => {
    try {
      setProgress(0);
      const response = await identity_mapping_add(identity_mappingg, id);
      const progressPercentage = response.data.progress;
      console.log('progresspercent',progressPercentage);
      setProgress(progressPercentage);
      displayAlert('Data posted successfully!', 'alert-success');
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  };

const displayAlert = (message, className, hidden = false) => {
  const alertElement = document.getElementById('alertMessage');
  alertElement.textContent = message;
  alertElement.classList.add(className);
  alertElement.style.display = hidden ? 'none' : 'block';
};

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Nav name="Add DMZ" />
      </Grid>

      <Grid item xs={12} md={2}>
        <Paper style={{ overflowY: 'auto' }}>
          <Sidebar />
        </Paper>
      </Grid>

      <Grid item xs={12} md={8}>
        <Paper style={{ padding: "8%" }}>
            
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
          
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
          
        
        <FormControl style={{ width: '50%' }}>
        <InputLabel  id="interface-select-label">WAN Index </InputLabel>
        <Select name="int_index" 
        labelId="interface-select-label"
        id="interface-select"
        value={selectedIndex}
        onChange={onValueChange}
                  style={{
                    minWidth: "200px",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    overflow: "auto",marginRight: '20px' 
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: 300 },
                    },
                  }}
        >
          {interfc?.map((user) => (
        <MenuItem value={user.int_index} key={user.int_index}>
          {user.type}
        </MenuItem>
      ))}
        </Select>
        </FormControl><br />
  
    <FormControl>
      <InputLabel>Internal IP Address</InputLabel>
      <Input
        name="internal_ip_address"
        value={IpAddress}
        onChange={event => {
          handleIpAddressChange(event);
          onValueChange(event); // Call the onValueChange function
        }}
        error={!isValidIpAddress}
      />
    </FormControl>
    </div>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
        <FormControl>
          <Button variant="contained" color="primary"   onClick={identity_mapping}  disabled={!isValidIpAddress}>
            Add DMZ
          </Button>
        </FormControl>
        
        </div>
        </Box>
            <div id="alertMessage" className="alert" hidden = "true"></div>
            <LinearProgress variant="determinate" value={progress} />
    </Paper>
      </Grid>
    </Grid>
  );
};

export default AddDMZ;
