import React, { useState, useEffect } from 'react';
import axios from 'axios';

import './LocalTime.css'; // You can create a CSS file for styling

const LocalTime = () => {
    const [response, setResponse] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL_node;
                const response = await axios.post(`${apiUrl}/getclock`);
                setResponse(response.data);
            } catch (error) {
                console.error('Error:', error.message);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            {response && response.body ? (
                <div className="table-container">
                    <div dangerouslySetInnerHTML={{ __html: response.body }}></div>
                    
                    {/* Display other clock data */}
                    <div className="clock-info">
                        <p>{response.clock_data.name}</p>
                        <p>{response.clock_data.date}</p>
                        <p>{response.clock_data.time_zone}</p>
                    </div>
                </div>
            ) : (
                <p>Error fetching data</p>
            )}
        </div>
    );
};

export default LocalTime;