import {React, useState} from 'react'
import {FormGroup, FormControl, Input, InputLabel,NativeSelect, Typography, styled, Button} from "@mui/material"
import { adduser } from '../../service/api'
import { useNavigate } from 'react-router-dom'


const Container = styled(FormGroup)`
    width: 10000vw%;
    margin: 5% auto 0 200%;
    & > div{
        margin-top: 20px;
    }


`

const defaultValues = {
    companyname: "",
    businessemail: "",
    firstname: "",
    lastname: "",
    jobtitle: "",
    phonenumber: "",
    password: "",
    permissionTo:"",
    entity:"",
    role:""

}

function AddUsers() {
    
const navigate = useNavigate()

    const [user, setUser] = useState(defaultValues);

    const onValueChange = (e) =>{
        setUser({...user, [e.target.name]: e.target.value})
    }

    const adduserdetails= async () =>{
       await adduser(user)
        navigate('/users')

    }

  return (
    <Container fullWidth>
        <Typography variant='h4'>Add User</Typography>
    <FormControl>
        <InputLabel>Company Name </InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="companyname"/>
    </FormControl>
        <FormControl>
            <InputLabel>Business Email</InputLabel>
        <Input onChange={(e) => onValueChange(e)} name="businessemail"/>
        </FormControl>
        <FormControl>
            <InputLabel>First Name</InputLabel>
        <Input onChange={(e) => onValueChange(e)} name="firstname" />
        </FormControl>
        <FormControl>
            <InputLabel>Last Name</InputLabel>
        <Input onChange={(e) => onValueChange(e)} name="lastname" />
        </FormControl>
        <FormControl>
            <InputLabel>Job Title</InputLabel>
        <Input onChange={(e) => onValueChange(e)} name="jobtitle" />
        </FormControl>
        <FormControl>
            <InputLabel>Phone</InputLabel>
        <Input type='number' onChange={(e) => onValueChange(e)} name="phonenumber" />
        </FormControl>
        <FormControl>
            <InputLabel>Password</InputLabel>
        <Input type='password' onChange={(e) => onValueChange(e)} name="password" />
        </FormControl>
        <FormControl fullWidth>
             <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Permission To
             </InputLabel>
             <NativeSelect
                  defaultValue={1}
                     inputProps={{
                      name: 'permissionTo',
                      id: 'uncontrolled-native',
                     }}
                     onChange={(e) => onValueChange(e)}  >
               <option value={1}>Organization</option>
               <option value={2}>Cloud Etel</option>
               <option value={3}>Organiztion 1</option>
               <option value={4}>Organiztion 2</option>
             </NativeSelect>
        </FormControl>
        <FormControl fullWidth>
             <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Entity
             </InputLabel>
             <NativeSelect
                  defaultValue={1}
                     inputProps={{
                      name: 'entity',
                      id: 'uncontrolled-native',
                     }}
                     onChange={(e) => onValueChange(e)}  >
               <option value={1}>Customer 1</option>
               <option value={2}>Customer 2</option>
               <option value={3}>Customer 3</option>
             </NativeSelect>
        </FormControl>
        <FormControl fullWidth>
             <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Role
             </InputLabel>
             <NativeSelect
                  defaultValue={1}
                     inputProps={{
                      name: 'role',
                      id: 'uncontrolled-native',
                     }}
                     onChange={(e) => onValueChange(e)}  >
               <option value={1}>Admin</option>
               <option value={2}>Manager</option>
               <option value={3}>viewer 1</option>
               <option value={4}>viewer 2</option>
             </NativeSelect>
        </FormControl>  
        <FormControl>
        <Button variant="contained" onClick={() => adduserdetails()}>Add User</Button>
        </FormControl>
    </Container>
  )
}

export default AddUsers