import React, { useState, useEffect } from "react";
import {
  Grid,
  Tabs,
  Tab,
  FormControl,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Input,
  LinearProgress,
  Box,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { useParams } from "react-router-dom";
import { dhcp_conf, getnetns_interface_details } from "../../service/api";
import { isInSubnet } from "is-in-subnet";
import Navbar from "../Home/Sidebar";
import Sidebar from "./Sidebar";
import { Paper } from "@material-ui/core";
import Nav from "../Home/Nav";

function Dhcp() {
  const { id } = useParams();

  const [testConn, settestConn] = useState({
    primary_dns: "8.8.8.8",
    secondary_dns: "8.8.4.4",
  });
  const [currentTab, setCurrentTab] = useState("connectivity_test");
  const [IpAddress, setInternalIpAddress] = useState("");
  const [primary_dns, setPrimaryDns] = useState("");
  const [secondary_dns, setSecondaryDns] = useState("");
  const [DhcpEndAddress, setDhcpEndAddress] = useState("");
  const [partd, setD] = useState("");
  const [isValidIpAddress, setIsValidIpAddress] = useState(true);
  const [progress, setProgress] = useState(0);
  const [selectedInterface, setSelectedInterface] = useState("");
  const [netnsInterfaceDetail, setNetnsInterfaceDetail] = useState([]);
  const [interfc, setInterfc] = useState([]);

  useEffect(() => {
    getnetns_interface_detail();
  }, []);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const handleIpAddressChange = (event) => {
    const ipAddress = event.target.value;
    setInternalIpAddress(ipAddress);
    setIsValidIpAddress(validateIp(ipAddress));
  };
  const handleDhcpEndAddressChange = (event) => {
    const ipAddress = event.target.value;
    setDhcpEndAddress(ipAddress);
    setIsValidIpAddress(validateIp1(ipAddress));
  };
  const handleprimary_dns = (event) => {
    const ipAddress = event.target.value;
    setPrimaryDns(ipAddress);
    setIsValidIpAddress(validateIp2(ipAddress));
  };
  const handlesecondary_dns = (event) => {
    const ipAddress = event.target.value;
    setSecondaryDns(ipAddress);
    setIsValidIpAddress(validateIp2(ipAddress));
  };
  const validateIp = (ipAddress) => {
    const parts = ipAddress.split(".");
    if (parts.length !== 4) {
      return false;
    }

    for (let i = 3; i < parts.length; i++) {
      const part = parseInt(parts[i]);
      if (isNaN(part) || part < 10 || part > 255) {
        return false;
      }
    }

    setD(parts);
    return true;
  };
  const validateIp1 = (ipAddress) => {
    const parts = ipAddress.split(".");
    if (parts.length !== 4) {
      return false;
    }
    if (parts[0] !== partd[0]) {
      return false;
    }
    if (parts[1] !== partd[1]) {
      return false;
    }
    if (parts[2] !== partd[2]) {
      return false;
    }

    for (let i = 3; i < parts.length; i++) {
      const part = parseInt(parts[i]);
      if (isNaN(part) || part <= partd[3] || part > 200) {
        return false;
      }
    }

    return true;
  };

  const validateIp2 = (ipAddress) => {
    const parts = ipAddress.split(".");
    if (parts.length !== 4) {
      return false;
    }

    for (let i = 0; i < parts.length; i++) {
      const part = parseInt(parts[i]);
      if (isNaN(part) || part < 0 || part > 255) {
        return false;
      }
    }
    return true;
  };

  const getnetns_interface_detail = async () => {
    try {
      const response = await getnetns_interface_details(id);
      console.log(
        "netnsInterfaceDetail",
        response.data.find((netInterface) => netInterface.type === "lan")
      );
      setNetnsInterfaceDetail(
        response.data.find((netInterface) => netInterface.type === "lan")
      );
    } catch (error) {
      console.log("Error while getting routing table data:", error);
    }
  };
  const onValueChange = (e) => {
    const { name, value } = e.target;
    if (name === "interface_name") {
      setSelectedInterface(value);
      const selectedUser = netnsInterfaceDetail.find(
        (user) => user.interface_name === value
      );
      if (selectedUser) {
        console.log(selectedUser.IPv4address);
        setInterfc(selectedUser.IPv4address);
      }
    }
    settestConn({ ...testConn, [name]: value });
  };
  const Connect_test = async () => {
    try {
      if (isInSubnet(IpAddress, interfc)) {
        setIsValidIpAddress(false);
        setProgress(0);
        const response = await dhcp_conf(testConn, id);

        const progressPercentage = response.data[0].progress;
        setProgress(progressPercentage);
        console.log(response.data[0].avg_rtt);
        if (response) {
          displayAlert(`DHCP Successfully Configured`, "alert-success");
        } else {
          displayAlert("Not Added", "alert-error");
        }
        setTimeout(() => {
          window.location.reload(); // Refresh the page after 4 seconds
        }, 4000);
        setIsValidIpAddress(true);
      } else {
        alert("Ip should be in lan subnet");
      }
    } catch (error) {
      displayAlert("Error posting data. Please try again.", "alert-error");
      setIsValidIpAddress(true);
    }
  };
  const displayAlert = (message, className, hidden = false) => {
    const alertElement = document.getElementById("alertMessage");
    alertElement.textContent = message;
    alertElement.classList.add(className);
    alertElement.style.display = hidden ? "none" : "block";
  };

  console.log("netnsInterfaceDetail", netnsInterfaceDetail);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Nav name="DHCP" />
      </Grid>

      <Grid item xs={12} md={2}>
        <Paper style={{ overflowY: "auto" }}>
          <Sidebar />
        </Paper>
      </Grid>

      <Grid item xs={12} md={8}>
        <Paper style={{ padding: "20px" }}>
          <Box>
            <Typography variant="h4" align="center" gutterBottom>
              DHCP
            </Typography>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
            ></Tabs>
            <Box
              hidden={currentTab !== "connectivity_test"}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <FormControl>
                <InputLabel>Interface</InputLabel>
                <Input
                  name="interface_name"
                  value={`${netnsInterfaceDetail.interface_name} (${netnsInterfaceDetail.IPv4address})`}
                  placeholder={netnsInterfaceDetail.interface_name}
                  id="interface_name"
                  onChange={(event) => {
                    handleIpAddressChange(event);
                    onValueChange(event); // Call the onValueChange function
                  }}
                  error={!isValidIpAddress}
                  disabled
                  fullWidth
                />
              </FormControl>
              {/* <FormControl  style={{ marginBottom: '10px' }}>
  <Select
  name="interface_name"
  labelId="interface-select-label"
  id="interface_name"
  value={selectedInterface}
  onChange={onValueChange}
  style={{
    minWidth: '200px',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    overflow: 'auto'
  }}
  MenuProps={{
    PaperProps: {
      style: { maxHeight: 300 }
    }
  }}
>
  {netnsInterfaceDetail?.map((user) => (
    <MenuItem
      value={user.interface_name}
      key={user.interface_name}
      style={{ borderRadius: '8px' }}
    >
      {`${user.interface_name} (${user.IPv4address})`}
    </MenuItem>
  ))}
</Select>
</FormControl> */}

              <div style={{ display: "flex", marginBottom: "10px" }}>
                <FormControl style={{ marginRight: "10px", flex: 1 }}>
                  <InputLabel>DHCP Start Address</InputLabel>
                  <Input
                    name="dhcp_startaddr"
                    value={IpAddress}
                    onChange={(event) => {
                      handleIpAddressChange(event);
                      onValueChange(event); // Call the onValueChange function
                    }}
                    error={!isValidIpAddress}
                  />
                </FormControl>
                <FormControl style={{ flex: 1 }}>
                  <InputLabel>DHCP End Address</InputLabel>
                  <Input
                    name="dhcp_endaddr"
                    value={DhcpEndAddress}
                    onChange={(event) => {
                      handleDhcpEndAddressChange(event);
                      onValueChange(event); // Call the onValueChange function
                    }}
                    error={!isValidIpAddress}
                  />
                </FormControl>
              </div>
              <div style={{ display: "flex", marginBottom: "10px" }}>
                <FormControl style={{ marginRight: "10px", flex: 1 }}>
                  <InputLabel>Primary DNS</InputLabel>
                  <Input
                    name="primary_dns"
                    value={primary_dns}
                    onChange={(event) => {
                      handleprimary_dns(event);
                      onValueChange(event); // Call the onValueChange function
                    }}
                    error={!isValidIpAddress}
                  />
                </FormControl>
                <FormControl style={{ flex: 1 }}>
                  <InputLabel>Secondary DNS</InputLabel>
                  <Input
                    name="secondary_dns"
                    value={secondary_dns}
                    onChange={(event) => {
                      handlesecondary_dns(event);
                      onValueChange(event); // Call the onValueChange function
                    }}
                    error={!isValidIpAddress}
                  />
                </FormControl>
              </div>
              <FormControl style={{ marginBottom: "10px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={Connect_test}
                  disabled={!isValidIpAddress}
                >
                  Configure DHCP
                </Button>
              </FormControl>
            </Box>
            <div id="alertMessage" className="alert" hidden="true"></div>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Dhcp;
