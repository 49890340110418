import React, { useEffect, useState } from "react";
import {  Button, Paper,Grid, Typography, FormControl, InputLabel, Select, MenuItem, Input, LinearProgress } from "@material-ui/core";

import { rate_limit, getinterface, reach_reset} from '../../service/api';
import {isInSubnet} from 'is-in-subnet'
import { useNavigate, useParams, Link } from 'react-router-dom';
import Sidebar from "./Sidebar";
import Nav from "../Home/Nav";
import { Box } from "@mui/material";

const Ratelimiting = () => {

       
  const { id } = useParams();
  const [testConn, settestConn] = useState({
    allowable_bw: 'none',
    lan_ip: 'none'
  });
  const [currentTab, setCurrentTab] = useState('connectivity_test');
  const [IpAddress, setInternalIpAddress] = useState('');
  const [isValidIpAddress, setIsValidIpAddress] = useState(true);
  const [progress, setProgress] = useState(0);
  const [interfc, setInterfc] = useState([]);


  useEffect(() => {

    getInterfc()
}, []);
  
  
  const getInterfc = async () => {
    try {
      const response = await getinterface(id);
      console.log(response.data.filter(item => item.type === "lan")[0].int_ipv4_address);
      setInterfc(response.data.filter(item => item.type === "lan")[0].int_ipv4_address);
      if (response.data) {
      }
    } catch (error) {
      console.log('Error while getting interface data:', error);
    }
  };
  

  const handleIpAddressChange = (event) => {
    const ipAddress = event.target.value;
    setInternalIpAddress(ipAddress);
    

  };
  

  const onValueChange = (e) => {
    
    const { name, value } = e.target;
    console.log(e.target);
  
    settestConn({ ...testConn, [name]: value });

  };
  const Connect_test = async () => {
    try {
        if(isInSubnet(IpAddress, interfc)){
            console.log(isInSubnet(IpAddress, interfc)); 
            setIsValidIpAddress(false);
      
            setProgress(0);
              const response = await rate_limit(testConn, id);
              
            
              if (response.status == 200) {
                
                displayAlert(`Configuration applied`, 'alert-success');
              }
              setIsValidIpAddress(true);
        }else{
            alert('Ip  should be in lan subnet')
        }
console.log(isInSubnet(IpAddress, interfc)); 
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
      setIsValidIpAddress(true);
    } 
  };
  

  


const displayAlert = (message, className, hidden = false) => {
  const alertElement = document.getElementById('alertMessage');
  alertElement.textContent = message;
  alertElement.classList.add(className);
  alertElement.style.display = hidden ? 'none' : 'block';
};

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Nav name="Rate Limiting" />
      </Grid>

      <Grid item xs={12} md={2}>
        <Paper style={{ overflowY: 'auto' }}>
          <Sidebar />
        </Paper>
      </Grid>

      <Grid item xs={12} md={8}>
        <Paper style={{ padding: "8%" }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <div style={{ display: "flex", marginBottom: "10px" }}>
            
          
        
  <FormControl style={{ marginRight: '10px', flex: 1 }}>
  <InputLabel>Allowable_bw</InputLabel>
  <Input
    name="allowable_bw"
    onChange={event => {
      onValueChange(event); // Call the onValueChange function
    }}
  />
</FormControl>
  <FormControl style={{ marginRight: '10px', flex: 1 }}>
  <InputLabel>IP</InputLabel>
  <Input
    name="lan_ip"
    value={IpAddress}
    onChange={event => {
      handleIpAddressChange(event);
      onValueChange(event); // Call the onValueChange function
    }}
    error={!isValidIpAddress}
  />
</FormControl>
</div>
            <div style={{ display: "flex", marginBottom: "10px" }}>
    <FormControl >
      <Button variant="contained"  color="primary"  onClick={Connect_test}  disabled={!isValidIpAddress}>
        Rate Limit
      </Button>
    </FormControl>
            </div>
          </Box>
            <div id="alertMessage" className="alert" hidden = "true"></div>
            <LinearProgress variant="determinate" value={progress} />
    </Paper>
      </Grid>
    </Grid>
  );
};

export default Ratelimiting;
