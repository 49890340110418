import {React, useEffect, useState} from 'react';
import "./peers.css"
import {getallPeers} from '../../service/api'
import { useNavigate,  Link, Router  } from "react-router-dom"
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material'
import axios from 'axios'

function Peers(){
    
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getallPeers();
  },[]);
  
  const getallPeers = async () =>{
    let response = await getallPeers();
    setUsers(response.data);
  }
  
  const navigate = useNavigate();
  const addPeers = () => {
    navigate('/addPeers')
  }
  
  return (
    <div className="peers">
        <div className="button" onClick={addPeers}>Add Peers</div>
        <h1>Peers</h1>
        <Table>
            <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Local ID</TableCell>
              <TableCell>Remote ID</TableCell>
              <TableCell>Remote IP</TableCell>
              <TableCell></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
              {
                users.map(user =>(
                  <TableRow>
                  <TableCell>{user.organizationName}</TableCell>
                    <TableCell>{user.organizationGroup}</TableCell>
                    <TableCell>{user.tunnelKeyExchangeMethod}</TableCell>
                    <TableCell>{user.organizationGroup}</TableCell>
                    <TableCell>
                      <Button variant='contained' style={{marginRight: 10}} component={Link} to={`/edit/${user._id}`}>Edit</Button>
                      <Button variant='contained' color="error">Delete</Button>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
        </Table>
    </div>

  )
}

export default Peers