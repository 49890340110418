import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Grid,
  CircularProgress,
  makeStyles,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import Navbar from "../Home/Sidebar";
import { Button, InputLabel } from "@mui/material";
import Nav from "../Home/Nav";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  image: {
    width: "100%",
    height: "auto",
    marginBottom: theme.spacing(2),
    cursor: "pointer",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "300px", // Set a fixed height to center CircularProgress
  },
}));

const Graphs = ({ action, data, additionalHeaders }) => {
  const classes = useStyles();
  const [systemImages, setSystemImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [zabbixGraphs, setZabbixGraphs] = useState([]);
  const [selectedGraphIndex, setSelectedGraphIndex] = useState(null);
  const [timeRange, setTimeRange] = useState("5m"); // Default time range

  const { id } = useParams();
  const defaultId = id || "10084";

  useEffect(() => {
    const fetchSystemImages = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL_node;
        const response = await axios.post(`${apiUrl}/GraphGet/${defaultId}`);

        // Filter out graphs not related to Zabbix
        const filteredGraphs = response.data.result.filter(
          (graph) => !graph.name.toLowerCase().includes("zabbix")
        );
        setZabbixGraphs(filteredGraphs);

        const baseUrl = "http://172.104.146.239/zabbix/chart2.php";
        const urls = filteredGraphs.map(
          (graph) =>
            `${baseUrl}?graphid=${
              graph.graphid
            }&from=now-${timeRange}&to=now&height=201&width=1280&profileIdx=web.charts.filter&_=${Math.random()}`
        );

        const imagePromises = urls.map(async (url) => {
          try {
            const response = await fetch(
              `${apiUrl}/getsystemlimg?imageUrl=${url}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "image/png",
                },
              }
            );

            if (!response.ok) {
              throw new Error(
                `Failed to fetch image: ${response.status} ${response.statusText}`
              );
            }

            const blob = await response.blob();
            const imageUrlObject = URL.createObjectURL(blob);
            return imageUrlObject;
          } catch (error) {
            console.error("Error fetching system image for URL:", url, error);
            return null;
          }
        });

        const systemImagesData = await Promise.all(imagePromises);
        setSystemImages(systemImagesData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching system images:", error);
        setLoading(false);
      }
    };

    fetchSystemImages();
  }, [action, data, additionalHeaders, defaultId, timeRange]);

  const handleImageClick = () => {
    if (selectedGraphIndex !== null) {
      setSelectedImage(systemImages[selectedGraphIndex]);
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedImage(null);
  };

  const handleDropdownChange = (event) => {
    setSelectedGraphIndex(event.target.value);
  };

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Nav name="Graphs" />
      </Grid>

      <Grid item xs={12} md={2}>
        <Paper style={{ overflowY: "auto" }}>
          <Navbar />
        </Paper>
      </Grid>

      <Grid item xs={12} md={8}>
        <Paper style={{ padding: "8%" }}>
          {loading ? (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          ) : (
            <Grid container spacing={2} align="center">
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl style={{ minWidth: 200 }} fullWidth>
                  <InputLabel style={{ fontSize: 16 }}>
                    Select a Graph
                  </InputLabel>
                  <Select
                    value={selectedGraphIndex}
                    onChange={handleDropdownChange}
                    style={{ marginTop: 8 }}
                  >
                    <MenuItem value="" disabled>
                      Select a Graph
                    </MenuItem>
                    {zabbixGraphs.map((graph, index) => (
                      <MenuItem key={index} value={index}>
                        {graph.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl style={{ minWidth: 200 }} fullWidth>
                  <InputLabel style={{ fontSize: 16 }}>
                    Select Time Range
                  </InputLabel>
                  <Select
                    value={timeRange}
                    onChange={handleTimeRangeChange}
                    style={{ marginTop: 8 }}
                  >
                    <MenuItem value="5m">5 minutes</MenuItem>
                    <MenuItem value="15m">15 minutes</MenuItem>
                    <MenuItem value="30m">30 minutes</MenuItem>
                    <MenuItem value="1h">1 hour</MenuItem>
                    <MenuItem value="12h">12 hours</MenuItem>
                    <MenuItem value="24h">24 hours</MenuItem>
                    <MenuItem value="30d">30 days</MenuItem>
                    <MenuItem value="6M">6 months</MenuItem>
                    <MenuItem value="1y">1 year</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleImageClick}
                >
                  Show Graph
                </Button>
              </Grid>
            </Grid>
          )}

          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            maxWidth="lg"
            fullWidth
          >
            <DialogContent>
              <img
                src={selectedImage}
                alt="Selected System Image"
                style={{ width: "100%", height: "auto" }}
              />
            </DialogContent>
          </Dialog>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Graphs;
