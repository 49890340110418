import { useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCogs,
  faPlus,
  faLock,
  faHome,
  faArrowsSpin,
  faPowerOff,
  faGrip,
  faPeopleArrows,
} from "@fortawesome/free-solid-svg-icons";
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";
import logo from "../logo.png"; // Import your logo image

const Sidebar = ({ clickedIndex }) => {
  const location = useLocation();
  const { id } = useParams();


  const deleteCookie = (cookieName) => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };
  const handleLogout = () => {
    // Call your deleteCookie function here to delete the user authentication cookie
    deleteCookie("isvalid");
    deleteCookie("user");
    deleteCookie("refresh_token");
    deleteCookie("access_token_expiry");
    deleteCookie("access_token");
    alert("Logged Out Successfully");
    setTimeout(() => {
      window.location.reload(); // Refresh the page after 1 second
    }, 1000);
    // Replace "your_cookie_name" with the actual name of your authentication cookie
    // You may also want to perform other logout-related tasks here (e.g., redirecting to the login page)
  };


  const drawerItems = [
    { text: "Home", icon: faHome, path: "/" },
    { text: "Device Manager", icon: faCogs, path: "/devices" },
    {
      text: "Diagnostics",
      icon: faArrowsSpin,
      path: `/interfaces/${id}`,
      items: [
        { text: "Ping Host", icon: faPeopleArrows, path: `/pinghost/${id}` },
        { text: "Restart", icon: faPowerOff, path: `/restart/${id}` },
        { text: "Reset", icon: faArrowsSpin, path: `/reset/${id}` },
        { text: "DNS Configuration", icon: faGrip, path: `/dnsconf/${id}` },
      ],
    },
    { text: "Add Device", icon: faPlus, path: "/addNewDevice" },
  ];

  const renderMenuItem = (item, index) => {
    const isActive = location.pathname === item.path;

    if (item.items) {
      return (
        <div key={index}>
          <ListItem
            button
            onClick={() =>
              setCollapseIndex((collapseIndex) =>
                collapseIndex === index ? null : index
              )
            }
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={item.icon} />
            </ListItemIcon>
            <ListItemText primary={item.text} />
            {collapseIndex === index ? (
              <KeyboardArrowUp />
            ) : (
              <KeyboardArrowDown />
            )}
          </ListItem>
          <Collapse in={collapseIndex === index} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.items.map((subItem, subIndex) => (
                <ListItem
                  button
                  key={subIndex}
                  component={Link}
                  to={subItem.path}
                  className={
                    location.pathname === subItem.path ? "active" : ""
                  }
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={subItem.icon} />
                  </ListItemIcon>
                  <ListItemText primary={subItem.text} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </div>
      );
    } else {
      return (
        <ListItem
          button
          key={index}
          component={Link}
          to={item.path}
          className={isActive ? "active" : ""}
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={item.icon} />
          </ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItem>
      );
    }
  };

  const [collapseIndex, setCollapseIndex] = useState(null);

  return (
    <div
      style={{
        position: "fixed",
            marginTop: '5%',
        left: 0,
        top: 0,
        width: "250px",
        height: "100%",
        background: "#fff",
      }}
    >
      <List>
        {drawerItems.map((item, index) => renderMenuItem(item, index))}
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faLock} />
          </ListItemIcon>
          <ListItemText primary="Log out" />
        </ListItem>
      </List>
    </div>
  );
};

export default Sidebar;
