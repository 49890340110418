import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  LinearProgress,
} from "@material-ui/core";
import {
  getroutingtable,
  getnetns_interface_details,
  poststatic_route_add,
} from "../../service/api";
import { useNavigate, useParams, Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Nav from "../Home/Nav"; // Import Nav component for the header
import { Box } from "@mui/material";

const StaticRouting = () => {
  const [static_route, setstatic_route] = useState({
    destination: "",
    gateway_ip: "",
    interface: "",
    metric: "",
  });

  useEffect(() => {
    getRoutingTable();
    getnetns_interface_detail();
  }, []);

  const { id } = useParams();
  const navigate = useNavigate();

  const [routingtable, setRoutingTable] = useState([]);
  const [progress, setProgress] = useState(0);
  const [selectedInterface, setSelectedInterface] = useState("");
  const [netns_interface_detail, setNetnsInterfaceDetail] = useState([]);
  const [destAddress, setInternaldestAddress] = useState("");
  const [isValiddestAddress, setIsValiddestAddress] = useState(true);

  const onValueChange = (e) => {
    const { name, value } = e.target;
    setstatic_route({ ...static_route, [name]: value });
  };

  const getRoutingTable = async () => {
    try {
      const response = await getroutingtable(id);
      setRoutingTable(response.data);
    } catch (error) {
      console.log("Error while getting routing table data:", error);
    }
  };

  const getnetns_interface_detail = async () => {
    try {
      const response = await getnetns_interface_details(id);
      setNetnsInterfaceDetail(response.data);
    } catch (error) {
      console.log("Error while getting routing table data:", error);
    }
  };

  const handledestinationChange = (event) => {
    const ipAddress = event.target.value;
    setInternaldestAddress(ipAddress);
    setIsValiddestAddress(validateIp(ipAddress));
  };

  const validateIp = (ipAddress) => {
    const parts = ipAddress.split(".");
    if (parts.length !== 4) {
      return false;
    }
    const a = parseInt(parts[0]);
    const b = parseInt(parts[1]);
    const c = parseInt(parts[2]);
    const d = parseInt(parts[3]);

    if (a === 10) {
      if (!(0 <= b && b <= 255 && 0 <= c && c <= 255 && 0 <= d && d <= 255)) {
        return false;
      }
    } else if (a === 172) {
      if (!(16 <= b && b <= 31 && 0 <= c && c <= 255 && 0 <= d && d <= 255)) {
        return false;
      }
    } else if (a === 192) {
      if (!(b === 168 && 0 <= c && c <= 255 && 0 <= d && d <= 255)) {
        return false;
      }
    } else {
      return false;
    }

    return true;
  };

  const poststatic_route = async () => {
    try {
      setProgress(0);
      const response = await poststatic_route_add(static_route, id);
      const progressPercentage = response.data.progress;
      setProgress(progressPercentage);
      displayAlert("Data posted successfully!", "alert-success");
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert("Error posting data. Please try again.", "alert-error");
    }
  };

  const isstaticFormValid = isValiddestAddress;

  const displayAlert = (message, className, hidden = false) => {
    const alertElement = document.getElementById("alertMessage");
    alertElement.textContent = message;
    alertElement.classList.add(className);
    alertElement.style.display = hidden ? "none" : "block";
  };
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Nav name="Add Static Routing" />
      </Grid>

      <Grid item xs={12} md={2}>
        <Paper style={{ overflowY: "auto" }}>
          <Sidebar />
        </Paper>
      </Grid>

      <Grid item xs={12} md={8}>
        <Paper style={{ padding: "8%" }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl style={{ marginRight: "10px", flex: 1 }}>
                <InputLabel>Destination</InputLabel>
                <Input
                  name="destination"
                  value={destAddress}
                  onChange={(event) => {
                    handledestinationChange(event);
                    onValueChange(event);
                  }}
                  error={!isValiddestAddress}
                />
              </FormControl>
              <FormControl style={{ marginRight: "10px" }}>
                <InputLabel>Gateway IP</InputLabel>
                <Select
                  name="gateway_ip"
                  value={static_route.gateway_ip}
                  onChange={onValueChange}
                  style={{
                    minWidth: "200px",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    overflow: "auto",
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: 300 },
                    },
                  }}
                >
                  {routingtable.map((user) => (
                    <MenuItem value={user.gateway} key={user.gateway}>
                      {user.gateway}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <FormControl>
                <InputLabel>Interface</InputLabel>
                <Select
                  name="interface"
                  value={static_route.interface}
                  onChange={onValueChange}
                  style={{
                    minWidth: "200px",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    overflow: "auto",
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: 300 },
                    },
                  }}
                >
                  {netns_interface_detail.map((user) => (
                    <MenuItem
                      value={user.interface_name}
                      key={user.interface_name}
                    >
                      {user.interface_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={poststatic_route}
              // disabled={!isstaticFormValid}
            >
              Add Static Route
            </Button>
          </Box>
          <div id="alertMessage" className="alert" hidden="true"></div>
          <LinearProgress variant="determinate" value={progress} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default StaticRouting;
