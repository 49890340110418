import {React, useState, ChangeEvent} from 'react'
import {FormGroup, FormControl, Input, InputLabel,NativeSelect, Typography, styled, Button,FormControlLabel, Switch, FormLabel, FormHelperText} from "@mui/material"
import { addPeers } from '../../service/api'
import { useNavigate } from 'react-router-dom'


const Container = styled(FormGroup)`
    width: 500%;
    margin: 5% auto 0 100%;
    & > div{
        margin-top: 20px;
    }


`

const defaultValues = {
    version: "",
    components: "",
    installWith: "",
    firewallRules: ""
}

function AddPeers(){
    
    const navigate = useNavigate()
    
    const [user, setUser] = useState(defaultValues);

    const onValueChange = (e) =>{
        setUser({...user, [e.target.name]: e.target.value})
    }

    
    const addPeersDetails= async () =>{
        await addPeers(user)
         navigate('/peers')
 
     }

     
  return (
    <Container fullWidth>
        <Typography variant='h4'>Add Peers</Typography>
    <FormControl>
        <InputLabel>Peers Name </InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="name"/>
    </FormControl>
        <FormControl fullWidth>
             <InputLabel variant="standard" htmlFor="uncontrolled-native">
             Organization 
             </InputLabel>
             <NativeSelect
                  defaultValue={1}
                     inputProps={{
                      name: 'organizationGroup',
                      id: 'uncontrolled-native',
                     }}
                     onChange={(e) => onValueChange(e)}  >
               <option value={1}>Organization 1</option>
               <option value={2}>Organization 2</option>
             </NativeSelect>
        </FormControl>
        
    <FormControl>
        <InputLabel>ID Type</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="idType"/>
    </FormControl>
    <FormControl>
        <InputLabel>localId</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="localId"/>
    </FormControl>

    <FormControl>
        <InputLabel>Remote ID</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="remoteId"/>
    </FormControl>

    <FormControl>
        <InputLabel>PSK</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="psk"/>
    </FormControl>

    <FormControl>
        <InputLabel>Remote Ip</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="remoteIP"/>
    </FormControl>

    <FormControl>
        <InputLabel>Urls</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="urls"/>
    </FormControl>

    <FormControl>
        <InputLabel>IPs</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="ips"/>
    </FormControl>

    <FormControl>
        <InputLabel>IKE DH Group</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="ikeDhGroup"/>
    </FormControl>

    <FormControl>
        <InputLabel>IKE Crypto Algorithm</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="ikeCryptoAlg"/>
    </FormControl>

    <FormControl>
        <InputLabel>IKE Key Size</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="ikeKeySize"/>
    </FormControl>

    <FormControl>
        <InputLabel>IKE Integ Algorithm</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="ikeIntegAlg"/>
    </FormControl>

    <FormControl>
        <InputLabel>ESP DH Group</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="espDhGroup"/>
    </FormControl>

    <FormControl>
        <InputLabel>ESP Crypto Algorithm</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="espCryptoAlg"/>
    </FormControl>

    <FormControl>
        <InputLabel>ESP Key Size</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="espKeySize"/>
    </FormControl>

    <FormControl>
        <InputLabel>ESP Integ Algorithm</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="espIntegAlg"/>
    </FormControl>

    <FormControl>
        <InputLabel>Session Life Time</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="sessionLifeTime"/>
    </FormControl>

    <FormControl>
        <InputLabel>Local Ip Range Start</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="localIpRangeStart"/>
    </FormControl>

    <FormControl>
        <InputLabel>Local Ip Range End</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="localIpRangeEnd"/>
    </FormControl>

    <FormControl>
        <InputLabel>Local Port Range Start</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="localPortRangeStart"/>
    </FormControl>

    <FormControl>
        <InputLabel>Local Port Range End</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="localPortRangeEnd"/>
    </FormControl>

    <FormControl>
        <InputLabel>Local Protocol</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="localProtocol"/>
    </FormControl>

    <FormControl>
        <InputLabel>Remote Ip Range Start</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="remoteIpRangeStart"/>
    </FormControl>

    <FormControl>
        <InputLabel>Remote Ip Range End</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="remoteIpRangeEnd"/>
    </FormControl>

    <FormControl>
        <InputLabel>Remote Protocol Range Start</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="remotePortRangeStart"/>
    </FormControl>

    <FormControl>
        <InputLabel>Remote Protocol Range End</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="remotePortRangeEnd"/>
    </FormControl>

    <FormControl>
        <InputLabel>Remote Protocol</InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="remoteProtocol"/>
    </FormControl>


        <FormControl>
        <Button variant="contained" onClick={() => addPeersDetails()}>Add Peers</Button>
        </FormControl>
    </Container>
  )
}

export default AddPeers