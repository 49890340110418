import React, { useState, useEffect } from 'react';
import axios from 'axios';
import cheerio from 'cheerio'; // Import cheerio for HTML parsing
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'; // Import Material UI components
import { makeStyles } from '@material-ui/core/styles';
import './ProblemsBySeverityInfo.css'; // Import the corresponding CSS file
import { Paper, TableContainer, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px', // Adjust the height based on your design
  },
}));

const ProblemsBySeverityInfo = () => {
  const classes = useStyles();
  const [problemsData, setProblemsData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL_node;
        const response = await axios.post(`${apiUrl}/getproblemsbysv`);

        // Parse the HTML content using cheerio
        const $ = cheerio.load(response.data.body);

        // Extract information from the parsed HTML
        const problemCounts = {};
        $('.by-severity-widget').children().each((index, element) => {
          const severity = $(element).attr('class').split('-')[0];
          const count = parseInt($(element).find('.count').text());
          problemCounts[severity] = count;
        });

        // Set the extracted information to state
        setProblemsData(problemCounts);
      } catch (error) {
        console.error('Error:', error.message);
      }
    };

    fetchData();
  }, []);

  // Function to capitalize the first letter of a string
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <Paper className={classes.container} elevation={3}>
      <Typography variant="h5" gutterBottom align='center'>
        Problems By Sevirity Information
      </Typography>
      {problemsData ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Severity</TableCell>
                <TableCell>Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(problemsData).map(([severity, count]) => (
                <TableRow key={severity}>
                   <TableCell>{capitalizeFirstLetter(severity)}</TableCell>
                  <TableCell>{count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
    </Paper>
  );
};

export default ProblemsBySeverityInfo;
