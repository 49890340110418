import {React, useState, ChangeEvent} from 'react'
import {FormGroup, FormControl, Input, InputLabel,NativeSelect, Typography, styled, Button,FormControlLabel, Switch, FormLabel, FormHelperText} from "@mui/material"
import { addResources } from '../../service/api'
import { useNavigate } from 'react-router-dom'


const Container = styled(FormGroup)`
    width: 500%;
    margin: 5% auto 0 100%;
    & > div{
        margin-top: 20px;
    }


`

const defaultValues = {
    fieldName: "",
    fileName: "",
    type: "",
    downloadObject: "",
    link: "",
    key: "",
    username: "",
    timestamps: ""
}

function AddResources(){
    
    const navigate = useNavigate()
    
    const [user, setUser] = useState(defaultValues);

    const onValueChange = (e) =>{
        setUser({...user, [e.target.name]: e.target.value})
    }

    
    const addResourcesDetails= async () =>{
        await addResources(user)
         navigate('/organization')
 
     }

     
  return (
    <Container fullWidth>
        <Typography variant='h4'>Add Resources</Typography>
    <FormControl>
        <InputLabel>Field Name </InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="fieldName"/>
    </FormControl>
        <FormControl fullWidth>
             <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Organization Group
             </InputLabel>
             <NativeSelect
                  defaultValue={1}
                     inputProps={{
                      name: 'organizationGroup',
                      id: 'uncontrolled-native',
                     }}
                     onChange={(e) => onValueChange(e)}  >
               <option value={1}>Default</option>
               <option value={2}>ReachManage</option>
             </NativeSelect>
        </FormControl>
        
    <FormControl>
        <InputLabel>Tunnel Key Exchange Method </InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="tunnelKeyExchangeMethod"/>
    </FormControl>


        <FormControl>
        <Button variant="contained" onClick={() => addResourcesDetails()}>Add Organization</Button>
        </FormControl>
    </Container>
  )
}

export default AddResources