import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Main } from './components';
import backgroundVideo from './assets/bgvideo/bg.mp4'


import Login from "./components/login/login"
import Register from "./components/register/register"
import AddUsers from "./components/users/AddUsers";
import Profile from "./components/profile/profile";
import Users from "./components/users/Users";
import Token from "./components/Tokens/token";
import NewToken from "./components/Tokens/newToken";
import PathLabel from "./components/pathLabels/pathLabel";
import AddPathLabel from "./components/pathLabels/addPathLabel";
import Organization from "./components/Organization/organization";
import AddOrganization from "./components/Organization/addOrganization";
import Resources from "./components/resources/resources";
import AddResources from "./components/resources/addresources";
import Peers from "./components/peers/peers";
import AddPeers from "./components/peers/addpeers";
import AddDevice from "./components/devicesE/device";
import Tunnel from "./components/tunnel/tunnel";
import AddNewDevice from "./components/addDevice/addDevice";

import EditUser from "./components/users/EditUser";
import Generalinfo from "./components/devicesE/general_info";
import Diagnostics from "./components/devicesE/diagnostics";

import Ratelimiting from "./components/devices/ratelimiting";
import Dhcp from "./components/devices/dhcp";
import Home from "./components/Home/Home";
import NotFound from "./components/Home/NotFound";


import Dashboard from "./pages/Dashboard";
import Messages from "./pages/Messages";
import FileManager from "./pages/FileManager";
import Analytics from "./pages/Analytics";
import Order from "./pages/Order";
import Saved from "./pages/Saved";
import Setting from "./pages/Setting";
import GraphComponent from "./components/zabbix/GraphComponent";
import DeviceDetails from "./components/devices/DeviceDetails";
import SystemChecker from "./components/devices/SystemChecker";
import Interfaces from "./components/devices/Interfaces";
import DownloadPage from "./components/DownloadPage";
import RoutingInfo from "./components/devices/Routing_Info";
import StaticRouting from "./components/devices/StaticRouting";
import FirewallStatus from "./components/devices/FirewallStatus";
import InboundRule from "./components/devices/InboundRule";
import OutBoundRule from "./components/devices/OutboundRule";
import AddQosPolicy from "./components/devices/AddQosPolicy";
import QosPolicy from "./components/devices/QosPolicy";
import AddDMZ from "./components/devices/AddDMZ";
import DMZ from "./components/devices/DMZ";
import PingHost from "./components/devices/PingHost";
import Restart from "./components/devices/Diagnostics/Restart";
import Reset from "./components/devices/Diagnostics/Reset";
import Graphs from "./components/zabbix/Graphs";
import DNSConfiguration from "./components/devices/Diagnostics/DNSConfiguration";
import PortForwarding from "./components/devices/PortForwarding";
import AddPF from "./components/devices/AddPF";
import TunnelStatus from "./components/devices/TunnelStatus";
function App() {
  
  const [ user, setLoginUser] = useState({});

  useEffect(() => {
    const userId = Cookies.get('access_token');
    if (userId) {
      setLoginUser({_id: userId});
    }
  }, []);


  
  return (
      
    <Router>
      {/* Conditionally render the video based on the presence of the access token */}
      {!user._id && (
        <video autoPlay muted loop id="background-video">
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}

      <div className="main-container">
        
        <Routes>
          {/* Render Home component or Login based on user authentication */}
          <Route exact path="/" element={ user && user._id ? ( <Home setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/devices" element={ user && user._id ? ( <AddDevice setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/tunnel" element={ user && user._id ? ( <Tunnel setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/addNewDevice" element={ user && user._id ? ( <DownloadPage setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/graphs/:id" element={ user && user._id ? ( <GraphComponent setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/graph/:id" element={ user && user._id ? ( <Graphs setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/d_details/:id" element={ user && user._id ? ( <DeviceDetails setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/system_checker/:id" element={ user && user._id ? ( <SystemChecker setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/interfaces/:id" element={ user && user._id ? ( <Interfaces setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/dhcp/:id" element={ user && user._id ? ( <Dhcp setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/routing_info/:id" element={ user && user._id ? ( <RoutingInfo setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/static_routing/:id" element={ user && user._id ? ( <StaticRouting setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/firewall_status/:id" element={ user && user._id ? ( <FirewallStatus setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/Inbound_rule/:id" element={ user && user._id ? ( <InboundRule setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/Outbound_rule/:id" element={ user && user._id ? ( <OutBoundRule setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/Qos_Policy/:id" element={ user && user._id ? ( <AddQosPolicy setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/ratelimiting/:id" element={ user && user._id ? ( <Ratelimiting setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/qos_policy_status/:id" element={ user && user._id ? ( <QosPolicy setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/tunnel_status/:id" element={ user && user._id ? ( <TunnelStatus setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/port_forwarding_status/:id" element={ user && user._id ? ( <PortForwarding setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/add_dmz/:id" element={ user && user._id ? ( <AddDMZ setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/add_PF/:id" element={ user && user._id ? ( <AddPF setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/dmz/:id" element={ user && user._id ? ( <DMZ setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/pinghost/:id" element={ user && user._id ? ( <PingHost setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/restart/:id" element={ user && user._id ? ( <Restart setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/reset/:id" element={ user && user._id ? ( <Reset setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route exact path="/dnsconf/:id" element={ user && user._id ? ( <DNSConfiguration setLoginUser={setLoginUser} />
              ) : ( <Login setLoginUser={setLoginUser} /> )}
          />
          <Route path="/diagnostics/:id" element={<Diagnostics />} />

          <Route path="/general_info/:id" element={<Generalinfo />} />
          
          <Route path="/login" element={<Login setLoginUser={setLoginUser}/>}/>
          <Route path="/addUser" element={<AddUsers />} />
          <Route path="/register" element={<Register />} />

        <Route path="*" element={<NotFound />} /> {/* Catch-all route for 404 errors */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
