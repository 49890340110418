import {React, useEffect, useState} from 'react';
import "./resources.css"
import {getallresources} from '../../service/api'
import { useNavigate,  Link, Router  } from "react-router-dom"
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material'
import axios from 'axios'

function Resources(){
    
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getAllResources();
  },[]);
  
  const getAllResources = async () =>{
    let response = await getallresources();
    setUsers(response.data);
  }
  
  const navigate = useNavigate();
  const addResources = () => {
    navigate('/addResources')
  }
  
  return (
    <div className="resources">
        <div className="button" onClick={addResources}>Add Resources</div>
        <h1>Resources</h1>
        <Table>
            <TableHead>
            <TableRow>
              <TableCell>fieldName</TableCell>
              <TableCell>fileName</TableCell>
              <TableCell>type</TableCell>
              <TableCell>key</TableCell>
              <TableCell>username</TableCell>
              <TableCell></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
              {
                users.map(user =>(
                  <TableRow>
                  <TableCell>{user.organizationName}</TableCell>
                    <TableCell>{user.organizationGroup}</TableCell>
                    <TableCell>{user.tunnelKeyExchangeMethod}</TableCell>
                    <TableCell>{user.organizationGroup}</TableCell>
                    <TableCell>{user.tunnelKeyExchangeMethod}</TableCell>
                    <TableCell>
                      <Button variant='contained' style={{marginRight: 10}} component={Link} to={`/edit/${user._id}`}>Edit</Button>
                      <Button variant='contained' color="error">Delete</Button>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
        </Table>
    </div>

  )
}

export default Resources