import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCogs,
  faPlus,
  faLock,
  faHome,
  faLaptopFile,
  faPeopleArrows
} from "@fortawesome/free-solid-svg-icons";

import logo from "./logo.png"; // Import your logo image

const Navbar = ({ clickedIndex }) => {
  const { id } = useParams();

  const deleteCookie = (cookieName) => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };
  const handleLogout = () => {
    // Call your deleteCookie function here to delete the user authentication cookie
    deleteCookie("isvalid");
    deleteCookie("user");
    deleteCookie("refresh_token");
    deleteCookie("access_token_expiry");
    deleteCookie("access_token");
    alert("Logged Out Successfully");
    setTimeout(() => {
      window.location.reload(); // Refresh the page after 1 second
    }, 1000);
    // Replace "your_cookie_name" with the actual name of your authentication cookie
    // You may also want to perform other logout-related tasks here (e.g., redirecting to the login page)
  };

  const drawerItems = [
    { text: 'Home', icon: faHome, path: '/' },
    { text: 'Device Manager', icon: faCogs, path: '/devices' },
    { text: 'Add Device', icon: faPlus, path: '/addNewDevice' },
  ];

  return (
    <div
      style={{
        position: "fixed",
            marginTop: '5%',
        left: 0,
        top: 0,
        width: "250px",
        height: "100%",
        background: "#fff",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Add this line for the shadow
      }}
    >
      {/* <img src={logo} alt="Logo" style={{ margin: '10px auto', display: 'block' }} /> */}
      <List>
        {drawerItems.map((item, index) => (
          <ListItem
            button
            key={index}
            component={Link}
            to={item.path}
            className={clickedIndex === index + 1 ? "active" : ""}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={item.icon} />
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faLock} />
          </ListItemIcon>
          <ListItemText primary="Log out" />
        </ListItem>
      </List>
    </div>
  );
};

export default Navbar;
