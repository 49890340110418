import React from 'react';
import './download.css';
import Navbar from '../Home/Sidebar';

const DownloadPage = () => {
  function copyCommand(id) {
    navigator.clipboard.writeText(document.getElementById(id).innerText);
  }

  return (
    <div className="container">
            <Navbar />
      <h1>Download Instructions</h1>
      <h2>Reach Wan</h2>
      <ul>
        <li>
          Establish a high secure communication between branches via encrypted 
as well as encapsulated tunnel over the Internet. 
        </li>
        <li>Secure centralized management system.</li>
        <li>Assurance for zero packet drops.</li>
        <li>Processing multiple packets at a time, with low latency.</li>
        <li>Fast, scalable and Deterministic packet processing. </li>
        <li>CPU as well as Resource utilization is less..</li>
        <li>On top of star topology, can get mesh functionality. </li>
        <li>Very attractive & Informative Dashboard.  </li>
        <li>Customize edge devices from dashboard itself.</li>
      </ul>
      <h3>Dashboard Features:</h3>
      <ul>
        <li>Monitor the health of Edge device as well as network.</li>
        <li>Network traffic graph.</li>
        <li>Live Tunnel connectivity status.</li>
        <li>Customizable QOS & Firewall.</li>
        <li>Provides detailed information about connected devices like Routing table,
Interfaces, DHCP, Firewall status & configured policies. </li>
        <li>Supports Diagnostics.</li>
      </ul>
      <h2>System Requirements:</h2>
      <ul>
        <li>OS: Ubuntu 20.04</li>
        <li>RAM: 4GB</li>
        <li>Hard-Disk: 100GB</li>
        <li>NIC: 2</li>
      </ul>
      <p>During Installation, it will ask for your Sign-In Info:</p>
      <ol start="1">
        <li>Enter your Registered mail ID with CloudEtel.</li>
        <li>
          Enter the password. Provide the valid Registered Mail Id & Password;
          otherwise, it won't proceed. If valid, you will get a message
          confirming successful registration.
        </li>
        <li>Enter your branch location  </li>
      </ol>
      <h2 style={{ marginBottom: 0 }}>Download Software:</h2>
      <br /> <br />
      <div className="command-box">
        <label htmlFor="command1">
          Or download the archive file with this command:
        </label>
        <pre id="command1">
          wget
          https://assets.cloudetel.com/cloudeteldev/reach_wan/reachwan.sh.x
        </pre>
        <button className="copy-btn" onClick={() => copyCommand('command1')}>
          Copy
        </button>
      </div>
      <h2>Installation Instructions:</h2>
      <div className="command-box">
        <label htmlFor="command2">Give permission to the file:</label>
        <pre id="command2">chmod +x reachwan.sh.x</pre>
        <button className="copy-btn" onClick={() => copyCommand('command2')}>
          Copy
        </button>
      </div>
      <div className="command-box">
        <label htmlFor="command2"> Install it using below command:</label>
        <pre id="command2">./reachwan.sh.x</pre>
        <button className="copy-btn" onClick={() => copyCommand('command2')}>
          Copy
        </button>
      </div>
      <p>Here, after your device act as Edge device.</p>
    </div>
  );
};

export default DownloadPage;
