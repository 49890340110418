import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Grid, CircularProgress, makeStyles, Dialog, DialogContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  image: {
    width: '100%',
    height: 'auto',
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px', // Set a fixed height to center CircularProgress
  },
}));

const GraphComponent = ({ action, data, additionalHeaders }) => {
  const classes = useStyles();
  const [systemImages, setSystemImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [zabbixGraphs, setZabbixGraphs] = useState([]);

  const { id } = useParams();
  const defaultId = id || '10084';

  useEffect(() => {
    const fetchSystemImages = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL_node;
        const response = await axios.post(`${apiUrl}/GraphGet/${defaultId}`);

        // Filter out graphs not related to Zabbix
        const filteredGraphs = response.data.result.filter(graph => !graph.name.toLowerCase().includes("zabbix"));
        setZabbixGraphs(filteredGraphs);

        const baseUrl = "http://172.104.146.239/zabbix/chart2.php";
        const urls = filteredGraphs.map(graph => `${baseUrl}?graphid=${graph.graphid}&from=now-5m&to=now&height=201&width=1280&profileIdx=web.charts.filter&_=${Math.random()}`);

        const imagePromises = urls.map(async (url) => {
          try {
            const response = await fetch(`${apiUrl}/getsystemlimg?imageUrl=${url}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'image/png',
              },
            });

            if (!response.ok) {
              throw new Error(`Failed to fetch image: ${response.status} ${response.statusText}`);
            }

            const blob = await response.blob();
            const imageUrlObject = URL.createObjectURL(blob);
            return imageUrlObject;
          } catch (error) {
            console.error('Error fetching system image for URL:', url, error);
            return null;
          }
        });

        const systemImagesData = await Promise.all(imagePromises);
        setSystemImages(systemImagesData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching system images:', error);
        setLoading(false);
      }
    };

    fetchSystemImages();
  }, [action, data, additionalHeaders, defaultId]);

  const handleImageClick = (index) => {
    setSelectedImage(systemImages[index]);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedImage(null);
  };

  return (
    <div className={classes.container}>
      <Typography variant="h5" gutterBottom align='center'>
        Graphs
      </Typography>
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <Grid container spacing={2} align='center'>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TableContainer component={Paper}>
              <Table aria-label="ReachManage Graphs Table" align='center'>
                <TableHead>
                  <TableRow>
                    <TableCell>Graph Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {zabbixGraphs.map((graph, index) => (
                    <TableRow key={index} onClick={() => handleImageClick(index)} style={{ cursor: 'pointer' }}>
                      <TableCell>{graph.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
        <DialogContent>
          <img
            src={selectedImage}
            alt="Selected System Image"
            style={{ width: '100%', height: 'auto' }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default GraphComponent;
