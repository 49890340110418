import {React, useEffect, useState} from 'react';
import "./organization.css"
import {getallorganizations} from '../../service/api'
import { useNavigate,  Link, Router  } from "react-router-dom"
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material'
import axios from 'axios'

function Organization() {

  const [users, setUsers] = useState([]);

useEffect(() => {
  getAllOrganizations();
},[]);

const getAllOrganizations = async () =>{
  let response = await getallorganizations();
  setUsers(response.data);
}

const navigate = useNavigate();
const addOrganization = () => {
  navigate('/addOrganization')
}


  return (
    <div className="organization">
        <div className="button" onClick={addOrganization}>Add Organization</div>
        <h1>Organizations</h1>
        <Table>
            <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Group</TableCell>
              <TableCell>Tunnels Key Exchange Method</TableCell>
              <TableCell></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
              {
                users.map(user =>(
                  <TableRow>
                  <TableCell>{user.organizationName}</TableCell>
                    <TableCell>{user.organizationGroup}</TableCell>
                    <TableCell>{user.tunnelKeyExchangeMethod}</TableCell>
                    <TableCell>
                      <Button variant='contained' style={{marginRight: 10}} component={Link} to={`/edit/${user._id}`}>Edit</Button>
                      <Button variant='contained' color="error">Delete</Button>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
        </Table>
    </div>

  )
}

export default Organization

