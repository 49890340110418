import {React, useEffect, useState} from 'react';
import "./users.css"
import {getallusers} from '../../service/api'
import { useNavigate,  Link, Router  } from "react-router-dom"
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material'
import axios from 'axios'

function Users() {

  const [users, setUsers] = useState([]);

useEffect(() => {
getAllUsers();
},[]);

const getAllUsers = async () =>{
  let response = await getallusers();
  setUsers(response.data);
}

const navigate = useNavigate();
const addUser = () => {
  navigate('/addUser')
}

  return (
    <div className="users">
        <div className="button" onClick={addUser}>Add User</div>
        <Table>
            <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Job Title</TableCell>
              <TableCell></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
              {
                users.map(user =>(
                  <TableRow>
                  <TableCell>{user.businessemail}</TableCell>
                    <TableCell>{user.firstname}</TableCell>
                    <TableCell>{user.lastname}</TableCell>
                    <TableCell>{user.jobtitle}</TableCell>
                    <TableCell>
                      <Button variant='contained' style={{marginRight: 10}} component={Link} to={`/edituser/${user._id}`} >Edit</Button>
                      <Button variant='contained' color="error">Delete</Button>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
        </Table>
    </div>

  )
}

export default Users

