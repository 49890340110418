import React, { useState, useEffect } from 'react';
import "./general.css"
import { FormGroup, FormControl, Input, InputLabel, Typography, styled,MenuItem, Button, Tab, Tabs, Box, Table, TableCell, TextField, TableBody, TableHead, TableRow, Select,LinearProgress } from '@mui/material';

import { getgeneralinfo, getinterface, getroutingtable, getvppinterfacedetails, getnetns_interface_details, getubuntu_interface_details, 
  getvxlan_details, getvxlan_tunnel_details, getacl_status, postTunnelData, postoutbound_aclrule, postinbound_aclrule, postqos_policy_add, 
  poststatic_route_add, port_forward_add, identity_mapping_add, upaclrule_add, downaclrule_add, deleteVxlanData, getsystemchecker,
  getacl_statusout,send_file, getqos_policy_status, aclrule_delete, static_mapping_status, identity_mapping_status, deleteportforword,
  static_mapping_delete, getallbackup, dataplane_install, controlplane_install, reach_install,changeMTU, qos_delete, getcsrf, setcsrf,
  changestate,changeipv4,check_vpp_installed, check_frr_installed, check_dataplane_install, check_controlplane_install, heartbeat } from '../../service/api';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Navbar from "../Home/Sidebar";

const Container = styled(FormGroup)`
  overflow: hidden;
  display: inline-block;
  /* Add any other styles you want for your container */
  zoom: 70%;
`;
const ProgressIndicator = ({ progress }) => {
  return (
    <div className="progress">
      <div
        className="progress-bar"
        role="progressbar"
        style={{ width: `${progress}%` }}
        aria-valuenow={progress}
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  );
};

console.log();
function GeneralInfo() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState({
    tunnel_ip: 'none',
    host_name: 'none',
    machine_id: 'none',
    wan_ip: 'none',
  });
  const [tunneldata, setTunneldata] = useState({
    sa_in: 'none',
    sa_out: 'none',
    spi_in: 'none',
    spi_out: 'none',
    vxlan_src_ip: 'none',
    vxlan_dst_ip: 'none',
    vx_loop_id: 'none',
    lan_address: 'none',
  });
  const [outbound_aclrule, setoutbound_aclrule] = useState({
    action: 'none',
    source: 'none',
    destination: 'none',
    src_port: 'none',
    destination_port: 'none',
    protocol: 'none',
    int_index: 'none',
  });
  const [inbound_aclrule, setinbound_aclrule] = useState({
    action: 'none',
    source: 'none',
    destination: 'none',
    src_port: 'none',
    destination_port: 'none',
    protocol: 'none',
    int_index: 2,
  });
  const [qos_policy, setqos_policy] = useState({
    policy_name: 'none',
    int_index: 2,
    inbound: 'none',
    match_dst_port: 'none'
  });
  const [static_route, setstatic_route] = useState({
    destination: 'none',
    gateway_ip: 'none',
    interface: 'none',
    metric: 'none',
  });
  const [port_forwardd, setport_forwardd] = useState({
    int_index: 2,
    protocols: ['tcp', 'udp'],
    ports: 'none',
    internal_ip: 'none',
    internal_port_start: 'none',
  });
  const [identity_mappingg, setidentity_mappingg] = useState({
    int_index: 2,
    protocols: ['tcp', 'udp'],
    internal_ip_address: 'none',
  });
  const [interfc, setInterfc] = useState([]);
  const [csrftoken, setcsrftoken] = useState([]);
  const [routingtable, setRoutingTable] = useState([]);
  const [netns_interface_detail, setNetnsInterfaceDetail] = useState([]);
  const [vppinterface, setVppInterface] = useState([]);
  const [qos_policy_status, setqos_policy_status] = useState([]);
  const [gstatic_mapping_status, setstatic_mapping_status] = useState([]);
  const [gidentity_mapping_status, setidentity_mapping_status] = useState([]);
  const [vxlan_details, setvxlan_details] = useState([]);
  const [acl_status, setacl_status] = useState([]);
  const [acl_statusout, setacl_statusout] = useState([]);
  const [systemchecker, setsystemchecker] = useState([]);
  const [vxlan_tunnel_detail, setvxlan_tunnel_detail] = useState([]);
  const [ubuntu_interface_details, setubuntu_interface_details] = useState([]);
  const [selectedInterface, setSelectedInterface] = useState('');
  const [selectedIndex, setSelectedIndex] = useState('');
  const [currentTab, setCurrentTab] = useState('systemchecker');
  const [selectedProtocols, setSelectedProtocols] = useState([]); // State variable for selected protocols
  const [IpAddress, setInternalIpAddress] = useState('');
  const [isValidIpAddress, setIsValidIpAddress] = useState(true);
  const [destAddress, setInternaldestAddress] = useState('');
  const [isValiddestAddress, setIsValiddestAddress] = useState(true);
  const [sourceIp, setSourceIp] = useState('');
  const [isValidSourceIp, setIsValidSourceIp] = useState(true);
  const [DestIp, setDestIp] = useState('');
  const [isValidDestIp, setIsValidDestIp] = useState(true);
  const [inbound, setInbound] = useState('');
  const [isValidInbound, setIsValidInbound] = useState(true);
  const [portsInput, setPortsInput] = useState('');
  const [isValidPortsInput, setIsValidPortsInput] = useState(true);
  const [internalPortStart, setInternalPortStart] = useState('');
  const [isValidInternalPortStart, setIsValidInternalPortStart] = useState(true);
  const [sourcePort, setSourcePort] = useState('');
  const [isValidSourcePort, setIsValidSourcePort] = useState(true);
  const [destinationPort, setDestinationPort] = useState('');
  const [isValidDestinationPort, setIsValidDestinationPort] = useState(true);
  const [matchSourceIP, setMatchSourceIP] = useState('');
  const [isValidMatchSourceIP, setIsValidMatchSourceIP] = useState(true);
  const [matchDestIP, setMatchDestIP] = useState('');
  const [isValidMatchDestIP, setIsValidMatchDestIP] = useState(true);
  const [fileSent, setFileSent] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0); // New state for progress bar
  const [newMTU, setNewMTU] = useState('');
  const [newState, setnewState] = useState('');
  const [newIPV4, setnewIPV4] = useState('');
  const [progress, setProgress] = useState(0);


  useEffect(() => {
    getInterfc();
    setTimeout(() => {
      loadUserDetails();
    }, 500);
    setTimeout(() => {
      getsyschecker();
    }, 1000);
    setTimeout(() => {
      getRoutingTable();
    }, 1500);
    setTimeout(() => {
      getVppInterface();
    }, 2000);
    setTimeout(() => {
      getvxlan_detail();
    }, 2500);
    setTimeout(() => {
      getnetns_interface_detail();
    }, 3000);
    setTimeout(() => {
      getubuntu_interface_detail();
    }, 3500); 
    setTimeout(() => {
      getvxlan_tunnel_detail();
    }, 4000);
    setTimeout(() => {
      getAcl_status();
    }, 4500);
    setTimeout(() => {
      getAcl_statusout();
    }, 5000);
    setTimeout(() => {
      getqos_policy();
    }, 5500);
    setTimeout(() => {
      getstatic_mapping();
    }, 6000);
    setTimeout(() => {
      getidentity_mapping_status();
    }, 6500);
    setTimeout(() => {
      get_backup();
    }, 7000);
    setTimeout(() => {
      callSendFile();
    }, 7500);
  }, []);
  const handleIpAddressChange = (event) => {
    const ipAddress = event.target.value;
    setInternalIpAddress(ipAddress);
    setIsValidIpAddress(validateIp(ipAddress));
  };
  const handledestinationChange = (event) => {
    const ipAddress = event.target.value;
    setInternaldestAddress(ipAddress);
    setIsValiddestAddress(validateIp(ipAddress));
  };
  const validateIp = (ipAddress) => {
    const parts = ipAddress.split('.');
    if (parts.length !== 4) {
      return false;
    }
    const a = parseInt(parts[0]);
    const b = parseInt(parts[1]);
    const c = parseInt(parts[2]);
    const d = parseInt(parts[3]);

    if (a === 10) {
      if (!(0 <= b && b <= 255 && 0 <= c && c <= 255 && 0 <= d && d <= 255)) {
        return false;
      }
    } else if (a === 172) {
      if (!(16 <= b && b <= 31 && 0 <= c && c <= 255 && 0 <= d && d <= 255)) {
        return false;
      }
    } else if (a === 192) {
      if (!(b === 168 && 0 <= c && c <= 255 && 0 <= d && d <= 255)) {
        return false;
      }
    } else {
      return false;
    }

    return true;
  };

  const handleSourceIpChange = (event) => {
    const ipAddress = event.target.value;
    setSourceIp(ipAddress);
    setIsValidSourceIp(validateIp2(ipAddress));
  };
  const handleSourcedestChange = (event) => {
    const ipAddress = event.target.value;
    setDestIp(ipAddress);
    setIsValidDestIp(validateIp2(ipAddress));
  };

  const validateIp2 = (ipAddress) => {
    const parts = ipAddress.split('/');
    if (parts.length !== 2) {
      return false;
    }

    const ipParts = parts[0].split('.');
    const maskPart = parts[1];

    if (ipParts.length !== 4) {
      return false;
    }

    for (let i = 0; i < ipParts.length; i++) {
      const part = ipParts[i];
      if (!/^\d+$/.test(part)) {
        return false;
      }
      const value = parseInt(part);
      if (value < 0 || value > 255) {
        return false;
      }
    }

    if (!/^\d+$/.test(maskPart)) {
      return false;
    }
    const maskValue = parseInt(maskPart);
    if (maskValue < 0 || maskValue > 32) {
      return false;
    }

    return true;
  };



  const validateSourcePort = (value) => {
    const portPattern = /^[0-9]+(-[0-9]+)?$/;

    if (!portPattern.test(value)) {
      setIsValidSourcePort(false);
    } else {
      const [a, b] = value.split('-').map(Number);

      if (a >= 0 && a <= 65535 && b >= 0 && b <= 65535 && b > a) {
        setIsValidSourcePort(true);
      } else {
        setIsValidSourcePort(false);
      }
    }
    setSourcePort(value);
  }; 
  

  
  const handleSPChange = (event) => {
    validateSourcePort(event.target.value);
  };

  



  const validateDestinationPort = (value) => {
    const portPattern = /^[0-9]+(-[0-9]+)?$/;

    if (!portPattern.test(value)) {
      setIsValidDestinationPort(false);
    } else {
      const [a, b] = value.split('-').map(Number);

      if (a >= 0 && a <= 65535 && b >= 0 && b <= 65535 && b > a) {
        setIsValidDestinationPort(true);
      } else {
        setIsValidDestinationPort(false);
      }
    }
    setDestinationPort(value);
  };

  const handleChange = (event) => {
    validateDestinationPort(event.target.value);
  };


  const handleInboundChange = (event) => {
    const value = event.target.value;
    setInbound(value);
    setIsValidInbound(validateInbound(value));
  };

  const validateInbound = (value) => {
    return value === '0' || value === '1';
  };

  const validateIp3 = (ipAddress) => {
    const parts = ipAddress.split('.');
    if (parts.length !== 4) {
      return false;
    }
  
    for (let i = 0; i < parts.length; i++) {
      const part = parseInt(parts[i]);
      if (isNaN(part) || part < 0 || part > 255) {
        return false;
      }
    }
    return true;
  };
  
  const validateMatchSourceIP = (value) => {
    if (validateIp3(value)) {
      setIsValidMatchSourceIP(true);
    } else {
      setIsValidMatchSourceIP(false);
    }
    setMatchSourceIP(value);
  };
  

  const handleMatchSourceIPChange = (event) => {
    validateMatchSourceIP(event.target.value);
  };





  const validateMatchDestIP = (value) => {
    const ipPattern = /^(10(\.\d{1,3}){3}|172\.(1[6-9]|2\d|3[0-1])(\.\d{1,3}){2}|192\.168(\.\d{1,3}){2})$/;

    if (!ipPattern.test(value)) {
      setIsValidMatchDestIP(false);
    } else {
      const [a, b, c, d] = value.split('.').map(Number);

      if (a === 10 && b >= 0 && b <= 255 && c >= 0 && c <= 255 && d >= 0 && d <= 255) {
        setIsValidMatchDestIP(true);
      } else if (a === 172 && b >= 16 && b <= 31 && c >= 0 && c <= 255 && d >= 0 && d <= 255) {
        setIsValidMatchDestIP(true);
      } else if (a === 192 && b === 168 && c >= 0 && c <= 255 && d >= 0 && d <= 255) {
        setIsValidMatchDestIP(true);
      } else {
        setIsValidMatchDestIP(false);
      }
    }

    setMatchDestIP(value);
  };

  const handleMatchDestIPChange = (event) => {
    validateMatchDestIP(event.target.value);
  };





  const handlePortsInputChange = (event) => {
    const input = event.target.value;
    setPortsInput(input);
    setIsValidPortsInput(validatePortsInput(input));
  };

  const validatePortsInput = (input) => {
    const regex = /^(\d{1,5}|\d{1,5}-\d{1,5})$/;
    if (!regex.test(input)) {
      return false;
    }

    const parts = input.split('-');
    const a = parseInt(parts[0]);
    const b = parseInt(parts[1]);

    if (parts.length === 1) {
      return a >= 0 && a <= 65535;
    } else if (parts.length === 2) {
      return a >= 0 && a <= 65535 && b >= 0 && b <= 65535 && b > a;
    }

    return false;
  };



  const handleInternalPortStartChange = (event) => {
    const input = event.target.value;
    setInternalPortStart(input);
    setIsValidInternalPortStart(validateInternalPortStart(input));
  };

  const validateInternalPortStart = (input) => {
    const port = parseInt(input);
    return !isNaN(port) && port >= 0 && port <= 65535;
  };


  const isportFormValid =
    isValidPortsInput && isValidInternalPortStart && selectedIndex && isValidIpAddress ;


    const isstaticFormValid =
    isValiddestAddress;

    const isoutFormValid =
    isValidSourceIp && isValidDestIp && isValidSourcePort && isValidDestinationPort;

    const isinboundFormValid =
    isValidSourceIp && isValidDestIp  && isValidSourcePort && isValidDestinationPort;

    const isQOSFormValid =
    isValidInbound && isValidMatchSourceIP && isValidMatchDestIP ;


const getVppInterface = async () => {
  try {
    const response = await getvppinterfacedetails(id);
    const combinedData = response.data;
    
    // Check if combinedData is not null or empty
    if (combinedData && Object.keys(combinedData).length !== 0) {
      // Extract the data arrays from the combinedData object
      const data1 = combinedData.data1;
      const data2 = combinedData.data2;
      // Concatenate the two arrays to create a single array of user objects
      const allUsers = [...data1, ...data2];
      setVppInterface(allUsers);

      // Set FileSent to true since response is not empty
      setFileSent(true);
    } else {
      console.log('Response is null or empty.');
    }
  } catch (error) {
    console.log('Error while getting VPP interface data:', error);
  }
};


const get_backup = async () => {
  try {
    const response = await getallbackup(id);
    console.log(response);
  } catch (error) {
    console.log('Error while getting Backup :', error);
  }
};
const get_heartbeat = async () => {
  try {
    const response = await heartbeat(id);
    console.log(response);
  } catch (error) {
    console.log('Error while getting Backup :', error);
    navigate("/devices")
  }
};
const callSendFile = () => {
  setInterval(async () => {
    try {
      await get_heartbeat(); // Pass null for res in this context
    } catch (error) {
      console.log('Error while calling send_file:', error);
    }
  }, 5000); // Repeat every  (5 second)
};
// const get_csrf= async () => {
//   try {
//     const response = await getcsrf(id);
//     console.log('csrf token ', response.data[0].csrf_token);
//     setcsrftoken(response.data[0].csrf_token)
//     set_csrf();
//   } catch (error) {
//     console.log('Error while getting Backup :', error);
//   }
// };
// const set_csrf= async () => {
//   try {
//     console.log(csrftoken);
//     const response = await setcsrf(csrftoken);
//     console.log(response);
    
//   } catch (error) {
//     console.log('Error while getting setcsrf :', error);
//   }
// };
  
  const getInterfc = async () => {
    try {
      const response = await getinterface(id);
      setInterfc(response.data);
      if (response.data) {
        setFileSent(true)
      }
    } catch (error) {
      console.log('Error while getting interface data:', error);
    }
  };

  const getRoutingTable = async () => {
    try {
      const response = await getroutingtable(id);
      setRoutingTable(response.data);
    } catch (error) {
      console.log('Error while getting routing table data:', error);
    }
  };
  const getnetns_interface_detail = async () => {
    try {
      const response = await getnetns_interface_details(id);
      setNetnsInterfaceDetail(response.data);
    } catch (error) {
      console.log('Error while getting routing table data:', error);
    }
  };

  const getubuntu_interface_detail = async () => {
    try {
      const response = await getubuntu_interface_details(id);
      setubuntu_interface_details(response.data);
    } catch (error) {
      console.log('Error while getting routing table data:', error);
    }
  };
  

  const getvxlan_detail = async () => {
    try {
      const response = await getvxlan_details(id);
      setvxlan_details(response.data);
    } catch (error) {
      console.log('Error while getting routing table data:', error);
    }
  };
  

  const getstatic_mapping = async () => {
    try {
      const response = await static_mapping_status(id);
      setstatic_mapping_status(response.data);
    } catch (error) {
      console.log('Error while getting routing table data:', error);
    }
  };



  const getidentity_mapping_status = async () => {
    try {
      const response = await identity_mapping_status(id);
      setidentity_mapping_status(response.data);
    } catch (error) {
      console.log('Error while getting routing table data:', error);
    }
  };


  const getqos_policy = async () => {
    try {
      const response = await getqos_policy_status(id);
      setqos_policy_status(response.data);
    } catch (error) {
      console.log('Error while getting routing table data:', error);
    }
  };

  const getvxlan_tunnel_detail = async () => {
    try {
      const response = await getvxlan_tunnel_details(id);
      setvxlan_tunnel_detail(response.data);
    } catch (error) {
      console.log('Error while getting routing table data:', error);
    }
  };
  
  
  const getAcl_status = async () => {
    try {
      const response = await getacl_status(id);
      setacl_status(response.data);
    } catch (error) {
      console.log('Error while getting routing table data:', error);
    }
  };
  const getAcl_statusout = async () => {
    try {
      const response = await getacl_statusout(id);
      setacl_statusout(response.data);
    } catch (error) {
      console.log('Error while getting routing table data:', error);
    }
  };
  const getsyschecker = async () => {
    try {
      const response = await getsystemchecker(id);
      console.log(response.data);
      setsystemchecker(response.data);
    } catch (error) {
      console.log('Error while getting routing table data:', error);
    }
  };
  const getcheck_vpp_installed = async () => {
    try {
      const response = await check_vpp_installed(id);
      console.log(response.data);
      displayAlert(`  ${response.data[0].vpp_status} `, 'alert-success');
    } catch (error) {
      console.log('Error while getting routing table data:', error);
    }
  };
  const getcheck_frr_installed = async () => {
    try {
      const response = await check_frr_installed(id);
      console.log(response.data);
      displayAlert(`  ${response.data[0].frr_status} `, 'alert-success');
    } catch (error) {
      console.log('Error while getting routing table data:', error);
    }
  };
  const getdataplane_install = async () => {
    try {
      const response = await dataplane_install(id);
      console.log(response.data);
      getcontrolplane_install();
      displayAlert(`DATA PLANE  ${response.data[0].message} `, 'alert-success');
    } catch (error) {
      console.log('Error while getting routing table data:', error);
    }
  };
  const getcontrolplane_install = async () => {
    try {
      const response = await controlplan_install(id);
      console.log(response.data);
      displayAlert(`CONTROL PLANE  ${response.data[0].message} `, 'alert-success');
    } catch (error) {
      console.log('Error while getting routing table data:', error);
    }
  };

  const loadUserDetails = async () => {
    try {
      const response = await getgeneralinfo(id);
      setUser(response.data);
    } catch (error) {
      console.log('Error while loading user details:', error);
    }
  };

  
  const onValueChange = (e) => {
    
    const { name, value } = e.target;
    console.log(e.target);
  
    setUser({ ...user, [name]: value });
    setTunneldata({ ...tunneldata, [name]: value });
    setoutbound_aclrule({ ...outbound_aclrule, [name]: value });
    console.log(outbound_aclrule);
    setinbound_aclrule({ ...inbound_aclrule, [name]: value });
    setqos_policy({ ...qos_policy, [name]: value });
    setstatic_route({ ...static_route, [name]: value });
    setport_forwardd({ ...port_forwardd, [name]: value });
    setidentity_mappingg({ ...identity_mappingg, [name]: value });
  
    if (name === 'int_index') {
      setSelectedIndex(value);
    }
  };
  


  const displayAlert = (message, className) => {
    const alertElement = document.getElementById('alertMessage');
    alertElement.textContent = message;
    alertElement.classList.add(className);
    alertElement.style.display = 'block';
  };
  const postTunnel = async () => {
    try {
      await postTunnelData(tunneldata, id);
      displayAlert('Data posted successfully!', 'alert-success');
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  };

  const postoutbound_acl = async () => {
    try {
      
    setProgress(0);
    console.log('progress',progress);
      const response = await postoutbound_aclrule(outbound_aclrule, id);
      
    const progressPercentage = response.data.progress;
    console.log('progresspercent',progressPercentage);
    setProgress(progressPercentage);
      displayAlert('Data posted successfully!', 'alert-success');
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  };

  const postinbound_acl = async () => {
    try {
      setProgress(0);
      const response = await postinbound_aclrule(inbound_aclrule, id);
      const progressPercentage = response.data.progress;
      console.log('progresspercent',progressPercentage);
      setProgress(progressPercentage);
      displayAlert('Data posted successfully!', 'alert-success');
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  };

  const postqos_policy = async () => {
    try {
      setProgress(0);
      const response = await postqos_policy_add(qos_policy, id);
      const progressPercentage = response.data.progress;
      console.log('progresspercent',progressPercentage);
      setProgress(progressPercentage);
      displayAlert('Data posted successfully!', 'alert-success');
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  };
  const port_forward = async () => {
    try {
      setProgress(0);
      const response =  await port_forward_add(port_forwardd, id);
      const progressPercentage = response.data.progress;
      console.log('progresspercent',progressPercentage);
      setProgress(progressPercentage);
      displayAlert('Data posted successfully!', 'alert-success');
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  };
  const identity_mapping = async () => {
    try {
      setProgress(0);
      const response = await identity_mapping_add(identity_mappingg, id);
      const progressPercentage = response.data.progress;
      console.log('progresspercent',progressPercentage);
      setProgress(progressPercentage);
      displayAlert('Data posted successfully!', 'alert-success');
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  };
  const upaclrule = async (rule_type, sw_if_index, data) => {
    try {
      const value ={rule_type :rule_type,sw_if_index:sw_if_index ,acl_index : data}
      setProgress(0);
      const response =await upaclrule_add(value, id);
      const progressPercentage = response.data.progress;
      setProgress(progressPercentage);
      console.log('progresspercent',progressPercentage);
      displayAlert('Firewall RULE UP', 'alert-success');
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  }
  const sendfile = async () => {
    try {
      setFileSent(true);
      console.log(systemchecker[0]);
  
      if (systemchecker[0].instruction_set_compatible !== true) {
        alert("Instruction Set not compatible");
      } else {
        if (systemchecker[0].ram < 4) {
          alert("Minimum 4 GB RAM Required");
        } else {
          if (systemchecker[0].no_of_cpu_core < 2) {
            alert("Minimum 2 CPU Cores Required");
          } else {
            if (systemchecker[0].no_of_nic < 2) {
              alert("Minimum 2 NICs Required");
            } else {
              if (systemchecker[0].nic_driver_info[0].supported !== 'yes') {
                alert("1st NIC Driver Needs to support");
              } else {
                if (systemchecker[0].nic_driver_info[1].supported !== 'yes') {
                  alert("2nd NIC Driver Needs to support");
                } else {
                  setFileSent(true);
                  const response = await send_file(id);
                  if (response.status === 200) {
                    setProgress(0);
                    setFileSent(true);
                    const progressPercentage = response.data.progress;
                    setProgress(progressPercentage);
                    displayAlert('File Sent Successfully', 'alert-success');
                    dataplan_install();
                  } else {
                    // Handle other response statuses or errors here
                    displayAlert('Error while file transfer: ' + response.data.message, 'alert-error');
                    navigate(`/devices`)
                    
                  }
                }
              }
            }
          }
        }
      }
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
      navigate(`/devices`)
    }
  };
  
  const dataplan_install = async () => {
    try {
      setProgress(0);
      const response =await dataplane_install(id);
      const progressPercentage = response.data.progress;
      console.log('progresspercent',progressPercentage);
      setProgress(progressPercentage);
      displayAlert('Data Plane Installed Successfully', 'alert-success');
      controlplan_install();
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  };
  const controlplan_install = async () => {
    try {
      setProgress(0);
      const response =await controlplane_install(id);
      const progressPercentage = response.data.progress;
      console.log('progresspercent',progressPercentage);
      setProgress(progressPercentage);
      displayAlert('Control Plane Installed Successfully', 'alert-success');
      reachedge_install();
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  };



  const reachedge_install = async () => {
    try {
      setProgress(0);
      const response =await reach_install(id);
      const progressPercentage = response.data.progress;
      console.log('progresspercent',progressPercentage);
      setProgress(progressPercentage);
      displayAlert('Reach Edge Installed Successfully', 'alert-success');
    } catch (error) {
      displayAlert('Reach Edge Installing Successfully wait for 10 minutes We are readirecting you to Device page after completion', 'alert-success');
      setTimeout(() => {
        
    navigate(`/devices`)// Refresh the page after 10 Minutes
      }, 600000);
    }
  };




  const getratelimit = () => {
    navigate(`/ratelimiting/${id}`)
  }

  const getdhcp = () => {
    navigate(`/dhcp/${id}`)
  }
  


  
  // Function to handle changing MTU
  const handleChangeMTU = async (intIndex, int_link_mtu) => {
    try {
      const value ={sw_if_index : intIndex,int_link_mtu:int_link_mtu, mtu : newMTU }
      
      setProgress(0);
      const response =await changeMTU(value, id);
      const progressPercentage = response.data.progress;
      console.log('progresspercent',progressPercentage);
      setProgress(progressPercentage);
      displayAlert('MTU Changed', 'alert-success');
      getInterfc();
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  };
  // Function to handle changing MTU
  const handleChangeIPV4 = async (intIndex, int_ipv4_address) => {
    try {
      const value ={sw_if_index : intIndex, current_ipaddress :int_ipv4_address,  new_ipaddress : newIPV4 }
      
      setProgress(0);
      const response =await changeipv4(value, id);
      const progressPercentage = response.data.progress;
      console.log('progresspercent',progressPercentage);
      setProgress(progressPercentage);
      displayAlert('INTERFACE IPV4 ADDRESS Changed', 'alert-success');
      
      getInterfc();
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 500);
    } catch (error) {
      displayAlert('','alert-success');
    }
  };
  // Function to handle changing State
  const handleChangeState = async (intIndex) => {
    try {
      const value ={sw_if_index : intIndex, state : newState }
      
      setProgress(0);
      const response =await changestate(value, id);
      const progressPercentage = response.data.progress;
      console.log('progresspercent',progressPercentage);
      setProgress(progressPercentage);
      displayAlert('State Changed', 'alert-success');
      getInterfc();
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  };


  const downaclrule = async (rule_type,sw_if_index,data) => {
    try {
      const value ={rule_type :rule_type,sw_if_index:sw_if_index ,acl_index : data}
      setProgress(0);
      const response =await downaclrule_add(value, id);
      const progressPercentage = response.data.progress;
      console.log('progresspercent',progressPercentage);
      setProgress(progressPercentage);
      displayAlert('Firewall RULE Down', 'alert-success');
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  }
  const deleteacl = async (rule_type,sw_if_index,data) => {
    try {
      const value ={rule_type :rule_type,sw_if_index:sw_if_index ,acl_index : data}
      setProgress(0);
      const response =await aclrule_delete(value, id);
      const progressPercentage = response.data.progress;
      console.log('progresspercent',progressPercentage);
      setProgress(progressPercentage);
      displayAlert('Selected Firewall RULE Deleted', 'alert-danger');
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  }
  const deleteqos = async (policy_name, dscp,match_dst_ip, match_dst_port, protocol ) => {
    try {
      const value ={policy_name : policy_name,dscp:dscp,match_dst_ip:match_dst_ip,match_dst_port:match_dst_port,protocol:protocol}
      
      setProgress(0);
      const response =await qos_delete(value, id);
      const progressPercentage = response.data.progress;
      console.log('progresspercent',progressPercentage);
      setProgress(progressPercentage);
      displayAlert('Selected QOS Policy Deleted', 'alert-danger');
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  }
  
  const deleteport = async (data) => {
    try {
      console.log(data)
      
      setProgress(0);
      const response =await deleteportforword(data, id);
      const progressPercentage = response.data.progress;
      console.log('progresspercent',progressPercentage);
      setProgress(progressPercentage);
      displayAlert('Selected Port Forwording Rule Deleted', 'alert-danger');
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  }
  const deletestatic_mapping = async (data) => {
    try {
      console.log(data)
      
      setProgress(0);
      const response =await static_mapping_delete(data, id);
      const progressPercentage = response.data.progress;
      console.log('progresspercent',progressPercentage);
      setProgress(progressPercentage);
      displayAlert('Selected Rule Deleted', 'alert-danger');
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  }
  const deletevxlan = async (vxlan_src_ip, vxlan_dst_ip) => {
    try {
      const value = { vxlan_src_ip: vxlan_src_ip, vxlan_dst_ip: vxlan_dst_ip };
      console.log(value);
      
      setProgress(0);
      const response =await deleteVxlanData(value, id);
      displayAlert('Selected Tunnel Deleted', 'alert-success');
      const progressPercentage = response.data.progress;
      console.log('progresspercent',progressPercentage);
      setProgress(progressPercentage);
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  };
  
  
  const poststatic_route = async () => {
    try {
      setProgress(0);
      const response =await poststatic_route_add(static_route, id);
      const progressPercentage = response.data.progress;
      console.log('progresspercent',progressPercentage);
      setProgress(progressPercentage);
      displayAlert('Data posted successfully!', 'alert-success');
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  };
  
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };  

  return (
    
    <div className="HomePageContainer">
      <div id="page-wrapper" className="gray-bg">
        
        <div className="row border-bottom " >
            
                <li>
                    <span className="m-r-sm text-muted welcome-message">Welcome to Reach Manage.</span>
                </li>
            <Navbar />
        </div>
    <Container fullWidth>
    <LinearProgress variant="determinate" value={progress} />
      <div id="alertMessage" className="alert"></div>

    <Tabs
      value={currentTab}
      onChange={handleTabChange}
      variant="scrollable"
      scrollButtons="auto"
    >
        {/* <Tab label="General" value="general" /> */}
        {/* <Tab label="Interfaces" value="interfaces" /> */}
        <Tab label="Reach Interface Details" value="vppinterface" onClick={event => { getInterfc(); get_backup(); }}/>
        <Tab label="System Checker" value="systemchecker" onClick={event => { getsyschecker(); get_backup(); }}/>
        <Tab label="Routing" value="routing" onClick={event => { getRoutingTable(); get_backup();}}/>
        <Tab label="Interfaces" value="netns_interface_detail" onClick={event => { getnetns_interface_detail(); get_backup();}}/>
        {/* <Tab label="Tunnel Details" value="vxlan_details" onClick={event => { getvxlan_detail(); get_backup();}}/> */}
        <Tab label="Static Routing" value="static_route"/>
        <Tab label=" Tunnel Status" value="vxlan_tunnel_detail" onClick={event => { getvxlan_tunnel_detail(); get_backup();}}/>
        <Tab label="Firewall Status" value="Acl_status" onClick={event => { getAcl_status(); getAcl_statusout(); get_backup();}}/>
        {/* <Tab label="Tunnel Data" value="tunnel_data" /> */}
        <Tab label="Outbound Rule" value="outbound_aclrule" />
        <Tab label="Inbound Rule" value="Inbound_aclrule"/>
        <Tab label="QOS Policy Status" value="qos_policy_status" onClick={event => { getqos_policy(); get_backup();}}/>
        <Tab label="QOS Policy" value="qos_policy" />
        <Tab label="Port Forwarding" value="portforwarding"/>
        <Tab label="Port Forwarding status" value="static_mapping_status" onClick={event => { getstatic_mapping(); get_backup(); }}/>
        <Tab label="1:1 Mapping " value="identity_mapping" />
        <Tab label="ratelimiting " onClick={getratelimit} />
        <Tab label="DHCP " onClick={getdhcp} />
        <Tab label="1:1 Mapping Status" value="identity_mapping_status" onClick={event => { getidentity_mapping_status(); get_backup(); }}/>
      </Tabs> 
      <Box hidden={currentTab !== 'systemchecker'}>
  <div className="vppinterface">
    
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>instruction_set_compatible</TableCell>
          <TableCell>RAM</TableCell>
          <TableCell>no_of_cpu_core</TableCell>
          <TableCell>no_of_nic</TableCell>
          <TableCell>kernel_module_supported</TableCell>
          <TableCell>nic_driver_info</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {/* <div>{JSON.stringify(systemchecker)}</div> */}
        {systemchecker.length && systemchecker?.map((user) => (
          <TableRow key={user.instruction_set_compatible}>
            <TableCell>{user.instruction_set_compatible ? 'Yes' : 'No'}</TableCell>
            <TableCell>{user.ram}</TableCell>
            <TableCell>{user.no_of_cpu_core}</TableCell>
            <TableCell>{user.no_of_nic}</TableCell>
            <TableCell>{user.kernel_module_supported ? 'Yes' : 'No'}</TableCell>
            <TableCell>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Manufacturer Name</TableCell>
                    <TableCell>Driver</TableCell>
                    <TableCell>Supported</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {user.nic_driver_info?.map((nic, index) => (
                    <TableRow key={index}>
                      <TableCell>{nic.manufacturer_name}</TableCell>
                      <TableCell>{nic.driver}</TableCell>
                      <TableCell>{nic.supported === 'yes' ? 'Yes' : 'No'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableCell>
      <TableCell>
        {/* Render the Button and disable it when the file is sent */}
        <Button onClick={() => sendfile()} className="btn btn-primary" disabled={fileSent}>
          SendFile
        </Button>
      </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableRow>
        <TableCell>
      <Button onClick={() => getcheck_vpp_installed()} className="btn btn-primary"  disabled={fileSent} >
      Check DATA PLANE Installed
        </Button></TableCell>
        <TableCell>
      <Button onClick={() => getcheck_frr_installed()} className="btn btn-primary" disabled={fileSent} >
      Check CONTROL PLANE Installed
        </Button></TableCell>
        <TableCell>
      <Button onClick={() => dataplan_install()} className="btn btn-primary" disabled={fileSent} >
         Install  DATA PLANE 
        </Button></TableCell>
        <TableCell>
      <Button onClick={() => controlplan_install()} className="btn btn-primary" disabled={fileSent} >
          Install CONTROL PLANE 
        </Button></TableCell>
        </TableRow>
    </Table>
  </div>
</Box>
      <Box hidden={currentTab !== 'vppinterface'}>
        <div className="vppinterface">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Interface Index</TableCell>
                <TableCell>Interface IPV4 Address</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Interface Link MTU</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>Interface Mac Address</TableCell>
                {/* <TableCell>Interface MTU</TableCell> */}
                <TableCell>Interface Name</TableCell>
                <TableCell>Interface Speed</TableCell>
                <TableCell>Interface Status</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {interfc?.map((user) => (
                <TableRow key={user.int_index}>
                  <TableCell>{user.int_index}</TableCell>
                  <TableCell>{user.int_ipv4_address}</TableCell>
                <TableCell>
    <FormControl>
      <TextField
        label="New IPV4 Address"
        variant="outlined"
        onChange={event => {
          handleSourceIpChange(event);
          setnewIPV4(event.target.value); // Call the onValueChange function
        }}
      />
    </FormControl></TableCell>
    <TableCell>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleChangeIPV4(user.int_index,user.int_ipv4_address) } disabled={!isValidSourceIp}
      >
        Change Interface
      </Button>
    </TableCell>
                  <TableCell>{user.type}</TableCell>
                  <TableCell>{user.int_link_mtu}</TableCell>
    <TableCell>
      <TextField
        label="New MTU Value"
        variant="outlined"
        onChange={(event) => setNewMTU(event.target.value)}
      />
    </TableCell>
    <TableCell>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleChangeMTU(user.int_index, user.int_link_mtu)}
      >
        Change MTU
      </Button>
    </TableCell>
                  <TableCell>{user.int_mac_address}</TableCell>
                  {/* <TableCell>{user.int_mtu}</TableCell> */}
                  <TableCell>{user.int_name}</TableCell>
                  <TableCell>{user.int_speed}</TableCell>
                  <TableCell>{user.int_status}</TableCell>
                  
        <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">State</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="state"
    name="state"
    label="state"
      style={{ textAlign: 'center' }} 
    onChange={(event) => setnewState(event.target.value)}
  >
    <MenuItem value='up'>up</MenuItem>
    <MenuItem value='down'>down</MenuItem>
    <MenuItem value='inactive'>inactive</MenuItem>
  </Select>
</FormControl>
    <TableCell>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleChangeState(user.int_index)}
      >
        Change State
      </Button>
    </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Box>
      <Box hidden={currentTab !== 'routing'}>
        <div className="routing">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Destination</TableCell>
                <TableCell>Gateway</TableCell>
                <TableCell>Interface</TableCell>
                <TableCell>Metric</TableCell>
                <TableCell>Protocol</TableCell>
                {/* <TableCell></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {routingtable?.map((user) => (
                <TableRow key={user.destination}>
                  <TableCell>{user.destination}</TableCell>
                  <TableCell>{user.gateway}</TableCell>
                  <TableCell>{user.interface_name}</TableCell>
                  <TableCell>{user.metric}</TableCell>
                  <TableCell>{user.protocol}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Box>
      <Box hidden={currentTab !== 'netns_interface_detail'}>
      <div className="routing">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>IPv4address</TableCell>
                {/* <TableCell>IPv4address_noprefix</TableCell> */}
                <TableCell>Broadcast</TableCell>
                <TableCell>Interface Name</TableCell>
                <TableCell>Mac Address</TableCell>
                <TableCell>Type</TableCell>
                {/* <TableCell>Netmask</TableCell> */}
                {/* <TableCell></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {netns_interface_detail?.map((user) => (
                <TableRow key={user.IPv4address}>
                  <TableCell>{user.IPv4address}</TableCell>
                  {/* <TableCell>{user.IPv4address_noprefix}</TableCell> */}
                  <TableCell>{user.broadcast}</TableCell>
                  <TableCell>{user.interface_name}</TableCell>
                  <TableCell>{user.mac_address}</TableCell>
                  <TableCell>{user.type}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Box>
      <Box hidden={currentTab !== 'vxlan_details'}>
      <div className="routing">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Source Address</TableCell>
                <TableCell>Destination Address</TableCell>
                <TableCell>VNI</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vxlan_details?.map((user) => (
                <TableRow key={user.src_address}>
                  <TableCell>{user.src_address}</TableCell>
                  <TableCell>{user.dst_address}</TableCell>
                  <TableCell>{user.vni}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Box>
      <Box hidden={currentTab !== 'static_route'}>
        <Typography variant="h4">Static Route</Typography>
        
    <FormControl>
      <InputLabel>Destination</InputLabel>
      <Input
        name="destination"
        value={destAddress}
        onChange={event => {
          handledestinationChange(event);
          onValueChange(event); // Call the onValueChange function
        }}
        error={!isValiddestAddress}
      />
    </FormControl><br />
        <FormControl style={{ width: '50%' }} >
        <InputLabel  id="interface-select-label">Gateway Ip </InputLabel>
        <Select name="gateway_ip" 
        labelId="interface-select-label"
        id="interface-select"
        value={selectedInterface}
      style={{ textAlign: 'center' }} 
        onChange={onValueChange}
        >
          {routingtable?.map((user) => (
        <MenuItem value={user.gateway} key={user.gateway}>
          {user.gateway}
        </MenuItem>
      ))}
        </Select>
        </FormControl><br />
        <FormControl style={{ width: '50%' }}>
        <InputLabel  id="interface-select-label">Interface</InputLabel>
        <Select name="interface" 
        labelId="interface-select-label"
        id="interface-select"
      style={{ textAlign: 'center' }} 
        value={selectedInterface}
        onChange={onValueChange}
        >
          {netns_interface_detail?.map((user) => (
        <MenuItem value={user.interface_name} key={user.interface_name}>
          {user.interface_name}
        </MenuItem>
      ))}
        </Select>
        </FormControl>
        <FormControl>
          <Button variant="contained" onClick={poststatic_route} disabled={!isstaticFormValid}>
            Add Static route
          </Button>
        </FormControl>
      </Box>
      <Box hidden={currentTab !== 'vxlan_tunnel_detail'}>
      <div className="routing">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Source Address</TableCell>
                <TableCell>Destination Address</TableCell>
                <TableCell>Source Loopback Address</TableCell>
                <TableCell>Destination Loopback Address</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Jitter</TableCell>
                <TableCell>RTT</TableCell>
                <TableCell>Encryption</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vxlan_tunnel_detail?.map((user) => (
                <TableRow key={user.src_address}>
                  <TableCell>{user.src_address}</TableCell>
                  <TableCell>{user.dst_address}</TableCell>
                  <TableCell>{user.src_loopback_addr}</TableCell>
                  <TableCell>{user.dst_loopback_addr}</TableCell>
                  <TableCell>{user.status}</TableCell>
                  <TableCell>{user.jitter}</TableCell>
                  <TableCell>{user.rtt}</TableCell>
                  <TableCell>{user.encrypt}</TableCell>
                  <TableCell>
                  <Button color="error" onClick={() => deletevxlan(user.src_address,user.dst_address)} className="btn btn-primary">Delete</Button>
                    
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Box>
      <Box hidden={currentTab !== 'Acl_status'}>
  <div className="routing">
    
  <h1>Lan Interface Inbound Rules</h1>
<Table>
  <TableHead>
    <TableRow>
      {/* <TableCell>SW IF INDEX</TableCell> */}
      <TableCell>Action</TableCell>
      <TableCell>Source Port</TableCell>
      {/* <TableCell>Interface type</TableCell> */}
      <TableCell>Destination Port</TableCell>
      <TableCell>Source</TableCell>
      <TableCell>Destination</TableCell>
      <TableCell>Protocol</TableCell>
      <TableCell></TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {acl_status
      .filter(user => user.int_type === "lan") // Filter rows with interface type "lan"
      .sort((a, b) => a.acl_index - b.acl_index) // Sort the filtered array by acl_index
      .map((user) => (
        <TableRow key={user.acl_index}>
        {/* <TableCell>{user.sw_if_index}</TableCell> */}
        <TableCell>{user.action}</TableCell>
          <TableCell>{user.src_port}</TableCell>
          {/* <TableCell>{user.int_type}</TableCell> */}
          <TableCell>{user.dst_port}</TableCell>
          <TableCell>{user.source}</TableCell>
          <TableCell>{user.destination}</TableCell>
          <TableCell>{user.protocol}</TableCell>
          <TableCell>
            <Button onClick={() => upaclrule("inbound",user.sw_if_index, user.acl_index)} className="btn btn-primary">Up</Button>
            <Button onClick={() => downaclrule("inbound",user.sw_if_index, user.acl_index)} className="btn btn-primary">Down</Button>
            <Button onClick={() => deleteacl("inbound",user.sw_if_index, user.acl_index)} className="btn btn-primary">Delete</Button>
          </TableCell>
        </TableRow>
      ))}
  </TableBody>
</Table>
  <h1>WAN Interface Inbound Rules</h1>
<Table>
  <TableHead>
    <TableRow>
      {/* <TableCell>SW IF INDEX</TableCell> */}
      <TableCell>Action</TableCell>
      <TableCell>Source Port</TableCell>
      {/* <TableCell>Interface type</TableCell> */}
      <TableCell>Destination Port</TableCell>
      <TableCell>Source</TableCell>
      <TableCell>Destination</TableCell>
      <TableCell>Protocol</TableCell>
      <TableCell></TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {acl_statusout
      .filter(user => user.int_type === "wan") // Filter rows with interface type "lan"
      .sort((a, b) => a.acl_index - b.acl_index) // Sort the filtered array by acl_index
      .map((user) => (
        <TableRow key={user.acl_index}>
        {/* <TableCell>{user.sw_if_index}</TableCell> */}
        <TableCell>{user.action}</TableCell>
          <TableCell>{user.src_port}</TableCell>
          {/* <TableCell>{user.int_type}</TableCell> */}
          <TableCell>{user.dst_port}</TableCell>
          <TableCell>{user.source}</TableCell>
          <TableCell>{user.destination}</TableCell>
          <TableCell>{user.protocol}</TableCell>
          <TableCell>
            <Button onClick={() => upaclrule("inbound",user.sw_if_index, user.acl_index)} className="btn btn-primary">Up</Button>
            <Button onClick={() => downaclrule("inbound",user.sw_if_index, user.acl_index)} className="btn btn-primary">Down</Button>
            <Button onClick={() => deleteacl("inbound",user.sw_if_index, user.acl_index)} className="btn btn-primary">Delete</Button>
          </TableCell>
        </TableRow>
      ))}
  </TableBody>
</Table>
 
<h1>Lan Interface OutBound Rules</h1>
<Table>
  <TableHead>
    <TableRow>
      {/* <TableCell>SW IF INDEX</TableCell> */}
      <TableCell>Action</TableCell>
      <TableCell>Source Port</TableCell>
      <TableCell>Interface type</TableCell>
      <TableCell>Destination Port</TableCell>
      <TableCell>Source</TableCell>
      <TableCell>Destination</TableCell>
      <TableCell>Protocol</TableCell>
      <TableCell></TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {acl_statusout
      .filter(user => user.int_type === "lan") // Filter rows with interface type "lan"
      .sort((a, b) => a.acl_index - b.acl_index) // Sort the filtered array by acl_index
      .map((user) => (
        <TableRow key={user.acl_index}>
        {/* <TableCell>{user.sw_if_index}</TableCell> */}
        <TableCell>{user.action}</TableCell>
          <TableCell>{user.src_port}</TableCell>
          <TableCell>{user.int_type}</TableCell>
          <TableCell>{user.dst_port}</TableCell>
          <TableCell>{user.source}</TableCell>
          <TableCell>{user.destination}</TableCell>
          <TableCell>{user.protocol}</TableCell>
          <TableCell>
            <Button onClick={() => upaclrule("outbound",user.sw_if_index, user.acl_index)} className="btn btn-primary">Up</Button>
            <Button onClick={() => downaclrule("outbound",user.sw_if_index, user.acl_index)} className="btn btn-primary">Down</Button>
            <Button onClick={() => deleteacl("outbound",user.sw_if_index, user.acl_index)} className="btn btn-primary">Delete</Button>
          </TableCell>
        </TableRow>
      ))}
  </TableBody>
</Table>
  <h1>WAN Interface OutBound Rules</h1>
<Table>
  <TableHead>
    <TableRow>
      {/* <TableCell>SW IF INDEX</TableCell> */}
      <TableCell>Action</TableCell>
      <TableCell>Source Port</TableCell>
      <TableCell>Interface type</TableCell>
      <TableCell>Destination Port</TableCell>
      <TableCell>Source</TableCell>
      <TableCell>Destination</TableCell>
      <TableCell>Protocol</TableCell>
      <TableCell></TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {acl_statusout
      .filter(user => user.int_type === "wan") // Filter rows with interface type "lan"
      .sort((a, b) => a.acl_index - b.acl_index) // Sort the filtered array by acl_index
      .map((user) => (
        <TableRow key={user.acl_index}>
        {/* <TableCell>{user.sw_if_index}</TableCell> */}
        <TableCell>{user.action}</TableCell>
          <TableCell>{user.src_port}</TableCell>
          <TableCell>{user.int_type}</TableCell>
          <TableCell>{user.dst_port}</TableCell>
          <TableCell>{user.source}</TableCell>
          <TableCell>{user.destination}</TableCell>
          <TableCell>{user.protocol}</TableCell>
          <TableCell>
            <Button onClick={() => upaclrule("outbound",user.sw_if_index, user.acl_index)} className="btn btn-primary">Up</Button>
            <Button onClick={() => downaclrule("outbound",user.sw_if_index, user.acl_index)} className="btn btn-primary">Down</Button>
            <Button onClick={() => deleteacl("outbound",user.sw_if_index, user.acl_index)} className="btn btn-primary">Delete</Button>
          </TableCell>
        </TableRow>
      ))}
  </TableBody>
</Table>
 
  </div>
</Box>

      <Box hidden={currentTab !== 'outbound_aclrule'}>
        <Typography variant="h4">OutBound Rule</Typography>
        <FormControl style={{ width: '50%' }}>
  <InputLabel id="demo-simple-select-label">Action</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="action"
    name="action"
      style={{ textAlign: 'center' }} 
    label="action"
    onChange={onValueChange}
  >
    <MenuItem value='permit'>Permit</MenuItem>
    <MenuItem value='deny'>deny</MenuItem>
    <MenuItem value='permit+reflect'>permit+reflect</MenuItem>
  </Select>
</FormControl><br />
    <FormControl>
      <InputLabel>Source</InputLabel>
      <Input
        name="source"
        value={sourceIp}
        onChange={event => {
          handleSourceIpChange(event);
          onValueChange(event); // Call the onValueChange function
        }}
        error={!isValidSourceIp}
      />
    </FormControl>
    <FormControl>
      <InputLabel>Destination</InputLabel>
      <Input
        name="destination"
        value={DestIp}
        onChange={event => {
          handleSourcedestChange(event);
          onValueChange(event); // Call the onValueChange function
        }}
        error={!isValidDestIp}
      />
    </FormControl>
    <FormControl>
      <InputLabel>Source Port</InputLabel>
      <Input
        name="src_port"
        value={sourcePort}
        onChange={event => {
          handleSPChange(event);
          onValueChange(event); // Call the onValueChange function
        }}
        error={!isValidSourcePort}
        helperText={
          !isValidSourcePort
            ? 'Invalid source port. Please enter a valid port or range (e.g., "a" or "a-b" where a & b are between 0 and 65535 and b > a)'
            : ''
        }
      />
    </FormControl>
    
    <FormControl>
      <InputLabel>Destination Port</InputLabel>
      <Input
        name="destination_port"
        value={destinationPort}
        onChange={event => {
          handleChange(event);
          onValueChange(event);
        }}
        error={!isValidDestinationPort}
        helperText={
          !isValidDestinationPort
            ? 'Invalid destination port. Please enter a valid port or range (e.g., "a" or "a-b" where a & b are between 0 and 65535 and b > a)'
            : ''
        }
      />
    </FormControl><br />
        <FormControl style={{ width: '50%' }}>
  <InputLabel id="demo-simple-select-label">Protocol</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="protocol"
    name="protocol"
    label="protocol"
      style={{ textAlign: 'center' }} 
    onChange={onValueChange}
  >
    <MenuItem value='HOPOPT'>HOPOPT</MenuItem>
    <MenuItem value='ICMP'>ICMP</MenuItem>
    <MenuItem value='IGMP'>IGMP</MenuItem>
    <MenuItem value='TCP'>TCP</MenuItem>
    <MenuItem value='UDP'>UDP</MenuItem>
    <MenuItem value='GRE'>GRE</MenuItem>
    <MenuItem value='ESP'>ESP</MenuItem>
    <MenuItem value='AH'>AH</MenuItem>
    <MenuItem value='ICMP6'>ICMP6</MenuItem>
    <MenuItem value='EIGRP'>EIGRP</MenuItem>
    <MenuItem value='OSPF'>OSPF</MenuItem>
    <MenuItem value='SCTP'>SCTP</MenuItem>
  </Select>
</FormControl><br />
        <FormControl style={{ width: '50%' }}>
        <InputLabel  id="interface-select-label">Int Index </InputLabel>
        <Select name="int_index" 
        labelId="interface-select-label"
        id="interface-select"
        value={selectedIndex}
        onChange={onValueChange}
      style={{ textAlign: 'center' }} 
        >
          {interfc?.map((user) => (
        <MenuItem value={user.int_index} key={user.int_index}>
          {user.type}
        </MenuItem>
      ))}
        </Select>
        </FormControl>
        <FormControl>
          <Button variant="contained" onClick={postoutbound_acl} disabled={!isoutFormValid}>
            Create
          </Button>
        </FormControl>
      </Box>
      <Box hidden={currentTab !== 'Inbound_aclrule'}>
        <Typography variant="h4">InBound Rule</Typography>
        <FormControl style={{ width: '50%' }}>
  <InputLabel id="demo-simple-select-label">Action</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="action"
    name="action"
    label="action"
      style={{ textAlign: 'center' }} 
    onChange={onValueChange}
  >
    <MenuItem value='permit'>Permit</MenuItem>
    <MenuItem value='deny'>deny</MenuItem>
    <MenuItem value='permit+reflect'>permit+reflect</MenuItem>
  </Select>
</FormControl><br />
    <FormControl>
      <InputLabel>Source</InputLabel>
      <Input
        name="source"
        value={sourceIp}
        onChange={event => {
          handleSourceIpChange(event);
          onValueChange(event); // Call the onValueChange function
        }}
        error={!isValidSourceIp}
      />
    </FormControl>
    <FormControl>
      <InputLabel>Destination</InputLabel>
      <Input
        name="destination"
        value={DestIp}
        onChange={event => {
          handleSourcedestChange(event);
          onValueChange(event); // Call the onValueChange function
        }}
        error={!isValidDestIp}
      />
    </FormControl>
    <FormControl>
      <InputLabel>Source Port</InputLabel>
      <Input
        name="src_port"
        value={sourcePort}
        onChange={event => {
          handleSPChange(event);
          onValueChange(event); // Call the onValueChange function
        }}
        error={!isValidSourcePort}
        helperText={
          !isValidSourcePort
            ? 'Invalid source port. Please enter a valid port or range (e.g., "a" or "a-b" where a & b are between 0 and 65535 and b > a)'
            : ''
        }
      />
    </FormControl>
    
    <FormControl>
      <InputLabel>Destination Port</InputLabel>
      <Input
        name="destination_port"
        value={destinationPort}
        onChange={event => {
          handleChange(event);
          onValueChange(event);
        }}
        error={!isValidDestinationPort}
        helperText={
          !isValidDestinationPort
            ? 'Invalid destination port. Please enter a valid port or range (e.g., "a" or "a-b" where a & b are between 0 and 65535 and b > a)'
            : ''
        }
      />
    </FormControl><br />
        <FormControl style={{ width: '50%' }}>
  <InputLabel id="demo-simple-select-label">Protocol</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="protocol"
    name="protocol"
    label="protocol"
      style={{ textAlign: 'center' }} 
    onChange={onValueChange}
  >
    <MenuItem value='HOPOPT'>HOPOPT</MenuItem>
    <MenuItem value='ICMP'>ICMP</MenuItem>
    <MenuItem value='IGMP'>IGMP</MenuItem>
    <MenuItem value='TCP'>TCP</MenuItem>
    <MenuItem value='UDP'>UDP</MenuItem>
    <MenuItem value='GRE'>GRE</MenuItem>
    <MenuItem value='ESP'>ESP</MenuItem>
    <MenuItem value='AH'>AH</MenuItem>
    <MenuItem value='ICMP6'>ICMP6</MenuItem>
    <MenuItem value='EIGRP'>EIGRP</MenuItem>
    <MenuItem value='OSPF'>OSPF</MenuItem>
    <MenuItem value='SCTP'>SCTP</MenuItem>
  </Select>
</FormControl><br />
        <FormControl style={{ width: '50%' }}>
        <InputLabel  id="interface-select-label">Int Index </InputLabel>
        <Select name="int_index" 
        labelId="interface-select-label"
        id="interface-select"
        value={selectedIndex}
      style={{ textAlign: 'center' }} 
        onChange={onValueChange}
        >
          {interfc?.map((user) => (
        <MenuItem value={user.int_index} key={user.int_index}  >
          {user.type}
        </MenuItem>
      ))}
        </Select>
        </FormControl>
        <FormControl>
          <Button variant="contained" onClick={postinbound_acl} disabled={!isinboundFormValid}>
            Add Inbound Rule
          </Button>
        </FormControl>
      </Box>
      
      <Box hidden={currentTab !== 'qos_policy_status'}>
      <div className="routing">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>policy_name</TableCell>
                <TableCell>DHCP</TableCell> 
                <TableCell>Match Destination Ip</TableCell> 
                <TableCell>Match Destination Port</TableCell> 
                <TableCell>Protocol</TableCell> 
              </TableRow>
            </TableHead>
            <TableBody>
              {qos_policy_status?.map((user) => (
                <TableRow key={user.policy_name}>
                  <TableCell>{user.policy_name}</TableCell>
                  <TableCell>{user.dscp}</TableCell>
                  <TableCell>{user.match_dst_ip}</TableCell>
                  <TableCell>{user.match_dst_port}</TableCell>
                  <TableCell>{user.protocol}</TableCell>
                  <TableCell>
                <Button onClick={() => deleteqos(user.policy_name,user.dscp,user.match_dst_ip,user.match_dst_port,user.protocol )} className="btn btn-primary">Delete</Button></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Box>
      <Box hidden={currentTab !== 'qos_policy'}>
        <Typography variant="h4">QOS Policy</Typography>
        <FormControl style={{ width: '50%' }}>
  <InputLabel id="demo-simple-select-label">Policy Name</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="policy_name"
    name="policy_name"
    label="policy_name"
      style={{ textAlign: 'center' }} 
    onChange={onValueChange}
  >
    <MenuItem value='voice_traffic'>Voice Traffic</MenuItem>
    <MenuItem value='ERP_traffic'>Erp Traffic</MenuItem>
    <MenuItem value='Management_traffic'>Management Traffic</MenuItem>
  </Select>
</FormControl><br />
        {/* <FormControl fullWidth>
        <InputLabel  id="interface-select-label">Int Index </InputLabel>
        <Select name="int_index" 
        labelId="interface-select-label"
        id="interface-select"
        value={selectedIndex}
        onChange={onValueChange}
        >
          {interfc?.map((user) => (
        <MenuItem value={user.int_index} key={user.int_index}>
          {user.type}
        </MenuItem>
      ))}
        </Select>
        </FormControl> */}
        
    <FormControl>
      <InputLabel>Match Destination IP</InputLabel>
      <Input
        name="match_dst_ip"
        value={matchSourceIP}
        onChange={event => {
          handleMatchSourceIPChange(event);
          onValueChange(event); // Call the onValueChange function
        }}
        error={!isValidMatchSourceIP}
        helperText={
          !isValidMatchSourceIP
            ? 'Invalid source IP. Please enter a valid IP address in the format "a.b.c.d", "a.b.0.0", or "a.0.0.0" where a is 10, 172, or 192 with appropriate ranges for b, c, and d.'
            : ''
        }
      />
    </FormControl>
    <FormControl>
      <InputLabel>Match Destination Port</InputLabel>
      <Input
        name="match_dst_port"
        type='number'
        onChange={event => {
          onValueChange(event); // Call the onValueChange function
        }}
      />
    </FormControl><br />
    
    <FormControl style={{ width: '50%' }}>
  <InputLabel id="demo-simple-select-label">Protocol</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="protocol"
    name="protocol"
    label="protocol"
      style={{ textAlign: 'center' }} 
    onChange={onValueChange}
  >
    <MenuItem value='TCP'>TCP</MenuItem>
    <MenuItem value='UDP'>UDP</MenuItem>
  </Select>
</FormControl>  
        <FormControl>
          <Button variant="contained" onClick={postqos_policy} disabled={!isQOSFormValid}>
            Add QOS Policy
          </Button>
        </FormControl>
      </Box>



      

      <Box hidden={currentTab !== 'portforwarding'}>
  <Typography variant="h4">Port Forwarding</Typography>
  <FormControl style={{ width: '50%' }}>
    <InputLabel id="interface-select-label">Wan Index</InputLabel>
    <Select
      name="int_index"
      labelId="interface-select-label"
      id="interface-select"
      style={{ textAlign: 'center' }} 
      value={selectedIndex}
      onChange={onValueChange}
    >
      {interfc?.map((user) => (
        <MenuItem value={user.int_index} key={user.int_index}>
          {user.type}
        </MenuItem>
      ))}
    </Select>
  </FormControl><br />
  
  <FormControl>
      <InputLabel>Internal IP Address</InputLabel>
      <Input
        name="internal_ip"
        value={IpAddress}
        onChange={event => {
          handleIpAddressChange(event);
          onValueChange(event); // Call the onValueChange function
        }}
        error={!isValidIpAddress}
      />
    </FormControl>

<FormControl>
  <InputLabel>Protocol</InputLabel>
  <Select
    id="protocols"
      style={{ textAlign: 'center' }} 
    name="protocols"
    multiple
    value={selectedProtocols.length > 0 ? selectedProtocols : ['tcp', 'udp']}
    onChange={event => {
      setSelectedProtocols(event.target.value);
      onValueChange(event); // Call the onValueChange function
    }}
    input={<Input />}
    renderValue={selected => selected.join(', ')}
  >
    <MenuItem value="any">any</MenuItem>
    <MenuItem value="icmp">icmp</MenuItem>
    <MenuItem value="tcp">tcp</MenuItem>
    <MenuItem value="udp">udp</MenuItem>
  </Select>
</FormControl>


<FormControl>
      <InputLabel>ports</InputLabel>
      <Input
        name="ports"
        value={portsInput}
        placeholder="440-444" 
        onChange={event => {
          handlePortsInputChange(event);
          onValueChange(event); // Call the onValueChange function
        }}
        error={!isValidPortsInput}
      />
    </FormControl>
    <FormControl>
      <InputLabel>Internal Port start</InputLabel>
      <Input
        name="internal_port_start"
        value={internalPortStart}
        placeholder="440" 
        type='number'
        onChange={event => {
          handleInternalPortStartChange(event);
          onValueChange(event); // Call the onValueChange function
        }}
        error={!isValidInternalPortStart}
      />
    </FormControl>  
  <FormControl>
    <Button variant="contained" onClick={port_forward} 
          disabled={!isportFormValid} >
      Forward
    </Button>
  </FormControl>
</Box>

<Box hidden={currentTab !== 'static_mapping_status'}>
      <div className="routing">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Local Ip Address</TableCell>
                <TableCell>External Ip Address</TableCell> 
                <TableCell>Protocol</TableCell> 
                <TableCell>Local Port</TableCell> 
                <TableCell>External Port</TableCell> 
                <TableCell></TableCell> 
              </TableRow>
            </TableHead>
            <TableBody>
              {gstatic_mapping_status?.map((user) => (
                <TableRow key={user.local_ip_address}>
                  <TableCell>{user.local_ip_address}</TableCell>
                  <TableCell>{user.external_ip_address}</TableCell>
                  <TableCell>{user.protocol}</TableCell>
                  <TableCell>{user.local_port}</TableCell>
                  <TableCell>{user.external_port}</TableCell>
                  <TableCell>
                <Button onClick={() => deleteport(user)} className="btn btn-primary">Delete</Button></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Box>



      <Box hidden={currentTab !== 'identity_mapping'}>
        <Typography variant="h4">1:1 Mapping </Typography>
        <FormControl style={{ width: '50%' }}>
        <InputLabel  id="interface-select-label">Wan Index </InputLabel>
        <Select name="int_index" 
        labelId="interface-select-label"
        id="interface-select"
        value={selectedIndex}
        onChange={onValueChange}
      style={{ textAlign: 'center' }} 
        >
          {interfc?.map((user) => (
        <MenuItem value={user.int_index} key={user.int_index}>
          {user.type}
        </MenuItem>
      ))}
        </Select>
        </FormControl><br />
  
    <FormControl>
      <InputLabel>Internal IP Address</InputLabel>
      <Input
        name="internal_ip_address"
        value={IpAddress}
        onChange={event => {
          handleIpAddressChange(event);
          onValueChange(event); // Call the onValueChange function
        }}
        error={!isValidIpAddress}
      />
    </FormControl>
        <FormControl>
          <Button variant="contained" onClick={identity_mapping}  disabled={!isValidIpAddress}>
            Forward
          </Button>
        </FormControl>
      </Box>



      <Box hidden={currentTab !== 'identity_mapping_status'}>
      <div className="routing">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Local Ip Address</TableCell>
                <TableCell>External Ip Address</TableCell> 
                <TableCell>Nat Type</TableCell> 
              </TableRow>
            </TableHead>
            <TableBody>
              {gidentity_mapping_status?.map((user) => (
                <TableRow key={user.local_ip_address}>
                  <TableCell>{user.local_ip_address}</TableCell>
                  <TableCell>{user.external_ip_address}</TableCell>
                  <TableCell>{user.nat_type}</TableCell>
                  <TableCell>
                <Button onClick={() => deletestatic_mapping(user)} className="btn btn-primary">Delete</Button></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Box>
    </Container>
    </div></div>
  );
}

export default GeneralInfo;
