import {React, useState, ChangeEvent} from 'react'
import {FormGroup, FormControl, Input, InputLabel,NativeSelect, Typography, styled, Button,FormControlLabel, Switch, FormLabel, FormHelperText} from "@mui/material"
import { generateToken } from '../../service/api' 
import { useNavigate } from 'react-router-dom'




const Container = styled(FormGroup)`
    width: 350%;
    margin: 5% auto 0 100%;
    & > div{
        margin-top: 20px;
    }


`


function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const token = makeid(64);

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = d.getHours(),
        minutes = d.getMinutes();


    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

        const dd = [day,month, year].join('-')
        const tt = [hours,minutes].join(':');

    return [dd, tt].join(' ');
}
const timeStamp = formatDate(Date());

const defaultValues = {
    organizationName: "",
    tokenName: "",
    token: token,
    timeStamp: timeStamp

}


function NewToken() {

    

    const navigate = useNavigate()
    
    const [user, setUser] = useState(defaultValues);

    const onValueChange = (e) =>{
        setUser({...user, [e.target.name]: e.target.value})
    }

    
    const getToken= async () =>{
        await generateToken(user)
         navigate('/inventory/tokens')
 
     }

  return (
    <Container fullWidth>
        <Typography variant='h4'>Generate New Token</Typography>
    <FormControl>
        <InputLabel>Token Name </InputLabel>
        <Input onChange={(e) => onValueChange(e)}  name="tokenName"/>
    </FormControl>
        <FormControl fullWidth>
             <InputLabel variant="standard" htmlFor="uncontrolled-native">
             Organization Name
             </InputLabel>
             <NativeSelect
                  defaultValue={1}
                     inputProps={{
                      name: 'organizationName',
                      id: 'uncontrolled-native',
                     }}
                     onChange={(e) => onValueChange(e)}  >
               <option value={1}>Demo</option>
               <option value={2}>Demo1</option>
               <option value={3}>Demo2</option>
             </NativeSelect>
        </FormControl>

        <FormControl>
        <Button variant="contained" onClick={() => getToken()}>Generate Token</Button>
        </FormControl>
    </Container>
  )
}

export default NewToken