import React, { useEffect, useState } from "react";
import "./device.css";
import { getallvxlantunnel } from "../../service/api.js";
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

function Tunnel() {
  const [vxlanTunnelDetails, setVxlanTunnelDetails] = useState([]);

  useEffect(() => {
    getVxlanTunnelDetails();
  }, []);

  const getVxlanTunnelDetails = async () => {
    try {
      const response = await getallvxlantunnel();
      const combinedData = Object.values(response.data).flat(); // Flattening the response object into an array
      setVxlanTunnelDetails(combinedData);
    } catch (error) {
      console.log('Error while getting VXLAN tunnel details:', error);
    }
  };

  return (
    <div className="routing">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Source Address</TableCell>
            <TableCell>Destination Address</TableCell>
            <TableCell>Source Loopback Address</TableCell>
            <TableCell>Destination Loopback Address</TableCell>
            <TableCell>GRE Bridge Id</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Encryption</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vxlanTunnelDetails.map((tunnel, index) => (
            <TableRow key={index}>
              <TableCell>{tunnel.src_address}</TableCell>
              <TableCell>{tunnel.dst_address}</TableCell>
              <TableCell>{tunnel.src_loopback_addr}</TableCell>
              <TableCell>{tunnel.dst_loopback_addr}</TableCell>
              <TableCell>{tunnel.gre_bridge_id}</TableCell>
              <TableCell>{tunnel.status}</TableCell>
              <TableCell>{tunnel.encrypt}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default Tunnel;
