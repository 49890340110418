import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  LinearProgress,
} from "@material-ui/core";
import { postqos_policy_add } from "../../service/api";
import { useNavigate, useParams, Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Nav from "../Home/Nav";
import { Box } from "@mui/material";

const AddQosPolicy = () => {
  const [qos_policy, setqos_policy] = useState({
    policy_name: "none",
    int_index: 2,
    inbound: "none",
    match_dst_port: "none",
  });

  useEffect(() => {}, []);

  const { id } = useParams();
  const navigate = useNavigate();

  const [progress, setProgress] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [isValidMatchSourceIP, setIsValidMatchSourceIP] = useState(true);
  const [matchSourceIP, setMatchSourceIP] = useState("");
  const [isValidMatchDestIP, setIsValidMatchDestIP] = useState(true);
  const [matchDestIP, setMatchDestIP] = useState("");

  const onValueChange = (e) => {
    const { name, value } = e.target;
    console.log(e.target);

    setqos_policy({ ...qos_policy, [name]: value });

    if (name === "int_index") {
      setSelectedIndex(value);
    }
  };

  const handleMatchSourceIPChange = (event) => {
    validateMatchSourceIP(event.target.value);
  };

  const validateMatchSourceIP = (value) => {
    if (validateIp3(value)) {
      setIsValidMatchSourceIP(true);
    } else {
      setIsValidMatchSourceIP(false);
    }
    setMatchSourceIP(value);
  };

  const validateIp3 = (ipAddress) => {
    const parts = ipAddress.split(".");
    if (parts.length !== 4) {
      return false;
    }

    for (let i = 0; i < parts.length; i++) {
      const part = parseInt(parts[i]);
      if (isNaN(part) || part < 0 || part > 255) {
        return false;
      }
    }
    return true;
  };

  const handleMatchDestIPChange = (event) => {
    validateMatchDestIP(event.target.value);
  };

  const validateMatchDestIP = (value) => {
    const ipPattern =
      /^(10(\.\d{1,3}){3}|172\.(1[6-9]|2\d|3[0-1])(\.\d{1,3}){2}|192\.168(\.\d{1,3}){2})$/;

    if (!ipPattern.test(value)) {
      setIsValidMatchDestIP(false);
    } else {
      const [a, b, c, d] = value.split(".").map(Number);

      if (
        a === 10 &&
        b >= 0 &&
        b <= 255 &&
        c >= 0 &&
        c <= 255 &&
        d >= 0 &&
        d <= 255
      ) {
        setIsValidMatchDestIP(true);
      } else if (
        a === 172 &&
        b >= 16 &&
        b <= 31 &&
        c >= 0 &&
        c <= 255 &&
        d >= 0 &&
        d <= 255
      ) {
        setIsValidMatchDestIP(true);
      } else if (
        a === 192 &&
        b === 168 &&
        c >= 0 &&
        c <= 255 &&
        d >= 0 &&
        d <= 255
      ) {
        setIsValidMatchDestIP(true);
      } else {
        setIsValidMatchDestIP(false);
      }
    }

    setMatchDestIP(value);
  };

  const postqos_policy = async () => {
    try {
      setProgress(0);
      const response = await postqos_policy_add(qos_policy, id);
      const progressPercentage = response.data.progress;
      console.log("progresspercent", progressPercentage);
      setProgress(progressPercentage);
      displayAlert("Data posted successfully!", "alert-success");
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert("Error posting data. Please try again.", "alert-error");
    }
  };

  const isQOSFormValid = isValidMatchSourceIP && isValidMatchDestIP;

  const displayAlert = (message, className, hidden = false) => {
    const alertElement = document.getElementById("alertMessage");
    alertElement.textContent = message;
    alertElement.classList.add(className);
    alertElement.style.display = hidden ? "none" : "block";
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Nav name="Add Qos Policy" />
      </Grid>

      <Grid item xs={12} md={2}>
        <Paper style={{ overflowY: "auto" }}>
          <Sidebar />
        </Paper>
      </Grid>

      <Grid item xs={12} md={8}>
        <Paper style={{ padding: "8%" }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <FormControl style={{ marginRight: '10px', flex: 1 }}>
                <InputLabel id="demo-simple-select-label">
                  Policy Name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="policy_name"
                  name="policy_name"
                  label="policy_name"
                  style={{
                    minWidth: "200px",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    overflow: "auto",
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: 300 },
                    },
                  }}
                  onChange={onValueChange}
                >
                  <MenuItem value="voice_traffic">Voice Traffic</MenuItem>
                  <MenuItem value="ERP_traffic">Erp Traffic</MenuItem>
                  <MenuItem value="Management_traffic">
                    Management Traffic
                  </MenuItem>
                </Select>
              </FormControl>

              <FormControl style={{ marginRight: '10px', flex: 1 }}>
                <InputLabel id="demo-simple-select-label">Protocol</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="protocol"
                  name="protocol"
                  label="protocol"
                  style={{
                    minWidth: "200px",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    overflow: "auto",
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: 300 },
                    },
                  }}
                  onChange={onValueChange}
                >
                  <MenuItem value="TCP">TCP</MenuItem>
                  <MenuItem value="UDP">UDP</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              {/* <FormControl fullWidth>
        <InputLabel  id="interface-select-label">Int Index </InputLabel>
        <Select name="int_index" 
        labelId="interface-select-label"
        id="interface-select"
        value={selectedIndex}
        onChange={onValueChange}
        >
          {interfc?.map((user) => (
        <MenuItem value={user.int_index} key={user.int_index}>
          {user.type}
        </MenuItem>
      ))}
        </Select>
        </FormControl> */}

              <FormControl style={{ marginRight: '10px', flex: 1 }}>
                <InputLabel>Match Destination IP</InputLabel>
                <Input
                  name="match_dst_ip"
                  value={matchSourceIP}
                  onChange={(event) => {
                    handleMatchSourceIPChange(event);
                    onValueChange(event); // Call the onValueChange function
                  }}
                  error={!isValidMatchSourceIP}
                  helperText={
                    !isValidMatchSourceIP
                      ? 'Invalid source IP. Please enter a valid IP address in the format "a.b.c.d", "a.b.0.0", or "a.0.0.0" where a is 10, 172, or 192 with appropriate ranges for b, c, and d.'
                      : ""
                  }
                />
              </FormControl >
              <FormControl style={{ marginRight: '10px', flex: 1 }}>
                <InputLabel>Match Destination Port</InputLabel>
                <Input
                  name="match_dst_port"
                  type="number"
                  onChange={(event) => {
                    //   handleMatchDestIPChange(event);
                    onValueChange(event); // Call the onValueChange function
                  }}
                />
              </FormControl>
            </div>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <FormControl style={{ marginRight: '10px', flex: 1 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={postqos_policy}
                  disabled={!isQOSFormValid}
                >
                  Add QOS Policy
                </Button>
              </FormControl>
              
            </div>
          </Box>
            <div id="alertMessage" className="alert" hidden = "true"></div>
            <LinearProgress variant="determinate" value={progress} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AddQosPolicy;
