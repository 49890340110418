import React, { useEffect, useState } from "react";
import { Table, TableHead, TableRow, TableCell, TableBody, Button, Paper,Grid, Typography, LinearProgress } from "@material-ui/core";
import {getroutingtable
} from '../../service/api';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Sidebar from "./Sidebar";
import Nav from "../Home/Nav";

const RoutingInfo = () => {
  useEffect(() => {
    getRoutingTable();
  }, []);

  const { id } = useParams();
  const navigate = useNavigate();

  const [routingtable, setRoutingTable] = useState([]);
  const [progress, setProgress] = useState(0);


  
  const getRoutingTable = async () => {
    try {
      const response = await getroutingtable(id);
      setRoutingTable(response.data);
    } catch (error) {
      console.log('Error while getting routing table data:', error);
    }
  };


const displayAlert = (message, className, hidden = false) => {
  const alertElement = document.getElementById('alertMessage');
  alertElement.textContent = message;
  alertElement.classList.add(className);
  alertElement.style.display = hidden ? 'none' : 'block';
};

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Nav name="Routing Information" />
      </Grid>

      <Grid item xs={12} md={2}>
        <Paper style={{ overflowY: 'auto' }}>
          <Sidebar />
        </Paper>
      </Grid>

      <Grid item xs={12} md={8}>
        <Paper style={{ padding: "8%" }}>
            <div id="alertMessage" className="alert" hidden = "true"></div>
            <LinearProgress variant="determinate" value={progress} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Destination</TableCell>
                <TableCell>Gateway</TableCell>
                <TableCell>Interface</TableCell>
                <TableCell>Metric</TableCell>
                <TableCell>Protocol</TableCell>
                {/* <TableCell></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {routingtable?.map((user) => (
                <TableRow key={user.destination}>
                  <TableCell>{user.destination}</TableCell>
                  <TableCell>{user.gateway}</TableCell>
                  <TableCell>{user.interface_name}</TableCell>
                  <TableCell>{user.metric}</TableCell>
                  <TableCell>{user.protocol}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
    </Paper>
      </Grid>
    </Grid>
  );
};

export default RoutingInfo;
