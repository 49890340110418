import React, { useEffect, useState } from "react";
import { Table, TableHead, TableRow, TableCell, TableBody, Button, Paper,Grid, Typography, LinearProgress } from "@material-ui/core";
import {getacl_status,upaclrule_add,downaclrule_add, aclrule_delete, getacl_statusout
} from '../../service/api';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Sidebar from "./Sidebar";
import Nav from "../Home/Nav";

const FirewallStatus = () => {
  useEffect(() => {
    getAcl_status();
    getAcl_statusout();
  }, []);

  const { id } = useParams();
  const navigate = useNavigate();

  const [progress, setProgress] = useState(0);
  const [acl_status, setacl_status] = useState([]);
  const [acl_statusout, setacl_statusout] = useState([]);


  
  const getAcl_status = async () => {
    try {
      const response = await getacl_status(id);
      setacl_status(response.data);
    } catch (error) {
      console.log('Error while getting routing table data:', error);
    }
  };

  const getAcl_statusout = async () => {
    try {
      const response = await getacl_statusout(id);
      setacl_statusout(response.data);
    } catch (error) {
      console.log('Error while getting routing table data:', error);
    }
  };

  const upaclrule = async (rule_type, sw_if_index, data) => {
    try {
      const value ={rule_type :rule_type,sw_if_index:sw_if_index ,acl_index : data}
      setProgress(0);
      const response =await upaclrule_add(value, id);
      const progressPercentage = response.data.progress;
      setProgress(progressPercentage);
      console.log('progresspercent',progressPercentage);
      displayAlert('Firewall RULE UP', 'alert-success');
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  }


  
  const downaclrule = async (rule_type,sw_if_index,data) => {
    try {
      const value ={rule_type :rule_type,sw_if_index:sw_if_index ,acl_index : data}
      setProgress(0);
      const response =await downaclrule_add(value, id);
      const progressPercentage = response.data.progress;
      console.log('progresspercent',progressPercentage);
      setProgress(progressPercentage);
      displayAlert('Firewall RULE Down', 'alert-success');
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  }


  
  const deleteacl = async (rule_type,sw_if_index,data) => {
    try {
      const value ={rule_type :rule_type,sw_if_index:sw_if_index ,acl_index : data}
      setProgress(0);
      const response =await aclrule_delete(value, id);
      const progressPercentage = response.data.progress;
      console.log('progresspercent',progressPercentage);
      setProgress(progressPercentage);
      displayAlert('Selected Firewall RULE Deleted', 'alert-danger');
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  }



const displayAlert = (message, className, hidden = false) => {
  const alertElement = document.getElementById('alertMessage');
  alertElement.textContent = message;
  alertElement.classList.add(className);
  alertElement.style.display = hidden ? 'none' : 'block';
};

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Nav name="Firewall Status" />
      </Grid>

      <Grid item xs={2}>
        <Paper style={{ overflowY: 'auto' }}>
          <Sidebar />
        </Paper>
      </Grid>

      <Grid item xs={10}  >
  <div className="routing" style={{ marginTop: '8%' }}>
    
<Table style={{ padding: "8%" }}>
  <TableBody>
      <TableRow>
        <TableCell colSpan={7} align="center">
    <Typography variant="h6" align="center">
      LAN Interface Inbound Rules
    </Typography></TableCell>
        
      </TableRow></TableBody>
  <TableHead>
    <TableRow>
      {/* <TableCell>SW IF INDEX</TableCell> */}
      <TableCell>Action</TableCell>
      <TableCell>Source Port</TableCell>
      {/* <TableCell>Interface type</TableCell> */}
      <TableCell>Destination Port</TableCell>
      <TableCell>Source</TableCell>
      <TableCell>Destination</TableCell>
      <TableCell>Protocol</TableCell>
      <TableCell></TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {acl_status
      .filter(user => user.int_type === "lan") // Filter rows with interface type "lan"
      .sort((a, b) => a.acl_index - b.acl_index) // Sort the filtered array by acl_index
      .map((user) => (
        <TableRow key={user.acl_index}>
        {/* <TableCell>{user.sw_if_index}</TableCell> */}
        <TableCell>{user.action}</TableCell>
          <TableCell>{user.src_port}</TableCell>
          {/* <TableCell>{user.int_type}</TableCell> */}
          <TableCell>{user.dst_port}</TableCell>
          <TableCell>{user.source}</TableCell>
          <TableCell>{user.destination}</TableCell>
          <TableCell>{user.protocol}</TableCell><TableCell>
  <Button
    variant="contained"
    color="primary"
    onClick={() => upaclrule("inbound", user.sw_if_index, user.acl_index)}
    className="btn btn-primary"
    style={{ marginRight: '8px' }} // Add margin to the right
  >
    Up
  </Button>
  <Button
    variant="contained"
    color="primary"
    onClick={() => downaclrule("inbound", user.sw_if_index, user.acl_index)}
    className="btn btn-primary"
    style={{ marginRight: '8px' }} // Add margin to the right
  >
    Down
  </Button>
  <Button
    variant="contained"
    color="secondary"
    onClick={() => deleteacl("inbound", user.sw_if_index, user.acl_index)}
    className="btn btn-primary"
    style={{ marginRight: '8px' }} // Add margin to the right
  >
    Delete
  </Button>
</TableCell>

        </TableRow>
      ))}

      <TableRow>
        <TableCell colSpan={7} align="center">
    <Typography variant="h6" align="center">
      WAN Interface Inbound Rules
    </Typography></TableCell>
        
      </TableRow>
      
    {acl_statusout
      .filter(user => user.int_type === "wan") // Filter rows with interface type "lan"
      .sort((a, b) => a.acl_index - b.acl_index) // Sort the filtered array by acl_index
      .map((user) => (
        <TableRow key={user.acl_index}>
        {/* <TableCell>{user.sw_if_index}</TableCell> */}
        <TableCell>{user.action}</TableCell>
          <TableCell>{user.src_port}</TableCell>
          {/* <TableCell>{user.int_type}</TableCell> */}
          <TableCell>{user.dst_port}</TableCell>
          <TableCell>{user.source}</TableCell>
          <TableCell>{user.destination}</TableCell>
          <TableCell>{user.protocol}</TableCell><TableCell>
  <Button
    variant="contained"
    color="primary"
    onClick={() => upaclrule("inbound", user.sw_if_index, user.acl_index)}
    className="btn btn-primary"
    style={{ marginRight: '8px' }} // Add margin to the right
  >
    Up
  </Button>
  <Button
    variant="contained"
    color="primary"
    onClick={() => downaclrule("inbound", user.sw_if_index, user.acl_index)}
    className="btn btn-primary"
    style={{ marginRight: '8px' }} // Add margin to the right
  >
    Down
  </Button>
  <Button
    variant="contained"
    color="secondary"
    onClick={() => deleteacl("inbound", user.sw_if_index, user.acl_index)}
    className="btn btn-primary"
  >
    Delete
  </Button>
</TableCell>

        </TableRow>
      ))}

      
      <TableRow>
        <TableCell colSpan={7} align="center">
    <Typography variant="h6" align="center">
      LAN Interface OutBound Rules
    </Typography></TableCell>
        
      </TableRow>
      
    {acl_statusout
      .filter(user => user.int_type === "lan") // Filter rows with interface type "lan"
      .sort((a, b) => a.acl_index - b.acl_index) // Sort the filtered array by acl_index
      .map((user) => (
        <TableRow key={user.acl_index}>
        {/* <TableCell>{user.sw_if_index}</TableCell> */}
        <TableCell>{user.action}</TableCell>
          <TableCell>{user.src_port}</TableCell>
          <TableCell>{user.dst_port}</TableCell>
          <TableCell>{user.source}</TableCell>
          <TableCell>{user.destination}</TableCell>
          <TableCell>{user.protocol}</TableCell><TableCell>
  <Button
    variant="contained"
    color="primary"
    onClick={() => upaclrule("outbound", user.sw_if_index, user.acl_index)}
    className="btn btn-primary"
    style={{ marginRight: '8px' }} // Add margin to the right
  >
    Up
  </Button>
  <Button
    variant="contained"
    color="primary"
    onClick={() => downaclrule("outbound", user.sw_if_index, user.acl_index)}
    className="btn btn-primary"
    style={{ marginRight: '8px' }} // Add margin to the right
  >
    Down
  </Button>
  <Button
    variant="contained"
    color="secondary"
    onClick={() => deleteacl("outbound", user.sw_if_index, user.acl_index)}
    className="btn btn-primary"
    style={{ marginRight: '8px' }} // Add margin to the right
  >
    Delete
  </Button>
</TableCell>

        </TableRow>
      ))}
      
      <TableRow>
        <TableCell colSpan={7} align="center">
    <Typography variant="h6" align="center">
      WAN Interface OutBound Rules
    </Typography></TableCell>
        
      </TableRow>
      
    {acl_statusout
      .filter(user => user.int_type === "wan") // Filter rows with interface type "lan"
      .sort((a, b) => a.acl_index - b.acl_index) // Sort the filtered array by acl_index
      .map((user) => (
        <TableRow key={user.acl_index}>
        {/* <TableCell>{user.sw_if_index}</TableCell> */}
        <TableCell>{user.action}</TableCell>
          <TableCell>{user.src_port}</TableCell>
          <TableCell>{user.dst_port}</TableCell>
          <TableCell>{user.source}</TableCell>
          <TableCell>{user.destination}</TableCell>
          <TableCell>{user.protocol}</TableCell><TableCell>
  <Button
    variant="contained"
    color="primary"
    onClick={() => upaclrule("outbound", user.sw_if_index, user.acl_index)}
    className="btn btn-primary"
    style={{ marginRight: '8px' }} // Add margin to the right
  >
    Up
  </Button>
  <Button
    variant="contained"
    color="primary"
    onClick={() => downaclrule("outbound", user.sw_if_index, user.acl_index)}
    className="btn btn-primary"
    style={{ marginRight: '8px' }} // Add margin to the right
  >
    Down
  </Button>
  <Button
    variant="contained"
    color="secondary"
    onClick={() => deleteacl("outbound", user.sw_if_index, user.acl_index)}
    className="btn btn-primary"
  >
    Delete
  </Button>
</TableCell>

        </TableRow>
      ))}
  </TableBody>
</Table>
 
  </div>
      </Grid>
    </Grid>
  );
};

export default FirewallStatus;
