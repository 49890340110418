import React, { useEffect, useState } from "react";
import {  Button, Paper,Grid, Typography, FormControl, InputLabel, Select, MenuItem, Input, LinearProgress } from "@material-ui/core";
import {getinterface, postinbound_aclrule
} from '../../service/api';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Sidebar from "./Sidebar";
import { Box } from "@mui/material";
import Nav from "../Home/Nav";

const InboundRule = () => {


 
  const [inbound_aclrule, setinbound_aclrule] = useState({
    action: 'none',
    source: 'none',
    destination: 'none',
    src_port: 'none',
    destination_port: 'none',
    protocol: 'none',
    int_index: 2,
  });


  useEffect(() => {
    getInterfc();
  }, []);

  const { id } = useParams();
  const navigate = useNavigate();

  const [progress, setProgress] = useState(0);
  const [interfc, setInterfc] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState('');
  const [sourceIp, setSourceIp] = useState('');
  const [isValidSourceIp, setIsValidSourceIp] = useState(true);
  const [DestIp, setDestIp] = useState('');
  const [isValidDestIp, setIsValidDestIp] = useState(true);
  const [isValidSourcePort, setIsValidSourcePort] = useState(true);
  const [sourcePort, setSourcePort] = useState('');
  const [isValidDestinationPort, setIsValidDestinationPort] = useState(true);
  const [destinationPort, setDestinationPort] = useState('');


  
  const getInterfc = async () => {
    try {
      const response = await getinterface(id);
      setInterfc(response.data);
      if (response.data) {
      }
    } catch (error) {
      console.log('Error while getting interface data:', error);
    }
  };

  const onValueChange = (e) => {
    
    const { name, value } = e.target;
    console.log(e.target);
  
    setinbound_aclrule({ ...inbound_aclrule, [name]: value });
  
    if (name === 'int_index') {
      setSelectedIndex(value);
    }
  };
  
  
  const handleSourceIpChange = (event) => {
    const ipAddress = event.target.value;
    setSourceIp(ipAddress);
    setIsValidSourceIp(validateIp2(ipAddress));
  };


  
  const validateIp2 = (ipAddress) => {
    const parts = ipAddress.split('/');
    if (parts.length !== 2) {
      return false;
    }

    const ipParts = parts[0].split('.');
    const maskPart = parts[1];

    if (ipParts.length !== 4) {
      return false;
    }

    for (let i = 0; i < ipParts.length; i++) {
      const part = ipParts[i];
      if (!/^\d+$/.test(part)) {
        return false;
      }
      const value = parseInt(part);
      if (value < 0 || value > 255) {
        return false;
      }
    }

    if (!/^\d+$/.test(maskPart)) {
      return false;
    }
    const maskValue = parseInt(maskPart);
    if (maskValue < 0 || maskValue > 32) {
      return false;
    }

    return true;
  };

  const handleSPChange = (event) => {
    validateSourcePort(event.target.value);
  };


  const validateSourcePort = (value) => {
    const portPattern = /^[0-9]+(-[0-9]+)?$/;

    if (!portPattern.test(value)) {
      setIsValidSourcePort(false);
    } else {
      const [a, b] = value.split('-').map(Number);

      if (a >= 0 && a <= 65535 && b >= 0 && b <= 65535 && b > a) {
        setIsValidSourcePort(true);
      } else {
        setIsValidSourcePort(false);
      }
    }
    setSourcePort(value);
  }; 
  const handleSourcedestChange = (event) => {
    const ipAddress = event.target.value;
    setDestIp(ipAddress);
    setIsValidDestIp(validateIp2(ipAddress));
  };

  

  const handleChange = (event) => {
    validateDestinationPort(event.target.value);
  };

  const validateDestinationPort = (value) => {
    const portPattern = /^[0-9]+(-[0-9]+)?$/;

    if (!portPattern.test(value)) {
      setIsValidDestinationPort(false);
    } else {
      const [a, b] = value.split('-').map(Number);

      if (a >= 0 && a <= 65535 && b >= 0 && b <= 65535 && b > a) {
        setIsValidDestinationPort(true);
      } else {
        setIsValidDestinationPort(false);
      }
    }
    setDestinationPort(value);
  };


  const postinbound_acl = async () => {
    try {
      setProgress(0);
      const response = await postinbound_aclrule(inbound_aclrule, id);
      const progressPercentage = response.data.progress;
      console.log('progresspercent',progressPercentage);
      setProgress(progressPercentage);
      displayAlert('Data posted successfully!', 'alert-success');
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  };
  
    const isinboundFormValid =
    isValidSourceIp && isValidDestIp  && isValidSourcePort && isValidDestinationPort;

const displayAlert = (message, className, hidden = false) => {
  const alertElement = document.getElementById('alertMessage');
  alertElement.textContent = message;
  alertElement.classList.add(className);
  alertElement.style.display = hidden ? 'none' : 'block';
};

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Nav name="Add Inbound Rule" />
      </Grid>

      <Grid item xs={12} md={2}>
        <Paper style={{ overflowY: 'auto' }}>
          <Sidebar />
        </Paper>
      </Grid>

      <Grid item xs={12} md={8}>
        <Paper style={{ padding: "8%" }}>
            
      
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
          
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
        <FormControl style={{ marginRight: '10px', flex: 1 }}>
  <InputLabel>Source</InputLabel>
  <Input
    name="source"
    value={sourceIp}
    onChange={event => {
      handleSourceIpChange(event);
      onValueChange(event); // Call the onValueChange function
    }}
    error={!isValidSourceIp}
  />
</FormControl>
<FormControl style={{ marginRight: '10px', flex: 1 }} >
  <InputLabel>Destination</InputLabel>
  <Input
    name="destination"
    value={DestIp}
    onChange={event => {
      handleSourcedestChange(event);
      onValueChange(event); // Call the onValueChange function
    }}
    error={!isValidDestIp}
  />
</FormControl>

<FormControl style={{ marginRight: '10px', flex: 1 }}>
  <InputLabel>Source Port</InputLabel>
  <Input
    name="src_port"
    value={sourcePort}
    onChange={event => {
      handleSPChange(event);
      onValueChange(event); // Call the onValueChange function
    }}
    error={!isValidSourcePort}
    helperText={
      !isValidSourcePort
        ? 'Invalid source port. Please enter a valid port or range (e.g., "a" or "a-b" where a & b are between 0 and 65535 and b > a)'
        : ''
    }
  />
</FormControl>
<FormControl style={{ marginRight: '10px', flex: 1 }}>
  <InputLabel>Destination Port</InputLabel>
  <Input
    name="destination_port"
    value={destinationPort}
    onChange={event => {
      handleChange(event);
      onValueChange(event);
    }}
    error={!isValidDestinationPort}
    helperText={
      !isValidDestinationPort
        ? 'Invalid destination port. Please enter a valid port or range (e.g., "a" or "a-b" where a & b are between 0 and 65535 and b > a)'
        : ''
    }
  />
</FormControl>
  </div>
  <div style={{ display: 'flex', marginBottom: '10px' }}>

<FormControl style={{ width: '50%', marginRight: '20px' }}>
  <InputLabel id="demo-simple-select-label">Action</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="action"
    name="action"
    label="action"
                  style={{
                    minWidth: "200px",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    overflow: "auto",
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: 300 },
                    },
                  }}
    onChange={onValueChange}
  >
    <MenuItem value='permit'>Permit</MenuItem>
    <MenuItem value='deny'>deny</MenuItem>
    <MenuItem value='permit+reflect'>permit+reflect</MenuItem>
  </Select>
</FormControl>
<FormControl style={{ width: '50%', marginRight: '20px' }}>
  <InputLabel id="demo-simple-select-label">Protocol</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="protocol"
    name="protocol"
    label="protocol"
                  style={{
                    minWidth: "200px",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    overflow: "auto",
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: 300 },
                    },
                  }}
    onChange={onValueChange}
  >
    <MenuItem value='HOPOPT'>HOPOPT</MenuItem>
    <MenuItem value='ICMP'>ICMP</MenuItem>
    <MenuItem value='IGMP'>IGMP</MenuItem>
    <MenuItem value='TCP'>TCP</MenuItem>
    <MenuItem value='UDP'>UDP</MenuItem>
    <MenuItem value='GRE'>GRE</MenuItem>
    <MenuItem value='ESP'>ESP</MenuItem>
    <MenuItem value='AH'>AH</MenuItem>
    <MenuItem value='ICMP6'>ICMP6</MenuItem>
    <MenuItem value='EIGRP'>EIGRP</MenuItem>
    <MenuItem value='OSPF'>OSPF</MenuItem>
    <MenuItem value='SCTP'>SCTP</MenuItem>
  </Select>
</FormControl>

        <FormControl style={{ width: '50%' }}>
        <InputLabel  id="interface-select-label">Int Index </InputLabel>
        <Select name="int_index" 
        labelId="interface-select-label"
        id="interface-select"
        value={selectedIndex}
                  style={{
                    minWidth: "200px",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    overflow: "auto",
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: 300 },
                    },
                  }}
        onChange={onValueChange}
        >
          {interfc?.map((user) => (
        <MenuItem value={user.int_index} key={user.int_index}  >
          {user.type}
        </MenuItem>
      ))}
        </Select>
        </FormControl>
  </div>

  <div style={{ display: 'flex', marginBottom: '10px' }}>
        <FormControl>
          <Button variant="contained" color="primary" onClick={postinbound_acl} disabled={!isinboundFormValid}>
            Add Inbound Rule
          </Button>
        </FormControl>
        
        </div>
        </Box>
            <div id="alertMessage" className="alert" hidden = "true"></div>
            <LinearProgress variant="determinate" value={progress} />
    
        </Paper>
      </Grid>
    </Grid>
  );
};

export default InboundRule;
