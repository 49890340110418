import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Paper,
  Grid,
  Typography,
} from "@material-ui/core";
import {
  check_vpp_installed,
  check_frr_installed,
  dataplane_install,
  send_file,
  controlplane_install,
  reach_install,
  getsystemchecker,
  getinterface,
} from "../../service/api";
import { useNavigate, useParams, Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Nav from "../Home/Nav";

const SystemChecker = () => {
  useEffect(() => {
    getsyschecker();
    getInterfc();
  }, []);

  const { id } = useParams();
  const navigate = useNavigate();

  const [systemchecker, setsystemchecker] = useState([]);
  const [fileSent, setFileSent] = useState(false);
  const [progress, setProgress] = useState(0);
  const [interfc, setInterfc] = useState([]);

  const getInterfc = async () => {
    try {
      const response = await getinterface(id);
      setInterfc(response.data);
      if (response.data) {
        setFileSent(true);
      }
    } catch (error) {
      console.log("Error while getting interface data:", error);
    }
  };
  const getsyschecker = async () => {
    try {
      const response = await getsystemchecker(id);
      console.log(response.data);
      setsystemchecker(response.data);
    } catch (error) {
      console.log("Error while getting system checker data:", error);
    }
  };

  const sendfile = async () => {
    try {
      setFileSent(true);
      console.log(systemchecker[0]);

      // Add your validation logic here

      setFileSent(true);
      const response = await send_file(id);

      if (response.status === 200) {
        setProgress(0);
        setFileSent(false); // Change this line to setFileSent(false) after sending the file
        const progressPercentage = response.data.progress;
        setProgress(progressPercentage);
        // Add your success message or logic here
        dataplan_install();
      } else {
        // Handle other response statuses or errors here
        // Add your error message or logic here
        navigate(`/devices`);
      }
    } catch (error) {
      // Add your error message or logic here
      navigate(`/devices`);
    }
  };

  const dataplan_install = async () => {
    try {
      setProgress(0);
      const response = await dataplane_install(id);
      const progressPercentage = response.data.progress;
      console.log("progresspercent", progressPercentage);
      setProgress(progressPercentage);
      // Add your success message or logic here
      controlplan_install();
    } catch (error) {
      // Add your error message or logic here
    }
  };

  const controlplan_install = async () => {
    try {
      setProgress(0);
      const response = await controlplane_install(id);
      const progressPercentage = response.data.progress;
      console.log("progresspercent", progressPercentage);
      setProgress(progressPercentage);
      // Add your success message or logic here
      reachedge_install();
    } catch (error) {
      // Add your error message or logic here
    }
  };

  const reachedge_install = async () => {
    try {
      setProgress(0);
      const response = await reach_install(id);
      const progressPercentage = response.data.progress;
      console.log("progresspercent", progressPercentage);
      setProgress(progressPercentage);
      // Add your success message or logic here
    } catch (error) {
      // Add your error message or logic here
      setTimeout(() => {
        navigate(`/devices`);
      }, 600000);
    }
  };

  const getcheck_vpp_installed = async () => {
    try {
      const response = await check_vpp_installed(id);
      console.log(response.data);
      // Add your success message or logic here
    } catch (error) {
      // Add your error message or logic here
    }
  };

  const getcheck_frr_installed = async () => {
    try {
      const response = await check_frr_installed(id);
      console.log(response.data);
      // Add your success message or logic here
    } catch (error) {
      // Add your error message or logic here
    }
  };

  const displayAlert = (message, className) => {
    const alertElement = document.getElementById("alertMessage");
    alertElement.textContent = message;
    alertElement.classList.add(className);
    alertElement.style.display = "block";
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Nav name="System Hardware Requirement Analysis" />
      </Grid>

      <Grid item xs={2}>
        <Paper style={{ height: "100vh", overflowY: "auto" }}>
          <Sidebar />
        </Paper>
      </Grid>

      <Grid item xs={10} style={{ paddingTop: "64px" }}>
        {" "}
        {/* Adjust the padding-top value to match your navbar's height */}
        <Typography variant="h6" align="center" gutterBottom></Typography>
        <Paper>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Instruction Set Compatible</TableCell>
                <TableCell>RAM</TableCell>
                <TableCell>CPU Cores</TableCell>
                <TableCell>NICs</TableCell>
                <TableCell>Kernel Module Supported</TableCell>
                <TableCell>NIC Driver Info</TableCell>
                {/* <TableCell></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {systemchecker.length &&
                systemchecker?.map((user) => (
                  <TableRow key={user.instruction_set_compatible}>
                    <TableCell>
                      {user.instruction_set_compatible ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>{user.ram}</TableCell>
                    <TableCell>{user.no_of_cpu_core}</TableCell>
                    <TableCell>{user.no_of_nic}</TableCell>
                    <TableCell>
                      {user.kernel_module_supported ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Manufacturer Name</TableCell>
                            <TableCell>Driver</TableCell>
                            <TableCell>Supported</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {user.nic_driver_info?.map((nic, index) => (
                            <TableRow key={index}>
                              <TableCell>{nic.manufacturer_name}</TableCell>
                              <TableCell>{nic.driver}</TableCell>
                              <TableCell>
                                {nic.supported === "yes" ? "Yes" : "No"}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableCell>
                    {/* <TableCell>
                <Button
                  onClick={() => sendfile()}
                  color="primary"
                  variant="contained"
                  disabled={fileSent}
                  size="small" // Adjust the size property to 'small'
                  style={{ minWidth: '80px' }} // Adjust the minWidth property as needed
                >
                  Send File
                </Button>
              </TableCell> */}
                  </TableRow>
                ))}
              {/* <TableRow>
            <TableCell>
              <Button
                onClick={() => getcheck_vpp_installed()}
                color="primary"
                variant="contained"
                disabled={fileSent}
                size="small" // Adjust the size property to 'small'
              >
                Check DATA PLANE Installed
              </Button>
            </TableCell>
            <TableCell>
              <Button
                onClick={() => getcheck_frr_installed()}
                color="primary"
                variant="contained"
                disabled={fileSent}
                size="small" // Adjust the size property to 'small'
              >
                Check CONTROL PLANE Installed
              </Button>
            </TableCell>
            <TableCell>
              <Button
                onClick={() => dataplan_install()}
                color="primary"
                variant="contained"
                disabled={fileSent}
                size="small" // Adjust the size property to 'small'
              >
                Install DATA PLANE
              </Button>
            </TableCell>
            <TableCell>
              <Button
                onClick={() => controlplan_install()}
                color="primary"
                variant="contained"
                disabled={fileSent}
                size="small" // Adjust the size property to 'small'
              >
                Install CONTROL PLANE
              </Button>
            </TableCell>
          </TableRow> */}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SystemChecker;
