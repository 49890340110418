import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Grid, CircularProgress, makeStyles, Dialog, DialogContent, Typography, FormControl, Select, MenuItem, Button, IconButton } from '@material-ui/core';
import { InputLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px', // Set a fixed height to center CircularProgress
  },
  selectedGraphItem: {
    display: 'flex',
    alignItems: 'center',
  },
  selectedGraphText: {
    marginRight: theme.spacing(1),
  },
}));

const GraphsComponent = ({ action, data, additionalHeaders }) => {
  const classes = useStyles();
  const [systemImages, setSystemImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [zabbixGraphs, setZabbixGraphs] = useState([]);

  const { id } = useParams();
  const defaultId = id || '10084';

  useEffect(() => {
    const fetchSystemImages = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL_node;
        const response = await axios.post(`${apiUrl}/GraphGet/${defaultId}`);

        // Filter out graphs not related to Zabbix
        const filteredGraphs = response.data.result.filter(graph => !graph.name.toLowerCase().includes("zabbix"));
        setZabbixGraphs(filteredGraphs);

        const baseUrl = "http://172.104.146.239/zabbix/chart2.php";
        const urls = filteredGraphs.map(graph => `${baseUrl}?graphid=${graph.graphid}&from=now-5m&to=now&height=201&width=1280&profileIdx=web.charts.filter&_=${Math.random()}`);

        const imagePromises = urls.map(async (url) => {
          try {
            const response = await fetch(`${apiUrl}/getsystemlimg?imageUrl=${url}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'image/png',
              },
            });

            if (!response.ok) {
              throw new Error(`Failed to fetch image: ${response.status} ${response.statusText}`);
            }

            const blob = await response.blob();
            const imageUrlObject = URL.createObjectURL(blob);
            return imageUrlObject;
          } catch (error) {
            console.error('Error fetching system image for URL:', url, error);
            return null;
          }
        });

        const systemImagesData = await Promise.all(imagePromises);
        setSystemImages(systemImagesData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching system images:', error);
        setLoading(false);
      }
    };

    fetchSystemImages();
  }, [action, data, additionalHeaders, defaultId]);

  const handleImageClick = () => {
    // Assuming you want to open dialog only if at least one image is selected
    if (selectedImages.length > 0) {
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDropdownChange = (event) => {
    setSelectedImages(event.target.value);
  };

  const handleRemoveSelectedImage = (index) => {
    const updatedSelectedImages = [...selectedImages];
    updatedSelectedImages.splice(index, 1);
    setSelectedImages(updatedSelectedImages);
  };

  return (
    <div className={classes.container}>
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <Grid container spacing={2} align='center'>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl style={{ minWidth: 200 }} fullWidth>
              <InputLabel>Select Graph(s)</InputLabel>
              <Select
                multiple
                value={selectedImages}
                onChange={handleDropdownChange}
                renderValue={(selected) =>
                  selected.map((value, index) => (
                    <div key={index} className={classes.selectedGraphItem}>
                      <Typography className={classes.selectedGraphText}>{value}</Typography>
                      <IconButton onClick={() => handleRemoveSelectedImage(index)} size="small">
                        <CloseIcon />
                      </IconButton>
                    </div>
                  ))
                }
              >
                {zabbixGraphs.map((graph, index) => (
                  <MenuItem key={index} value={graph.name}>{graph.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button variant="contained" color="primary" onClick={handleImageClick}>
              Show Graph(s)
            </Button>
          </Grid>
        </Grid>
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
        <DialogContent>
          {selectedImages.map((selectedImage, index) => (
            <div key={index}>
              <Typography variant="h6" gutterBottom>
                {selectedImage}
              </Typography>
              <img
                src={systemImages[index]} // Assuming systemImages array is mapped in order
                alt="Selected System Image"
                style={{ width: '100%', height: 'auto' }}
              />
            </div>
          ))}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default GraphsComponent;
