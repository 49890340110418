import React, { useEffect, useState } from "react";
import { Grid, Paper } from "@material-ui/core";
import { gethome, createtunnel, getHost } from "../../service/api";
import { useNavigate } from "react-router-dom";
import DeviceTable from "./DeviceTable";
import Sidebar from "../devices/Sidebar";
import ConnectedClients from "./ConnectedClients";
import Navbar from "../Home/Sidebar";
import Cookies from 'js-cookie';
import Nav from "../Home/Nav";

function AddDevice() {
  const [users, setUsers] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [isCreatingTunnel, setIsCreatingTunnel] = useState(false); 
  const [internetConnected, setInternetConnected] = useState(true); // State to track internet connection
  const [assigned, setassigned] = useState([])

  useEffect(() => {
    getAllUsers();
  }, []);


  const getAllUsers = async () => {
    try {
      console.log("called gethomefunc");
      // Check internet connection before making the API call
      const isOnline = navigator.onLine;
      setInternetConnected(isOnline);
      if (!isOnline) return; // If not online, stop further execution

      const id = Cookies.get("access_token");
      const response = await gethome(id);
      console.log("response:", response.data);
      console.log("Company_name:", response.data[0].Company_name);
      const Company_name = response.data[0].Company_name;

      if (response.data && response.data.length > 0) {
        console.log("Parent component users:", response.data);
        console.log("====================================");
        console.log("====================================");

        const datae = {
          jsonrpc: "2.0",
          method: "host.get",
          params: {
            output: "extend",
            selectHostGroups: "extend",
          },
          id: 1,
        };
        const res = await getHost(datae);
        console.log("=============GHost=======================");
        console.log(res.data);
        console.log("====================================");

        // Map the data based on edgedevice_name
        const mappedData = response.data.map((user) => {
          const edgedeviceName = user.edgedevice_name;
          const matchingHost = res.data.result.find(
            (host) => host.host === edgedeviceName
          );
          return { ...user, matchingHost };
        });

        // Filter out the items where matchingHost is undefined
        const filteredData = mappedData.filter((user) => user.matchingHost);
        // Assuming res.data.result contains the JSON data
        const filteredData1 = res.data.result.filter((item) => {
          // Check if any of the hostgroups have the name "wermink"
          return item.hostgroups.some((group) => group.name === Company_name);
        });

        console.log("Filtered Data1:",filteredData1.length);

        setassigned(filteredData1);
        console.log("setassigned Data:", assigned);
        setUsers(filteredData);
        console.log("setUsers Data:", users);
      } else {
        console.log("No user data found");
      }
    } catch (error) {
      console.log("Error fetching users:", error);
    }
  };

  const navigate = useNavigate();

  const handleCheckboxChange = (event, user) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedDevices(prevSelectedDevices => [...prevSelectedDevices, user]);
    } else {
      setSelectedDevices(prevSelectedDevices =>
        prevSelectedDevices.filter(selectedDevice => selectedDevice.Public_ip !== user.Public_ip)
      );
    }
  };

  const displayAlert = (message, type) => {
    setAlertMessage(message);
    setAlertType(type);
    setTimeout(() => {
      setAlertMessage("");
      setAlertType("");
    }, 3000);
  };

  const handleButtonClick = async () => {
    if (selectedDevices.length === 2) {
      const device1 = selectedDevices[0];
      const device2 = selectedDevices[1];

      const device1IP = device1.Public_ip;
      const device1TunnelIP = device1.Tunnel_ip;
      const device2IP = device2.Public_ip;
      const device2TunnelIP = device2.Tunnel_ip;

      try {
        setIsCreatingTunnel(true);
        const response = await createtunnel(device1IP, device1TunnelIP, device2IP, device2TunnelIP);
        console.log("Tunnel created successfully:", response);
        displayAlert("Tunnel created successfully!", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (error) {
        console.log("Error creating tunnel:", error);
        displayAlert("Error creating tunnel. Please try again.", "error");
      } finally {
        setIsCreatingTunnel(false);
      }
    } else {
      displayAlert("Please select exactly 2 devices", "success");
      console.log("Please select exactly 2 devices.");
    }
  };

  return (
    <Grid container spacing={0}>
        <Grid item xs={12}>
          <Nav  name="Device Manager"/>
        </Grid>
      <Grid item xs={2}>
        <Paper>
          <Navbar />
        </Paper>
      </Grid>

      <Grid item xs={10}>
                <ConnectedClients users={users} />
                <DeviceTable
                  users={users}
                  ghost={assigned}
                  handleCheckboxChange={handleCheckboxChange}
                  handleButtonClick={handleButtonClick}
                  isCreatingTunnel={isCreatingTunnel}
                />
      </Grid>
    </Grid>
  );
}

export default AddDevice;
