import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const TopHost = () => {
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL_node;
        const response = await axios.post(`${apiUrl}/gettophost`);
        const parsedData = parseHTMLData(response.data.body);
        setRowData(parsedData);
      } catch (error) {
        console.error('Error:', error.message);
      }
    };

    fetchData();
  }, []);

  const parseHTMLData = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const tableRows = doc.querySelectorAll('tbody > tr');
    const data = [];
    tableRows.forEach((row) => {
      const cells = row.querySelectorAll('td');
      const rowData = {
        host: cells[0].textContent.trim(),
        utilization: cells[2].textContent.trim(),
        avg1m: cells[3].textContent.trim(),
        avg5m: cells[4].textContent.trim(),
        avg15m: cells[5].textContent.trim(),
        processes: cells[6].textContent.trim(),
      };
      data.push(rowData);
    });
    return data;
  };

  return (
    <div>
      {rowData.length > 0 ? (
        <Paper elevation={3} style={{ padding: 16, marginBottom: 16 }}>
      <Typography variant="h5" gutterBottom align='center'>
        Top Host
      </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Host</TableCell>
                  <TableCell>Utilization</TableCell>
                  <TableCell>1m avg</TableCell>
                  <TableCell>5m avg</TableCell>
                  <TableCell>15m avg</TableCell>
                  <TableCell>Processes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.host}</TableCell>
                    <TableCell>{row.utilization}</TableCell>
                    <TableCell>{row.avg1m}</TableCell>
                    <TableCell>{row.avg5m}</TableCell>
                    <TableCell>{row.avg15m}</TableCell>
                    <TableCell>{row.processes}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <p>Error fetching data</p>
      )}
    </div>
  );
};

export default TopHost;
