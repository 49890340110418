import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Container, Grid, Typography, Paper } from "@material-ui/core";
import Navbar from "./Sidebar";
import SystemInformation from "../zabbix/systemInformation";
import PerformanceInfo from "../zabbix/PerformanceInfo";
import HostAvailabilityInfo from "../zabbix/HostAvailabilityInfo";
import ProblemsBySeverityInfo from "../zabbix/ProblemsBySeverityInfo";
import CurrentProblems from "../zabbix/CurrentProblems";
import TopHost from "../zabbix/TopHost";
import LocalTime from "../zabbix/LocalTime";
import GraphComponent from "../zabbix/GraphComponent";
import { gethome, getHost } from "../../service/api";
import Graphs from "../zabbix/Graphs";
import Nav from "./Nav";
import GraphsComponent from "../zabbix/graphsComponent";

const Home = () => {
  const [users, setUsers] = useState([]);
  const [internetConnected, setInternetConnected] = useState(true); // State to track internet connection

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    try {
      console.log("called gethomefunc");
      // Check internet connection before making the API call
      const isOnline = navigator.onLine;
      setInternetConnected(isOnline);
      if (!isOnline) return; // If not online, stop further execution

      const id = Cookies.get("access_token");
      const response = await gethome(id);
      console.log("response:", response.data);
      console.log("Company_name:", response.data[0].Company_name);
      const Company_name = response.data[0].Company_name;

      if (response.data && response.data.length > 0) {
        console.log("Parent component users:", response.data);
        console.log("====================================");
        console.log("====================================");

        const datae = {
          jsonrpc: "2.0",
          method: "host.get",
          params: {
            output: "extend",
            selectHostGroups: "extend",
          },
          id: 1,
        };
        const res = await getHost(datae);
        console.log("=============GHost=======================");
        console.log(res.data);
        console.log("====================================");

        // Map the data based on edgedevice_name
        const mappedData = response.data.map((user) => {
          const edgedeviceName = user.edgedevice_name;
          const matchingHost = res.data.result.find(
            (host) => host.host === edgedeviceName
          );
          return { ...user, matchingHost };
        });

        // Filter out the items where matchingHost is undefined
        const filteredData = mappedData.filter((user) => user.matchingHost);
        // Assuming res.data.result contains the JSON data
        const filteredData1 = res.data.result.filter((item) => {
          // Check if any of the hostgroups have the name "wermink"
          return item.hostgroups.some((group) => group.name === Company_name);
        });

        console.log(filteredData1);

        console.log("Filtered Data:", filteredData);
        setUsers(filteredData);
      } else {
        console.log("No user data found");
      }
    } catch (error) {
      console.log("Error fetching users:", error);
    }
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Nav name="Dashboard"/>
        </Grid>
        <Grid item xs={2}>
          {/* 20% width for Navbar */}
          <Navbar />
        </Grid>

        <Grid item xs={10}>
          {/* 80% width for remaining components */}
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ marginTop: '5%' }}>
              {/* Replace Typography with a styled banner or custom component */}
            
            </Grid>

            {!internetConnected && (
              <Grid item xs={12}>
                <Typography variant="h6" align="center" color="error">
                  Please connect to the internet to access the application.
                </Typography>
              </Grid>
            )}

            {internetConnected && (
              <>
                <Grid item xs={12} sm={6}>
                  <SystemInformation />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <PerformanceInfo />
                  <TopHost />
                </Grid>

                <Grid item xs={12}>
                  <CurrentProblems />
                </Grid>

                <Grid item xs={12}>
                  {/* Live Chart */}
                  <GraphsComponent />
                </Grid>

                <Grid item xs={12}>
                  {/* Live Chart */}
                  {/* <Graphs /> */}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
