import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { Doughnut } from "react-chartjs-2";
import "./ConnectedClients.css"; // Import the CSS file

function ConnectedClients({ users }) {
  // Calculate the count of connected clients
  const connectedClientsCount = users.filter(user => user.status === "connected").length;

  // Calculate the count of disconnected clients (total users - connected clients)
  const disconnectedClientsCount = users.length - connectedClientsCount;

  // Define the data for the doughnut chart
  const [chartData, setChartData] = useState({
    labels: ["Connected Clients", "Disconnected Clients"],
    datasets: [
      {
        data: [connectedClientsCount, disconnectedClientsCount],
        backgroundColor: ["rgba(75, 192, 192, 0.2)", "rgba(255, 99, 132, 0.2)"],
        borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      },
    ],
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <div className="connected-clients" style={{ marginTop: '5%' }}>
          <div className="chart-container">
            <Doughnut data={chartData} />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default ConnectedClients;
