import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

const CurrentProblems = () => {
  const [problemsData, setProblemsData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL_node;
        const response = await axios.post(`${apiUrl}/getcurrproblems`);
        setProblemsData(response.data.body);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const parseHTMLTable = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const rows = doc.querySelectorAll('.list-table tbody tr');
    const data = [];
    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll('td').forEach((cell) => {
        rowData.push(cell.textContent.trim());
      });
      data.push(rowData);
    });
    return data;
  };

  const tableData = problemsData ? parseHTMLTable(problemsData) : [];

  return (
    <div>
      <Typography variant="h5" gutterBottom align='center'>
        Current Problems
      </Typography>
      <Paper>
        <Table align='center'>
          <TableHead>
            <TableRow>
              <TableCell>Date & Time</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>Host</TableCell>
              <TableCell>Problem & Severity</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Info</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell align='center'>Tags</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index}>
                {row.map((cell, cellIndex) => (
                  <TableCell key={cellIndex}>{cell}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default CurrentProblems;
