import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Paper,
  Grid,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import { getvxlan_tunnel_details, deleteVxlanData } from "../../service/api";
import { useNavigate, useParams, Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Nav from "../Home/Nav";

const TunnelStatus = () => {
  useEffect(() => {
    getvxlan_tunnel_detail();
  }, []);

  const { id } = useParams();
  const navigate = useNavigate();

  const [vxlan_tunnel_detail, setvxlan_tunnel_detail] = useState([]);
  const [progress, setProgress] = useState(0);

  const getvxlan_tunnel_detail = async () => {
    try {
      const response = await getvxlan_tunnel_details(id);
      setvxlan_tunnel_detail(response.data);
    } catch (error) {
      console.log("Error while getting routing table data:", error);
    }
  };

  const deletevxlan = async (vxlan_src_ip, vxlan_dst_ip) => {
    try {
      const value = { vxlan_src_ip: vxlan_src_ip, vxlan_dst_ip: vxlan_dst_ip };
      console.log(value);

      setProgress(0);
      const response = await deleteVxlanData(value, id);
      displayAlert("Selected Tunnel Deleted", "alert-success");
      const progressPercentage = response.data.progress;
      console.log("progresspercent", progressPercentage);
      setProgress(progressPercentage);
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert("Error posting data. Please try again.", "alert-error");
    }
  };

  const displayAlert = (message, className, hidden = false) => {
    const alertElement = document.getElementById("alertMessage");
    alertElement.textContent = message;
    alertElement.classList.add(className);
    alertElement.style.display = hidden ? "none" : "block";
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Nav name="Tunnel Status" />
      </Grid>

      <Grid item xs={12} md={2}>
        <Paper style={{ overflowY: "auto" }}>
          <Sidebar />
        </Paper>
      </Grid>

      <Grid item xs={12} md={8}>
        <Paper style={{ padding: "8%" }}>
          <div id="alertMessage" className="alert" hidden="true"></div>
          <LinearProgress variant="determinate" value={progress} />

          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Source Address</TableCell>
                  <TableCell>Destination Address</TableCell>
                  <TableCell>Source Loopback Address</TableCell>
                  <TableCell>Destination Loopback Address</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Jitter</TableCell>
                  <TableCell>RTT</TableCell>
                  <TableCell>Encryption</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vxlan_tunnel_detail?.map((user) => (
                  <TableRow key={user.src_address}>
                    <TableCell>{user.src_address}</TableCell>
                    <TableCell>{user.dst_address}</TableCell>
                    <TableCell>{user.src_loopback_addr}</TableCell>
                    <TableCell>{user.dst_loopback_addr}</TableCell>
                    <TableCell>{user.status}</TableCell>
                    <TableCell>{user.jitter}</TableCell>
                    <TableCell>{user.rtt}</TableCell>
                    <TableCell>{user.encrypt}</TableCell>
                    <TableCell>
                      <Button
                        color="error"
                        onClick={() =>
                          deletevxlan(user.src_address, user.dst_address)
                        }
                        className="btn btn-primary"
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TunnelStatus;
