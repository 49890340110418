import React, { useState } from "react";
import "./register.css";
import axios from "axios";
import { Link, Redirect, useNavigate } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState({
    companyname: "",
    businessemail: "",
    firstname: "",
    lastname: "",
    jobtitle: "",
    phonenumber: "",
    password: "",
    confirmpassword: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      register();
    }
  };

  const register = () => {
    const {
      companyname,
      businessemail,
      firstname,
      lastname,
      jobtitle,
      phonenumber,
      password,
      confirmpassword
    } = user;

    // Validate form fields
    if (
      companyname.trim() === "" ||
      businessemail.trim() === "" ||
      firstname.trim() === "" ||
      lastname.trim() === "" ||
      jobtitle.trim() === "" ||
      phonenumber.trim() === "" ||
      password.trim() === "" ||
      confirmpassword.trim() === ""
    ) {
      alert("Please fill in all fields.");
      return;
    }

    if (password !== confirmpassword) {
      alert("Passwords do not match.");
      return;
    }

    axios
      .post("http://172.104.146.239:9002/register", user)
      .then((res) => {
        alert(res.data.message);
        navigate("/login");
      })
      .catch((error) => {
        console.log("Error:", error);
        alert("An error occurred. Please try again.");
      });
  };

  const login = () => {
    navigate("/login");
  };

  return (
    <div className="register">
      {console.log("User", user)}
      <h1>Register</h1>
      <input
        type="text"
        name="companyname"
        value={user.companyname}
        placeholder="Enter your Company Name"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      ></input>
      <input
        type="text"
        name="businessemail"
        value={user.businessemail}
        placeholder="Enter your Business Email"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      ></input>
      <input
        type="text"
        name="firstname"
        value={user.firstname}
        placeholder="Enter your First Name"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      ></input>
      <input
        type="text"
        name="lastname"
        value={user.lastname}
        placeholder="Enter your Last Name"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      ></input>
      <input
        type="text"
        name="jobtitle"
        value={user.jobtitle}
        placeholder="Enter your Job Title"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      ></input>
      <input
        type="number"
        name="phonenumber"
        value={user.phonenumber}
        placeholder="Enter your Phone Number"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      ></input>
      <input
        type="password"
        name="password"
        value={user.password}
        placeholder="Enter your Password"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      ></input>
      <input
        type="password"
        name="confirmpassword"
        value={user.confirmpassword}
        placeholder="Enter your Confirm Password"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      ></input>
      <div className="button" onClick={register}>
        Register
      </div>
      <div>or</div>
      <div className="button" onClick={login}>
        Login
      </div>
    </div>
  );
};

export default Register;
