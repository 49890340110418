import React, { useState } from "react";
import { Button, Paper, Grid, FormControl, LinearProgress } from "@material-ui/core";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import { restart_reach } from '../../../service/api';
import { useParams } from 'react-router-dom';
import Sidebar from "../Diagnostics/Sidebar";
import { Box } from "@mui/material";
import Nav from "../../Home/Nav";

const Restart = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Reach_Restart = async () => {
    try {
      await restart_reach(id);
      displayAlert('Restarted successfully!', 'alert-success');
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  };

  const handleRestartClick = () => {
    handleClose(); // Close the dialog
    Reach_Restart(); // Restart action
  };

  const displayAlert = (message, className, hidden = false) => {
    const alertElement = document.getElementById('alertMessage');
    alertElement.textContent = message;
    alertElement.classList.add(className);
    alertElement.style.display = hidden ? 'none' : 'block';
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Nav name="Restart" />
      </Grid>

      <Grid item xs={12} md={2}>
        <Paper style={{ overflowY: 'auto' }}>
          <Sidebar />
        </Paper>
      </Grid>

      <Grid item xs={12} md={8}>
        <Paper style={{ padding: "8%" }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
              <FormControl>
                <Button variant="contained" color="secondary" onClick={handleOpen}>
                  Restart
                </Button>
              </FormControl>
              <div id="alertMessage" className="alert" hidden="true"></div>
              <LinearProgress variant="determinate" value={progress} />
            </div>
          </Box>
        </Paper>
      </Grid>

      {/* Confirmation Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Restart"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to restart?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRestartClick} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Restart;
