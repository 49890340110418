import React, { useEffect, useState } from "react";
import {  Button, Paper,Grid, Typography, FormControl, InputLabel, Select, MenuItem, Input, LinearProgress } from "@material-ui/core";
import {testConnection
} from '../../service/api';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Sidebar from "../devices/Diagnostics/Sidebar";
import Nav from "../Home/Nav";
import { Box } from "@mui/material";

const PingHost = () => {



  const [testConn, settestConn] = useState({
    primary_dns: '8.8.8.8',
    secondary_dns: '8.8.4.4'
  });


  useEffect(() => {
  }, []);

  const { id } = useParams();
  const navigate = useNavigate();

  const [IpAddress, setInternalIpAddress] = useState('');
  const [progress, setProgress] = useState(0);
  const [isValidIpAddress, setIsValidIpAddress] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState('');

  const onValueChange = (e) => {
    
    const { name, value } = e.target;
    console.log(e.target);
  
    settestConn({ ...testConn, [name]: value });
  
    if (name === 'int_index') {
      setSelectedIndex(value);
    }
  };
  
  

  const handleIpAddressChange = (event) => {
    const ipAddress = event.target.value;
    setInternalIpAddress(ipAddress);
    setIsValidIpAddress(validateIp(ipAddress));
  };
  const validateIp = (ipAddress) => {
    
  
    const parts = ipAddress.split('.');
    if (parts.length !== 4) {
      return false;
    }
  
    for (let i = 0; i < parts.length; i++) {
      const part = parseInt(parts[i]);
      if (isNaN(part) || part < 0 || part > 255) {
        return false;
      }
    }
    return true; // Add this line to fix the function
  };
  

  const Connect_test = async () => {
    try {
      setIsValidIpAddress(false);
      
    setProgress(0);
      const response = await testConnection(testConn, id);
      
    const progressPercentage = response.data[0].progress;
    setProgress(progressPercentage);
      console.log(response.data[0].avg_rtt);
      if (response.data[0].avg_rtt === -1) {
        displayAlert('Not Connected', 'alert-error');
      } else {
        displayAlert(`Testing Connection with Round Trip Time ${response.data[0].avg_rtt}`, 'alert-success');
      }
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 4 seconds
      }, 4000);
      setIsValidIpAddress(true);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
      setIsValidIpAddress(true);
    } 
  };


const displayAlert = (message, className, hidden = false) => {
  const alertElement = document.getElementById('alertMessage');
  alertElement.textContent = message;
  alertElement.classList.add(className);
  alertElement.style.display = hidden ? 'none' : 'block';
};

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Nav name="Ping" />
      </Grid>

      <Grid item xs={12} md={2}>
        <Paper style={{ overflowY: 'auto' }}>
          <Sidebar />
        </Paper>
      </Grid>

      <Grid item xs={12} md={8}>
        <Paper style={{ padding: "8%" }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
          
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
        
          
        
      <FormControl>
      <InputLabel>Destination</InputLabel>
      <Input
        name="destination"
        value={IpAddress}
        onChange={event => {
          handleIpAddressChange(event);
          onValueChange(event); // Call the onValueChange function
        }}
        error={!isValidIpAddress}
      />
    </FormControl>
    </div>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
        <FormControl>
          <Button variant="contained" color="primary"  onClick={Connect_test}  disabled={!isValidIpAddress}>
            Test
          </Button>
        </FormControl>
        
        </div>
        
            <div id="alertMessage" className="alert" hidden = "true"></div>
            <LinearProgress variant="determinate" value={progress} />
        </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PingHost;
