import React from 'react';
import Navbar from './Sidebar'; // Import the Navbar component

const NotFound = () => {
  return (
    <div className="NotFoundPageContainer">
      <div id="page-wrapper" className="gray-bg">
        <div className="row border-bottom">
          <nav className="navbar navbar-static-top white-bg" role="navigation">
            <Navbar /> {/* Use the Navbar component */}
          </nav>
        </div>
        <div className="wrapper wrapper-content">
          <div className="error-page">
            <div className="error-code">404</div>
            <div className="error-message">Page not found</div>
            <div className="error-description">Sorry, the page you are looking for does not exist.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
