import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Paper, Typography, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px', // Adjust the height based on your design
  },
}));

const PerformanceInfo = () => {
  const classes = useStyles();
  const [performanceData, setPerformanceData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL_node;
        const response = await axios.post(`${apiUrl}/getperformance`);
        const parsedData = parseHTMLData(response.data.body);
        setPerformanceData(parsedData);
        setLoading(false);
      } catch (error) {
        console.error('Error:', error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const parseHTMLData = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const link = doc.querySelector('a');
    const value = link.querySelector('.value').textContent.trim();
    const decimals = link.querySelector('.decimals').textContent.trim();
    const indicator = link.querySelector('.svg-arrow').classList.contains('svg-arrow-down') ? 'down' : 'up';
    const description = link.querySelector('.item-description').textContent.trim().replace(/\r?\n|\r/g, ' ').trim();
  
    return {
      value,
      decimals,
      indicator,
      description
    };
  };

  return (
    <Paper className={classes.container} elevation={3}>
      <Typography variant="h5" gutterBottom align='center'>
        Performance Information
      </Typography>
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>Decimals</TableCell>
                <TableCell>Indicator</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{performanceData.description}</TableCell>
                <TableCell>{performanceData.value}</TableCell>
                <TableCell>{performanceData.decimals}</TableCell>
                <TableCell>{performanceData.indicator}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};

export default PerformanceInfo;
