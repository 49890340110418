import React, { useState, useEffect } from 'react';
import {
  FormGroup,
  FormControl,
  Input,
  InputLabel,
  NativeSelect,
  Typography,
  styled,
  Button,
  FormControlLabel,
  Switch,
  FormLabel,
  FormHelperText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';

const Container = styled(FormGroup)`
  width: 500%;
  margin: auto auto auto 10;
  & > div {
    margin-top: 20px;
  }
`;

function Profile() {
  const [user, setLoginUser] = useState({});

  useEffect(() => {
    const userId = Cookies.get('user');
    if (userId) {
      setLoginUser({ _id: userId });
    }
  }, []);

  const getuserinfo = () => {
    const userId = Cookies.get('user');
    axios
      .post('http://172.104.146.239:9002/user/getoneuser', { userId: user._id })
      .then((res) => {
        setUsers(res.data.user);
      });
  };

  const [users, setUsers] = useState([]);

  useEffect(() => {
    getuserinfo();
  }, []);

  const navigate = useNavigate();
  const addOrganization = () => {
    navigate('/addOrganization');
  };

  const [state, setState] = useState({
    gilad: true,
    jason: false,
    antoine: true,
  });

  // Get the value of the companyname cookie
  const companynameCookie = Cookies.get('companyname');

  return (
    <Container fullWidth>
      <Typography variant="h4">Account Profile</Typography>
      <FormControl>
        <InputLabel>Company Name</InputLabel>
        <Input name="companyname" defaultValue={companynameCookie} />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Country
        </InputLabel>
        <NativeSelect
          defaultValue={1}
          inputProps={{
            name: 'country',
            id: 'uncontrolled-native',
          }}
        >
          <option value={1}>Dubai</option>
          <option value={2}>UAE</option>
          <option value={3}>India</option>
          <option value={4}>USA</option>
        </NativeSelect>
      </FormControl>

      <FormControl component="fieldset" variant="standard">
        <FormLabel component="legend">Notifications</FormLabel>
        <FormGroup>
          <FormControlLabel control={<Switch checked={state.gilad} name="gilad" />} />
        </FormGroup>
      </FormControl>

      <FormControl>
        <Button variant="contained">Update Profile</Button>
      </FormControl>
    </Container>
  );
}

export default Profile;
