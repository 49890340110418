import React from 'react'

function Dashboard() {
  return (
    <div><h1>Home</h1>
    <p>Create Your Network in Few Simple Steps</p></div>
  )
}

export default Dashboard
