import React, { useState } from 'react';
import { Tab, Tabs, TableCell, Table, TableBody, TableHead, TableRow, Box, Typography, FormControl, Button, InputLabel, MenuItem, Select, Input, LinearProgress } from '@mui/material';
import {  useParams } from 'react-router-dom';
import { testConnection, restart_reach, reach_reset, dns_conf} from '../../service/api';
import Navbar from "../Home/Sidebar";



function Diagnostics() {
  
  const { id } = useParams();
  
  const [testConn, settestConn] = useState({
    primary_dns: '8.8.8.8',
    secondary_dns: '8.8.4.4'
  });
  const [currentTab, setCurrentTab] = useState('connectivity_test');
  const [IpAddress, setInternalIpAddress] = useState('');
  const [isValidIpAddress, setIsValidIpAddress] = useState(true);
  const [progress, setProgress] = useState(0);


  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };  
  const handleIpAddressChange = (event) => {
    const ipAddress = event.target.value;
    setInternalIpAddress(ipAddress);
    setIsValidIpAddress(validateIp(ipAddress));
  };
  const validateIp = (ipAddress) => {
    
  
    const parts = ipAddress.split('.');
    if (parts.length !== 4) {
      return false;
    }
  
    for (let i = 0; i < parts.length; i++) {
      const part = parseInt(parts[i]);
      if (isNaN(part) || part < 0 || part > 255) {
        return false;
      }
    }
    return true; // Add this line to fix the function
  };
  

  const onValueChange = (e) => {
    
    const { name, value } = e.target;
    console.log(e.target);
  
    settestConn({ ...testConn, [name]: value });

  };
  const Connect_test = async () => {
    try {
      setIsValidIpAddress(false);
      
    setProgress(0);
      const response = await testConnection(testConn, id);
      
    const progressPercentage = response.data[0].progress;
    setProgress(progressPercentage);
      console.log(response.data[0].avg_rtt);
      if (response.data[0].avg_rtt === -1) {
        displayAlert('Not Connected', 'alert-error');
      } else {
        displayAlert(`Testing Connection with Round Trip Time ${response.data[0].avg_rtt}`, 'alert-success');
      }
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 4 seconds
      }, 4000);
      setIsValidIpAddress(true);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
      setIsValidIpAddress(true);
    } 
  };
  
  const Reach_Restart = async () => {
    
    try {
      await restart_reach(id);
      displayAlert('Restarted successfully!', 'alert-success');
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  };
  const Reach_Reset = async () => {
    
    try {
      await reach_reset(id);
      displayAlert('ReachEdge Reset successfully!', 'alert-success');
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 1 second
      }, 1000);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
    }
  };


  const configureDNS = async () => {
    try {
      setIsValidIpAddress(false);
      
    setProgress(0);
      const response = await dns_conf(testConn, id);
      
    const progressPercentage = response.data[0].progress;
    setProgress(progressPercentage);
      console.log(response.data[0].avg_rtt);
      if (response.data[0].avg_rtt === -1) {
        displayAlert('Not Connected', 'alert-error');
      } else {
        displayAlert(`Testing Connection with Round Trip Time ${response.data[0].avg_rtt}`, 'alert-success');
      }
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 4 seconds
      }, 4000);
      setIsValidIpAddress(true);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
      setIsValidIpAddress(true);
    } 
  };

  const displayAlert = (message, className) => {
    const alertElement = document.getElementById('alertMessage');
    alertElement.textContent = message;
    alertElement.classList.add(className);
    alertElement.style.display = 'block';
  };

  return (
    <>
    <div className="HomePageContainer">
      <div id="page-wrapper" className="gray-bg">
        
        <div className="row border-bottom " >
            
                <li>
                    <span className="m-r-sm text-muted welcome-message">Welcome to Reach Manage.</span>
                </li>
            <Navbar />
        </div>
      <h1>Diagnostics</h1>
      <div id="alertMessage" className="alert"></div>
      
  <LinearProgress variant="determinate" value={progress} />
      
    <Tabs
      value={currentTab}
      onChange={handleTabChange}
      variant="scrollable"
      scrollButtons="auto"
    >
        {/* <Tab label="General" value="general" /> */}
        {/* <Tab label="Interfaces" value="interfaces" /> */}
        <Tab label="Connectivity test" value="connectivity_test" />
        <Tab label="ReachEdge Restart" value="reach_restart" />
        <Tab label="ReachEdge Reset" value="reach_reset" />
        <Tab label="DNS Configuration" value="dns_conf" />
      </Tabs>
      



      <Box hidden={currentTab !== 'connectivity_test'}>
        <Typography variant="h4">Connectivity test</Typography>
      <FormControl>
      <InputLabel>Destination</InputLabel>
      <Input
        name="destination"
        value={IpAddress}
        onChange={event => {
          handleIpAddressChange(event);
          onValueChange(event); // Call the onValueChange function
        }}
        error={!isValidIpAddress}
      />
    </FormControl>
        <FormControl>
          <Button variant="contained" onClick={Connect_test}  disabled={!isValidIpAddress}>
            Test
          </Button>
        </FormControl>
      </Box>





      <Box hidden={currentTab !== 'reach_restart'}>
        <Typography variant="h4">ReachEdge Restart</Typography>
      
        <FormControl>
          <Button variant="contained" onClick={Reach_Restart} >
            Restart
          </Button>
        </FormControl>
      </Box>


<Box hidden={currentTab !== 'reach_reset'}>
  <Typography variant="h4">ReachEdge Reset</Typography>

  <FormControl>
    <Button variant="contained" onClick={Reach_Reset} >
      Reset
    </Button>
  </FormControl>
</Box>

<Box hidden={currentTab !== 'dns_conf'}>
        <Typography variant="h4">DNS Configuration</Typography>
      <FormControl>
      <InputLabel>Primary DNS</InputLabel>
      <Input
        name="primary_dns"
        onChange={event => {
          handleIpAddressChange(event);
          onValueChange(event); // Call the onValueChange function
        }}
        error={!isValidIpAddress}
      />
    </FormControl>
      <FormControl>
      <InputLabel>Secondary DNS</InputLabel>
      <Input
        name="secondary_dns"
        onChange={event => {
          handleIpAddressChange(event);
          onValueChange(event); // Call the onValueChange function
        }}
        error={!isValidIpAddress}
      />
    </FormControl>
        <FormControl>
          <Button variant="contained" onClick={configureDNS}  disabled={!isValidIpAddress}>
            Test
          </Button>
        </FormControl>
      </Box>


    </div>
    </div>


    </>
  );
}

export default Diagnostics;
